import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, ButtonWrapper } from "../FrontComponents/Buttons";
import {
  FormMKStyle,
  FormikStyled,
  Input,
} from "../FrontComponents/StyledFormik";
import { Subtitle, Title } from "../FrontComponents/TextType";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { Field } from "formik";
import {getAreasData} from "../../services/databaseData"
const validate = Yup.object({
  name: Yup.string().required(),
  area: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  firma: Yup.string().required(),
  photo: Yup.string().required(),
  logo: Yup.string().required(),
  empresa: Yup.string().required(),
});
const validateEdit = Yup.object({
  name: Yup.string().required(),
  area: Yup.string().required(),
  email: Yup.string().email().required(),
  firma: Yup.string().required(),
  photo: Yup.string().required(),
  logo: Yup.string().required(),
  empresa: Yup.string().required(),
});


const AgregarTrabajadores = ({ show, onHide, edit, onSubmit, worker }) => {
  const [areas, setAreas] = useState({})
  useEffect(() => {
    getAreasData().then((values)=>{
      setAreas(values)
    })
   
  }, [])
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Title modal>{edit ? "Editar Trabajador" : "Agregar Trabajador"}</Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "1rem 2rem" }}>
        <FormikStyled
          initialValues={
            edit
              ? {
                  name: worker.name,
                  area: worker.area,
                  email: worker.email,
                  password: "***",
                  firma: worker.firma,
                  photo: worker.photo,
                  empresa: worker.empresa,
                  logo: worker.logo,
                }
              : {
                  name: "",
                  area: "",
                  email: "",
                  password: "",
                  firma: "",
                  photo: "",
                  empresa: "",
                  logo: "",
                }
          }
          validationSchema={edit? validateEdit: validate }
          onSubmit={onSubmit}
        >
          {({values, setFieldValue}) => (
            <FormMKStyle>
              <Input name="name" type="text">
                Nombres y Apellidos
              </Input>
              <Subtitle modal>Área</Subtitle>
              <br />
              <Field
                name="area"
                type="text"
                as="select"
                className="form-select"
              >
                <option value="">Seleccionar</option>
                {Object.entries(areas).map(([id, area]) => {   
                  return (
                    <option key={id} value={area.name}>
                      {area.name}
                    </option>
                  );
                })}
              </Field>
              <Input name="empresa" type="text" >
                Empresa
              </Input>
              <Subtitle modal>Logo</Subtitle>
              <br />
              <input
                name="logo"
                type="file"
                accept="image/png,image/jpeg"
                onChange={(event) => {
                  setFieldValue("logo", event.currentTarget.files[0]);
                }}
              />
              <Input name="email" type="text" disabled={edit? true : false}>
                Correo
              </Input>

              <Input name="password" type="text" disabled={edit? true : false}>
                Password
              </Input>
              <Subtitle modal>Foto</Subtitle>
              <br />
              <input
                name="photo"
                type="file"
                accept="image/png,image/jpeg"
                onChange={(event) => {
                  setFieldValue("photo", event.currentTarget.files[0]);
                }}
              />
              <br />
              <Subtitle modal>Firma</Subtitle>
              <br />
              <input
                name="firma"
                type="file"
                accept="image/png,image/jpeg"
                onChange={(event) => {
                  setFieldValue("firma", event.currentTarget.files[0]);
                }}
              />

              <ButtonWrapper center>
                <Button type="submit">Aceptar</Button>
              </ButtonWrapper>
            </FormMKStyle>
          )}
        </FormikStyled>
      </Modal.Body>
    </Modal>
  );
};

export default AgregarTrabajadores;
