import styled from "styled-components";

const Colors = {
  /* Azul: "#3f37c9", */
  Azul: "#1f487e",
  Blanco: "#fff",
  Rojo: "#D17495",
  Amarillo: "#CFC67A",
  Verde: "#00BE94",
};
export const Button = styled.button`
  ${({ align }) => {
    if (align === "start") {
      return `align-self: flex-start;`;
    }
    if (align === "center") {
      return `align-self: center;`;
    }
    if (align === "end") {
      return `align-self: flex-end;`;
    }
  }}
  ${(props) => {
    if (props.size === "lg") {
      return `padding: 0.8rem 1.2rem;  font-size: 18px; font-weight: bold;`;
    } else {
      return `font-size: 16px; padding: 0.6rem 1.2rem; font-weight: bold;`;
    }
  }}
  ${(props) => {
    if (props.color === "primary") {
      return `background-color: ${Colors.Azul}; color: ${Colors.Blanco}; border: 2px ${Colors.Azul} solid;`;
    } else if (props.color === "secondary") {
      return `background-color: ${Colors.Blanco}; color: ${Colors.Azul}; border: 2px ${Colors.Azul} solid;`;
    } else if (props.color === "red") {
      return `background-color: ${Colors.Rojo}; color: ${Colors.Blanco}; border: 2px ${Colors.Rojo} solid;`;
    } else if (props.color === "green") {
      return `background-color: ${Colors.Verde}; color: ${Colors.Blanco}; border: 2px ${Colors.Verde} solid;`;
    } else if (props.color === "yellow") {
      return `background-color: ${Colors.Amarillo}; color: ${Colors.Blanco}; border: 2px ${Colors.Amarillo} solid;`;
    } else {
      return `background-color: ${Colors.Azul}; color: ${Colors.Blanco}; border: 2px ${Colors.Azul} solid;`;
    }
  }}

  border-radius: 10px;

  &:hover {
    ${(props) => {
      if (props.color === "primary") {
        return `background-color: ${Colors.Blanco}; color: ${Colors.Azul}; `;
      } else if (props.color === "secondary") {
        return `background-color: ${Colors.Azul}; color: ${Colors.Blanco}; `;
      } else if (props.color === "red") {
        return `background-color: ${Colors.Blanco}; color: ${Colors.Rojo}; `;
      } else if (props.color === "green") {
        return `background-color: ${Colors.Blanco}; color: ${Colors.Verde}; `;
      } else if (props.color === "yellow") {
        return `background-color: ${Colors.Blanco}; color: ${Colors.Amarillo}; `;
      } else {
        return `background-color: ${Colors.Blanco}; color: ${Colors.Azul}; `;
      }
    }}
  }
`;
export const ButtonWrapper = styled.div`
  ${(props) => {
    if (props.center) {
      return `text-align: center;`;
    } else if (props.left) {
      return `text-align: left;`;
    } else if (props.right) {
      return "text-align:right;";
    } else {
      return `text-align: left;`;
    }
  }}
  display: flex;
  gap: 10px;
  width: 100%;
  margin: 1.2rem 0;
  @media screen and (min-width: 1024px) {
    margin: 1.5rem 0;
  }
  @media screen and (min-width: 1400px) {
    margin: 2.1rem 0;
  }
`;
