import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Table,
  TableData,
  TableH,
  TableWrapper,
} from "../components/FrontComponents/Table";
import { Title } from "../components/FrontComponents/TextType";
import { Content } from "../components/FrontComponents/Structure";
import Navbar from "../components/Navbar/NavbarVF";
import NavbarSupervisor from "../components/Navbar/NavBarSupervisor";
import NavbarTrabajador from "../components/Navbar/NavBarTrabajador";
import * as IoIcons from "react-icons/io";
import { getUserData } from "../services/userData";
import { getManualFormsData } from "../services/databaseData";
const Typedecl = () => {
  const [fetchManualFormData, setFetchManualFormData] = useState()
  const fetchManualFormsData = (data) => {
    const fetch = Object.keys(data).map((index) => {
      if (data[index].state && index !== "Iperc" ) {
        return (
          <tr key={index}>
          <TableData>{data[index].nombre}</TableData>
          <TableData>
            <Link className="ms-1" to={"/" + index}>
              <Button variant="success" title="Ver">
                <IoIcons.IoMdEye /> Resolver
              </Button>
            </Link>
           
          </TableData>
        </tr>
        );
      }
    })
    setFetchManualFormData(fetch);
  };

  useEffect(() => {
    const initialize = async() => {
      fetchManualFormsData(await getManualFormsData())
    }
    initialize()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {getUserData().area === "Administrador" ? (
        <Navbar />
      ) : getUserData().area === "Supervisor" ? (
        <NavbarSupervisor />
      ) : (
        <NavbarTrabajador />
      )}
      <Content>
        <Title>Lista de Formularios</Title>
        <TableWrapper>
          <Table>
            <thead style={{ backgroundColor: "#343a40", color: "#fff" }}>
              <tr>
                <TableH>Nombre</TableH>
                <TableH>Acción</TableH>
              </tr>
            </thead>
            <thead>
            {fetchManualFormData}
            </thead>
          </Table>
        </TableWrapper>
      </Content>
    </div>
  );
};

export default Typedecl;
