import React, { useRef } from "react";
import CanvasDraw from "react-canvas-draw";
import { Button, Col, Row } from "react-bootstrap";
import * as GrIcons from "react-icons/gr";
import "./firm.css";

const Firm = (props) => {
  let data;
  const firmCanvas = useRef(null);
  const handleClick = () => {
    firmCanvas.current.clear();
    data = undefined;
    props.firmChange(data);
  };
  const handleSaveFirm = (value) => {
    data = value.toDataURL("image/png");
    console.log(data);
    props.firmChange(data);
  };
  return (
    <div
      className="container mt-3 contenedor rounded-3 "
      style={{ backgroundColor: "#95B8D1" }}
      tabIndex="0"
      onBlur={props.onBlur}
    >
      <Row>
        <Col md="12">
          <Button
            className="mt-2"
            variant="info"
            size="sm"
            onClick={handleClick}
          >
            Borrar Firma <GrIcons.GrUpdate className="pb-1" />
          </Button>
          <p className="mt-2 fw-bold text-center">
            Firmar en la parte inferior del recuadro
          </p>
        </Col>
      </Row>
      <CanvasDraw
        style={{
          borderStyle: "dashed",
          marginTop: "1rem",
          width: "100%",
          height: "10rem",
        }}
        brushRadius={1}
        lazyRadius={0}
        hideGrid={true}
        ref={firmCanvas}
        onChange={(value) => handleSaveFirm(value.ctx.drawing.canvas)}
      />
      <Col className="text-center">
        <p>{props.children}</p>
      </Col>
    </div>
  );
};

export default Firm;
