import { jsPDF } from "jspdf";
import PlusPetrolIcon from "../icons/plusPetrolIcon";
import { resetTable, cell, tfValue } from "./pdfFunctions";

const dowloadPdf = (toConvetData) => {
  resetTable()
  const doc = new jsPDF();
  const alturaTabla = 5;
  doc.setFontSize(12);
  doc.text("DECLARACION JURADA", 120, 11, { align: "center" }); // eje x y eje 'y' a
  doc.setFontSize(9);
  doc.text(
    "Vigilancia de coronavirus - COVID-19 y otros virus respiratorios",
    120,
    16,
    "center"
  );
  doc.text(
    doc.splitTextToSize(
      "En el contexto de la pandemia por el COVID-19, con la finalidad de evitar el contagio de esta enfermedad, se solicita conteste este cuestionario, respondiendo de manera objetiva y sincera, a fin de poder implementar acciones que protejan su salud y la de todos los trabajadores.",
      200
    ),
    5,
    30
  );
  doc.setFontSize(8);

  doc.addImage(PlusPetrolIcon, "PNG", 5, 5);

  resetTable();
  cell(doc, 5, 45, 200, alturaTabla, "1.-Identificacion", 1, {
    align: "center",
  });
  cell(doc, 5, 0, 40, alturaTabla, "Apellidos y Nombres:", 2, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 100, alturaTabla, toConvetData.nombres_apellidos, 2);
  cell(doc, 5, 0, 30, alturaTabla, "Edad:", 2, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 30, alturaTabla, toConvetData.edad.toString(), 2);
  cell(doc, 5, 0, 20, alturaTabla, "Sexo:", 3, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 30, alturaTabla, toConvetData.sexo, 3);
  cell(doc, 5, 0, 30, alturaTabla, "Estado Civil:", 3, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 50, alturaTabla, toConvetData.estado_civil, 3);
  cell(doc, 5, 0, 30, alturaTabla, "Telefono:", 3, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 40, alturaTabla, toConvetData.telefono.toString(), 3);
  cell(doc, 5, 0, 20, alturaTabla, "Empresa:", 4, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 40, alturaTabla, toConvetData.empresa.toString(), 4);
  cell(doc, 5, 0, 30, alturaTabla, "Area de trabajo:", 4, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 40, alturaTabla, toConvetData.area_trabajo.toString(), 4);
  cell(doc, 5, 0, 30, alturaTabla, "Puesto:", 4, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 40, alturaTabla, toConvetData.puesto.toString(), 4);
  cell(doc, 5, 0, 40, alturaTabla, "Fecha de nacimiento:", 5, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 30, alturaTabla, toConvetData.fecha_nacimiento, 5);
  cell(doc, 5, 0, 40, alturaTabla, "Documento de identidad:", 5, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 20, alturaTabla, "Tipo:", 5, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 20, alturaTabla, toConvetData.documento, 5);
  cell(doc, 5, 0, 30, alturaTabla, "Nro Documento:", 5, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 20, alturaTabla, toConvetData.nro_documento, 5);
  cell(doc, 5, 0, 30, alturaTabla, "Procedencia:", 6, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 50, alturaTabla, toConvetData.procedencia.toString(), 6);
  cell(doc, 5, 0, 20, alturaTabla, "Domicilio", 6, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 100, alturaTabla, toConvetData.domicilio.toString(), 6);
  cell(doc, 5, 45, 200, alturaTabla, "2.-Antecedentes", 7, { align: "center" });
  cell(doc, 5, 0, 170, alturaTabla, "2.1 Epidemiología:", 8, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 15, alturaTabla, "SI", 8, {
    align: "center",
    color: "#cccccc",
  });
  cell(doc, 5, 0, 15, alturaTabla, "NO", 8, {
    align: "center",
    color: "#cccccc",
  });
  tfValue(
    doc,
    5,
    0,
    200,
    alturaTabla,
    "Ha tenido contacto con alguna persona con síntomas de infección respiratoria en los últimos 14 días?",
    9,
    toConvetData.contacto_sintomas,
    { color: "#c9c9c9" }
  );
  tfValue(
    doc,
    5,
    0,
    200,
    alturaTabla,
    "Reside o ha viajado, en los ultimos 14 días a ciudades del Perú fuera de Lima?",
    10,
    toConvetData.reside
  );
  tfValue(
    doc,
    5,
    0,
    200,
    alturaTabla,
    "En su domicilio, habita personal de salud en actividad atendiendo pacientes con COVID-19?",
    11,
    toConvetData.habita
  );
  tfValue(
    doc,
    5,
    0,
    200,
    alturaTabla,
    "Usted tuvo diagnostico de COVID 19 ?",
    12,
    toConvetData.tuvo_covid
  ); //Falta en el formulario
  tfValue(
    doc,
    5,
    0,
    200,
    alturaTabla,
    "Estuvo hospitalizado",
    13,
    toConvetData.hospitalizado
  );
  cell(
    doc,
    5,
    0,
    70,
    alturaTabla,
    "Cuales fueron sus Sintomas (escriba los principales)",
    14
  );
  cell(doc, 5, 0, 130, alturaTabla, toConvetData.sintomas, 14);
  cell(doc, 5, 0, 160, alturaTabla, "Fecha de diagnóstico", 15);
  cell(doc, 5, 0, 40, alturaTabla, toConvetData.ant_pregunta1, 15);
  cell(doc, 5, 0, 170, alturaTabla, "2.2 Viajes:", 16, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 15, alturaTabla, "SI", 16, {
    align: "center",
    color: "#cccccc",
  });
  cell(doc, 5, 0, 15, alturaTabla, "NO", 16, {
    align: "center",
    color: "#cccccc",
  });
  tfValue(
    doc,
    5,
    0,
    200,
    alturaTabla,
    "¿Ha viajado fuera del Perú en los últimos 14 días?",
    17,
    toConvetData.viajado_fuera
  );
  tfValue(
    doc,
    5,
    0,
    200,
    alturaTabla,
    "Si viajó, ¿En la ciudad que visitó había casos de infección por coronavirus?",
    18,
    toConvetData.ciudad_visito
  );
  cell(
    doc,
    5,
    0,
    70,
    alturaTabla,
    "2.3 Comorbilidad / enfermedades crónicas:",
    19,
    {
      color: "#cccccc",
    }
  );
  cell(doc, 5, 0, 15, alturaTabla, "SI", 19, {
    align: "center",
    color: "#cccccc",
  });
  cell(doc, 5, 0, 15, alturaTabla, "NO", 19, {
    align: "center",
    color: "#cccccc",
  });
  cell(doc, 5, 0, 70, alturaTabla, "", 19, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 15, alturaTabla, "SI", 19, {
    align: "center",
    color: "#cccccc",
  });
  cell(doc, 5, 0, 15, alturaTabla, "NO", 19, {
    align: "center",
    color: "#cccccc",
  });
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Diabetes mellitus",
    20,
    toConvetData.diabetes
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Insuficiencia renal crónica",
    20,
    toConvetData.insuficiencia
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Hipertensión arterial:",
    21,
    toConvetData.hipertension
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Enfermedad Cardiovascular",
    21,
    toConvetData.cardiovascular
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Obesidad IMC >35",
    22,
    toConvetData.obesidad
  );
  tfValue(doc, 5, 0, 100, alturaTabla, "Cáncer", 22, toConvetData.cancer);
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Asma bronquial, Enfermedad pulmonar crónica",
    23,
    toConvetData.asma
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Otras enfermedades",
    23,
    toConvetData.otras_enfermedades
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Enfermedad, tratamiento y estado inmunosupresor",
    24,
    toConvetData.estado
  );
  cell(doc, 5, 0, 70, alturaTabla, "Especifica", 24);
  cell(doc, 5, 0, 30, alturaTabla, toConvetData.otras_especificar, 24);
  cell(doc, 5, 45, 200, alturaTabla, "3.-Sintomas", 25, { align: "center" });
  cell(
    doc,
    5,
    45,
    200,
    alturaTabla,
    "En la ultimas 48 horas ha presentado alguno de los siguientes sintomas:",
    26
  );
  cell(doc, 5, 0, 70, alturaTabla, "Sintoma", 27, {
    align: "center",
    color: "#cccccc",
  });
  cell(doc, 5, 0, 15, alturaTabla, "SI", 27, {
    align: "center",
    color: "#cccccc",
  });
  cell(doc, 5, 0, 15, alturaTabla, "NO", 27, {
    align: "center",
    color: "#cccccc",
  });
  cell(doc, 5, 0, 70, alturaTabla, "", 27, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 15, alturaTabla, "SI", 27, {
    align: "center",
    color: "#cccccc",
  });
  cell(doc, 5, 0, 15, alturaTabla, "NO", 27, {
    align: "center",
    color: "#cccccc",
  });
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Fiebre ((T°>37,5°C)",
    28,
    toConvetData.fiebre
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Irritabilidad / Confusión",
    28,
    toConvetData.irritabilidad
  );
  tfValue(doc, 5, 0, 100, alturaTabla, "Tos", 29, toConvetData.tos);
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Escalofríos",
    29,
    toConvetData.escalofrios
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Alteración del olfato (disosmia)",
    30,
    toConvetData.disosmia
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Expectoración",
    30,
    toConvetData.expectoracion
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Alteración del gusto (disgeusia)",
    31,
    toConvetData.disguesia
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Fotofobia (molestia visual a la luz)",
    31,
    toConvetData.fotofobia
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Dolor de garganta (disfagia)",
    32,
    toConvetData.disfagia
  );
  tfValue(doc, 5, 0, 100, alturaTabla, "Diarrea", 32, toConvetData.diarrea);
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Malestar General",
    33,
    toConvetData.malestar_general
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Náuseas (deseo de vomitar)",
    33,
    toConvetData.nauseas
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Congestión o secreción nasal (rinorrea)",
    34,
    toConvetData.rinorrea
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Vómito (expulsar por la boca lo ingerido)",
    34,
    toConvetData.vomito
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Dolor de cabeza (cefalea)",
    35,
    toConvetData.cefalea
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Otalgia (dolor de oído)",
    35,
    toConvetData.otalgia
  );
  doc.setFontSize(7);
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Dificultad respiratoria (disnea, sensación de falta de aire)",
    36,
    toConvetData.disnea
  );
  doc.setFontSize(8);
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Dolor articular",
    36,
    toConvetData.dolor_articular
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Enrojecimiento ocular (congestión ocular)",
    37,
    toConvetData.congestion_ocular
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Otras molestias o síntomas",
    37,
    toConvetData.otros_sintomas
  );
  tfValue(
    doc,
    5,
    0,
    100,
    alturaTabla,
    "Dolor muscular",
    38,
    toConvetData.dolor_muscular
  );
  cell(doc, 5, 0, 70, alturaTabla, "(Especificar):", 38);
  cell(doc, 5, 0, 30, alturaTabla, toConvetData.otros_sintomas_especificar, 38);
  doc.setFontSize(8);

  doc.text(
    doc.splitTextToSize(
      "Declaro sujetarme a lo que disponga Pluspetrol, respecto a la posibilidad o no de viajar, atendiendo a mi estado de salud y lo declarado en el presente documento.",
      200
    ),
    5,
    239
  );
  doc.text(
    doc.splitTextToSize(
      "Declaro que en caso de tener positivo alguno de los síntomas durante mi permanencia en las instalaciones, acudiré inmediatamente a la Unidad Médica para la atención respectiva.",
      200
    ),
    5,
    245
  );

  resetTable();
  doc.setFontSize(8);
  cell(
    doc,
    5,
    250,
    200,
    alturaTabla,
    "Declaracion realizada en (especificar):",
    1,
    { align: "center" }
  );
  cell(doc, 5, 0, 20, alturaTabla, "Hotel:", 2, { color: "#cccccc" });
  cell(doc, 5, 0, 40, alturaTabla, toConvetData.hotel, 2);
  cell(doc, 5, 0, 30, alturaTabla, "Aeropuerto:", 2, { color: "#cccccc" });
  cell(doc, 5, 0, 40, alturaTabla, toConvetData.aeropuerto, 2);
  cell(doc, 5, 0, 20, alturaTabla, "Otros:", 2, { color: "#cccccc" });
  cell(doc, 5, 0, 50, alturaTabla, toConvetData.otros, 2);

  doc.text("FIRMA:", 5, 279);
  doc.addImage(toConvetData.firma, "PNG", 15, 259, 20, 20);
  doc.line(15, 279, 35, 279);
  doc.text("NOMBRES Y APELLIDOS:", 5, 285);
  doc.text(toConvetData.nombres_apellidos, 40, 285);

  const date = new Date(toConvetData.createDate);

  doc.text("FECHA:", 160, 279);
  doc.text(date.toLocaleDateString(), 180, 279);
  doc.text("DNI:", 160, 285);
  doc.text(toConvetData.nro_documento, 180, 285);

  window.open(doc.output("bloburl"), "_blank");
};

export default { dowloadPdf };
