import axios from 'axios'
const endpoint = (dni) => {
    return 'https://dniruc.apisperu.com/api/v1/dni/' + dni + '?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6Ijc3ZmVyMTk5N0BnbWFpbC5jb20ifQ.HcNQ_2P_WPQfIylfNjrW0eMkW30nMBN6R7A5fOtduFU'
}

const getData = async (dni) => {
    try{
        const data = await axios.get(endpoint(dni))
        if(data.status === 200){
            if(data.data.success !== false){
                return data.data
            }
            return false
        }
        return false
    }
    catch{
        return false
    }
}

export default {getData}
