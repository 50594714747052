import React, { useEffect, useState } from "react";
import { Content } from "../../components/FrontComponents/Structure";
import Navbar from "../../components/Navbar/NavbarVF";
import NavbarSupervisor from "../../components/Navbar/NavBarSupervisor";
import NavbarTrabajador from "../../components/Navbar/NavBarTrabajador";
import { getUserData } from "../../services/userData";
import { Title } from "../../components/FrontComponents/TextType";
import { ActionButton } from "../../components/FrontComponents/ActionButtons/actionbutton";
import {
  Button,
  ButtonWrapper,
} from "../../components/FrontComponents/Buttons";
import * as FaIcons from "react-icons/fa";
import {
  TableH,
  Table,
  TableData,
  TableWrapper,
} from "../../components/FrontComponents/Table";
import Marea from "../../components/Modales/Marea";
import { uploadNewArea, removeArea } from "../../services/uploadToDatabase";
import { getAreasData } from "../../services/databaseData";
import Toast from "../../components/FrontComponents/toast";

const Areas = () => {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [toFetch, setToFetch] = useState();

  const handleClick = () => {
    setShow(true);
    setEdit(false);
  };
  const handleRemove = async (id) => {
    const payload = await removeArea(id);
    if (payload.error === "") {
      Toast("Se eliminó correctamente.", "success");
      console.log("Remove correctly");
      await initialize();
    } else {
      Toast("Ocurrió un error.", "error");
      console.log(payload.error);
    }
  };

  const handleSubmit = async (values) => {
    const response = await uploadNewArea(values);
    if (response.error === "") {
      Toast("Se ingreso correctamente.", "success");
      console.log("Se ingreso correctamente");
      await initialize();
      setShow(false);
    } else {
      Toast("Ocurrió un error.", "error");
    }
  };

  const fetchData = (values) => {
    setToFetch(values);
  };

  const initialize = async () => {
    const data = await getAreasData();
    fetchData(data);
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div>
      {getUserData().area === "Administrador" ? (
        <Navbar />
      ) : getUserData().area === "Supervisor" ? (
        <NavbarSupervisor />
      ) : (
        <NavbarTrabajador />
      )}
      <Content>
        <Title>Áreas</Title>
        <ButtonWrapper>
          <Button color="secondary" onClick={handleClick}>
            <FaIcons.FaPlus></FaIcons.FaPlus> Agregar
          </Button>
          {show && (
            <Marea
              onSubmit={handleSubmit}
              edit={edit}
              show={show}
              onHide={() => setShow(false)}
            />
          )}
        </ButtonWrapper>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <TableH>Descripción</TableH>
                <TableH>Acciones</TableH>
              </tr>
            </thead>
            <tbody>
              {toFetch &&
                Object.entries(toFetch).map(([id, data]) => {
                  return (
                    <tr key={id}>
                      <TableData>{data.name}</TableData>
                      <TableData>
                        <ActionButton
                          type="remove"
                          onClick={() => handleRemove(id)}
                        />
                      </TableData>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </TableWrapper>
      </Content>
    </div>
  );
};

export default Areas;
