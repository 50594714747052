import vehiculosLigeros from "../manualFormsDataStructure/vehiculosligerosNames.json"
import vehiculosPesados from "../manualFormsDataStructure/vehiculospesadosNames.json"
import ExcelJS from "exceljs";
import { getManualFormsData } from "./databaseData";

const manualFormQuestions = {
  VehiculosLigeros: vehiculosLigeros,
  VehiculosPesados: vehiculosPesados,
  Iperc: {
      area: "Area",
      controlsecuencias: "SECUENCIA PARA CONTROLAR EL PELIGRO Y REDUCIR EL RIESGO",
      createDate: undefined,
      empresa: "Empresa",
      formKey: undefined,
      latitud: undefined,
      longitud: undefined,
      secuencias: "SECUENCIA DE PASOS DE LA TAREA",
      supervisores: "Supervisores",
      tarea: "Nombre de la Tarea",
      trabajadores: "Trabajadores",
      ubicacion: "Ubicacion exacta (Lugar)"
  }
}

export const downloadExcel = async (data) => {
  const workbook = new ExcelJS.Workbook();
    workbook.creator = "Bimerso";
    workbook.lastModifiedBy = "Bimerso";
    workbook.created = new Date();
    workbook.modified = new Date();
    let column = 1
    let row = 2;

    const formsData = await getManualFormsData()

    Object.keys(manualFormQuestions).map((key) => { 
      if(data[key] !== undefined){
        const sheet = workbook.addWorksheet(formsData[key].nombre.substring(0, 30));
        column = 1
        Object.keys(data[key]).map((item) => {
          Object.keys(data[key][item]).map((answerKey) => {
            if(answerKey !== "formKey" && answerKey !== "createDate" && answerKey !== "latitud" && answerKey !== "longitud" && answerKey !== "userId" && answerKey.length !== 28){
              sheet.getCell(1, column).value = manualFormQuestions[key][answerKey] === "+" ? "" : manualFormQuestions[key][answerKey];
              sheet.getCell(1, column).fill = {type: "pattern", pattern: "solid", fgColor: { argb: "FFcccccc" }};
              column ++
            }
          })
          column = 1
          Object.keys(data[key][item]).map((answerKey) => {
            if(answerKey !== "formKey" && answerKey !== "createDate" && answerKey !== "latitud" && answerKey !== "longitud" && answerKey !== "userId" && answerKey.length !== 28){
              if(typeof data[key][item][answerKey] === "object"){
                Object.keys(data[key][item][answerKey]).map((objKey) => {
                  if(typeof data[key][item][answerKey][objKey] === "object"){
                    Object.keys(data[key][item][answerKey][objKey]).map((subObjKey) => {
                      sheet.getCell(row, column).value = data[key][item][answerKey][objKey][subObjKey];
                      column ++
                    })
                  } else {
                    sheet.getCell(row, column).value = data[key][item][answerKey][objKey];
                    column ++
                  }
                })
              } else {
                sheet.getCell(row, column).value = data[key][item][answerKey];
                column ++
              }
            }
          })
          row += 1;
          column = 1
        });
      }
      column = 1
      row = 2
    })

    dowloadExcel(workbook); 
};

const dowloadExcel = async (excel) => {
  const buf = await excel.xlsx.writeBuffer();
  const data = new Blob([buf], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(data);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = "Respuestas.xlsx";
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  if (a && a.parentElement) {
    a.parentElement.removeChild(a);
  }
};