import {Modal} from "react-bootstrap";
import CustomForm from "./CustomForm/customForm";
const AddForm = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    > 
      <Modal.Body>
        <CustomForm onSubmit={props.onSubmit} edit={props.edit}/>
      </Modal.Body>
    </Modal>
  );
};
export default AddForm;
