import React, { useEffect, useState } from "react";
import {
  Formik,
  Field,
  Form as FormMK,
  ErrorMessage,
  FieldArray,
} from "formik";
import * as Yup from "yup";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import Tarjeta from "../components/Tarjeta";
import ErrorM from "../components/ErrorMessage";
import { Subtitle, Title } from "../components/FrontComponents/TextType";
import { List } from "../components/FrontComponents/list";
import { getWorkersData } from "../services/databaseData";
import { useCurrentPosition } from "../hooks/useCurrentPosition";
import { DeleteButton } from "../components/FrontComponents/ActionButtons";
import { uploadAnswer, sendNotification } from "../services/uploadToDatabase";
import Toast from "../components/toast";
import { Redirect } from "react-router-dom";
import { TextField } from "../components/FrontComponents/Inputs/textinput";
import { getUserData } from "../services/userData";
import { ActionButton } from "../components/FrontComponents/ActionButtons/actionbutton";
const initialData = {
  empresa: "",
  lugar: "",
  nombre_conductor: "",
  tipo_vehiculo: "",
  "Documento de identidad": {
    number: "",
    typeDoc: "DNI",
  },
  placa_tracto: "",
  placa_acoplado: "",
  placa_otros: "",
  const_vehicularTr: "",
  const_vehicularSe: "",
  revi_trac: "",
  revi_semi: "",
  segu_oblig: "",
  poliza_trac: "",
  poliza_semi: "",
  tarjeta_trac: "",
  tarjeta_remol: "",
  plan_contin: "",
  licencia_vi: "",
  sctr: "",
  equipo_perso: "",
  //2 columnas
  encen_eva: "",
  baterias: "",
  tanques: "",
  bloqueador: "",
  luces: "",
  alarmas: "",
  quinta_ru: "",
  espejos: "",
  parabrisas: "",
  estado_neumatico: "",
  estado_general: "",
  compartimiento: "",
  tuercas: "",
  conos: "",
  tacos: "",
  kit_herrami: "",
  kit_anderrame: "",
  botiquin: "",
  radio: "",
  bocina: "",
  circulina: "",
  //3 columnas
  luces_lateral: "",
  extintores: "",
  defensa_anti: "",
  sistema_sus: "",
  estado_neuma: "",
  estado_chasis: "",
  pata_meca: "",
  puertas_compar: "",
  tuercas_espar: "",
  manparon: "",
  piso_acoplado: "",
  contenedor: "",
  elementos_aseg: "",
  supervisores: [{ id: "" }],
};
const validate = Yup.object({
  empresa: Yup.string().required("El campo es necesario llenarlo"),
  lugar: Yup.string().required("El campo es necesario llenarlo"),
  nombre_conductor: Yup.string().required("El campo es necesario llenarlo"),
  tipo_vehiculo: Yup.string().required("El campo es necesario llenarlo"),
  /* "Documento de identidad": Yup.array().of(
    Yup.object().shape({
      number: Yup.number().required("El campo es necesario llenarlo"),
    })
  ), */
  placa_tracto: Yup.string().required("El campo es necesario llenarlo"),
  placa_acoplado: Yup.string().required("El campo es necesario llenarlo"),
  placa_otros: Yup.string().required("El campo es necesario llenarlo"),
  const_vehicularTr: Yup.string().required("El campo es necesario llenarlo"),
  const_vehicularSe: Yup.string().required("El campo es necesario llenarlo"),
  revi_trac: Yup.string().required("El campo es necesario llenarlo"),
  revi_semi: Yup.string().required("El campo es necesario llenarlo"),
  segu_oblig: Yup.string().required("El campo es necesario llenarlo"),
  poliza_trac: Yup.string().required("El campo es necesario llenarlo"),
  poliza_semi: Yup.string().required("El campo es necesario llenarlo"),
  tarjeta_trac: Yup.string().required("El campo es necesario llenarlo"),
  tarjeta_remol: Yup.string().required("El campo es necesario llenarlo"),
  plan_contin: Yup.string().required("El campo es necesario llenarlo"),
  licencia_vi: Yup.string().required("El campo es necesario llenarlo"),
  sctr: Yup.string().required("El campo es necesario llenarlo"),
  equipo_perso: Yup.string().required("El campo es necesario llenarlo"),
  encen_eva: Yup.string().required("El campo es necesario llenarlo"),
  baterias: Yup.string().required("El campo es necesario llenarlo"),
  tanques: Yup.string().required("El campo es necesario llenarlo"),
  bloqueador: Yup.string().required("El campo es necesario llenarlo"),
  luces: Yup.string().required("El campo es necesario llenarlo"),
  alarmas: Yup.string().required("El campo es necesario llenarlo"),
  quinta_ru: Yup.string().required("El campo es necesario llenarlo"),
  espejos: Yup.string().required("El campo es necesario llenarlo"),
  parabrisas: Yup.string().required("El campo es necesario llenarlo"),
  estado_neumatico: Yup.string().required("El campo es necesario llenarlo"),
  estado_general: Yup.string().required("El campo es necesario llenarlo"),
  compartimiento: Yup.string().required("El campo es necesario llenarlo"),
  tuercas: Yup.string().required("El campo es necesario llenarlo"),
  conos: Yup.string().required("El campo es necesario llenarlo"),
  tacos: Yup.string().required("El campo es necesario llenarlo"),
  kit_herrami: Yup.string().required("El campo es necesario llenarlo"),
  kit_anderrame: Yup.string().required("El campo es necesario llenarlo"),
  botiquin: Yup.string().required("El campo es necesario llenarlo"),
  radio: Yup.string().required("El campo es necesario llenarlo"),
  bocina: Yup.string().required("El campo es necesario llenarlo"),
  circulina: Yup.string().required("El campo es necesario llenarlo"),
  luces_lateral: Yup.string().required("El campo es necesario llenarlo"),
  extintores: Yup.string().required("El campo es necesario llenarlo"),
  defensa_anti: Yup.string().required("El campo es necesario llenarlo"),
  sistema_sus: Yup.string().required("El campo es necesario llenarlo"),
  estado_neuma: Yup.string().required("El campo es necesario llenarlo"),
  estado_chasis: Yup.string().required("El campo es necesario llenarlo"),
  pata_meca: Yup.string().required("El campo es necesario llenarlo"),
  puertas_compar: Yup.string().required("El campo es necesario llenarlo"),
  tuercas_espar: Yup.string().required("El campo es necesario llenarlo"),
  manparon: Yup.string().required("El campo es necesario llenarlo"),
  piso_acoplado: Yup.string().required("El campo es necesario llenarlo"),
  contenedor: Yup.string().required("El campo es necesario llenarlo"),
  elementos_aseg: Yup.string().required("El campo es necesario llenarlo"),
  supervisores: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required("El campo es necesario llenarlo"),
    })
  ),
});

const VehiculosPesadosFormat = () => {
  const [latitud, longitud] = useCurrentPosition(0);
  const [supervisores, setSupervisores] = useState({});
  const [upload, setUpload] = useState(false);
  const handleSubmit = async (values) => {
    const userData = getUserData();
    values.latitud = latitud;
    values.longitud = longitud;
    values.formKey = "VehiculosPesados";
    values.createDate = Date.now();
    values.userId = userData.uid;
    const data = await uploadAnswer(values);
    if (data.error === "") {
      sendNotification(values.supervisores, data.key);
      Toast("Información subida Correctamente", "success");
      setUpload(true);
    } else {
      console.log(data.error);
    }
  };
  useEffect(async () => {
    const workersData = await getWorkersData();
    setSupervisores(workersData);
  }, []);
  return (
    <Container style={{ backgroundColor: "#a3dfff" }} className="p-3">
      {upload ? <Redirect to="/subido" /> : null}
      <Formik
        initialValues={initialData}
        validationSchema={validate}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <FormMK>
            <Tarjeta>
              <h2
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                LISTA DE VERIFICACIÓN DE VEHÍCULOS PESADOS
              </h2>
            </Tarjeta>
            <Tarjeta>
              <TextField title="Empresa transportista:" name="empresa" />
              <TextField title="Lugar:" name="lugar" />
              <TextField
                title="Nombre del conductor:"
                name="nombre_conductor"
              />
              <TextField title="Tipo de vehículo:" name="tipo_vehiculo" />
              <TextField
                title="Nº DNI:"
                name="Documento de identidad.number"
                type="number"
              />
              <TextField title="Placa del tracto:" name="placa_tracto" />
              <TextField title="Placa Acoplado::" name="placa_acoplado" />
              <TextField title="Placa (otros):" name="placa_otros" />
            </Tarjeta>
            <Tarjeta>
              <h2
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                Verificación de Documentos del Vehiculo
              </h2>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                Constancia de Habilitación Vehicular del MTC (Tracto):{" "}
              </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="const_vehicularTr"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="const_vehicularTr"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="const_vehicularTr"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="const_vehicularTr" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                Constancia de Habilitación Vehicular del MTC (Semi
                Remolque/Remolque):{" "}
              </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="const_vehicularSe"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="const_vehicularSe"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="const_vehicularSe"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="const_vehicularSe" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                Revisión Técnica Vehicular vigente (Tracto):{" "}
              </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="revi_trac"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="revi_trac"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="revi_trac"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="revi_trac" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                Revisión Técnica Vehicular vigente (Semi Remolque/Remolque):{" "}
              </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="revi_semi"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="revi_semi"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="revi_semi"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="revi_semi" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                Seguro Obligatorio de Tránsito vigente (SOAT):{" "}
              </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="segu_oblig"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="segu_oblig"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="segu_oblig"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="segu_oblig" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                Póliza Responsabilidad Civil frente a terceros vigente (Tracto):{" "}
              </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="poliza_trac"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="poliza_trac"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="poliza_trac"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="poliza_trac" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                Póliza Responsabilidad Civil frente a terceros vigente (Semi
                Remolque/Remolque):{" "}
              </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="poliza_semi"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="poliza_semi"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="poliza_semi"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="poliza_semi" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>Tarjeta de Propiedad del Tracto: </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tarjeta_trac"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tarjeta_trac"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tarjeta_trac"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="tarjeta_trac" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                Tarjeta de Propiedad de Remolque/Semiremolque:{" "}
              </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tarjeta_remol"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tarjeta_remol"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tarjeta_remol"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="tarjeta_remol" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>Plan de Contingencias: </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="plan_contin"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="plan_contin"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="plan_contin"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="plan_contin" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <h2
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                Verificación de Documentos y Equipos del Conductor
              </h2>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>Licencia de Conducir vigente : </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="licencia_vi"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="licencia_vi"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="licencia_vi"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="licencia_vi" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>SCTR Vigente (Salud y Pensión) : </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="sctr"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="sctr"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="sctr"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="sctr" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                Equipo de Protección Personal Básico ( Casco, zapatos punta
                reforzada, lentes de seguridad, chaleco reflectivo, guantes de
                cuero):{" "}
              </Form.Label>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="equipo_perso"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="equipo_perso"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="equipo_perso"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="equipo_perso" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Title>Inspección del Tracto | Evaluar</Title>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Encendido y Apagado del Motor: </Form.Label>
              <List elements={["Encendido y Apagado Adecuado."]} />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="encen_eva"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="encen_eva"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="encen_eva"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="encen_eva" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Baterias: </Form.Label>
              <List
                elements={[
                  "Cubiertas y Bornes protegidos con elemento aislante. Caja de la batería.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="baterias"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="baterias"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="baterias"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="baterias" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Tanques de Combustible, agua y aire </Form.Label>
              <List elements={["Sin abolladuras, soldaduras."]} />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tanques"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tanques"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tanques"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="tanques" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                {" "}
                Bloqueador general de corriente electrica{" "}
              </Form.Label>
              <List
                elements={[
                  "Que permita desenergizar  el equipo, recomendable fuera de la cabina.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="bloqueador"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="bloqueador"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="bloqueador"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="bloqueador" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Luces(Frontales, Laterales) </Form.Label>
              <List
                elements={[
                  "Altas bajas, direccionales, de freno y parqueo funcionando (verificar micas).",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="luces"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luces"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luces"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="luces" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                {" "}
                Alarmas de Retroceso (Tracto y acoplado){" "}
              </Form.Label>
              <List
                elements={[
                  "Que se active automáticamente al colocar la marcha en retroceso.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="alarmas"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="alarmas"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="alarmas"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="alarmas" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Quinta Rueda (Tornamesa) </Form.Label>
              <List
                elements={[
                  "king pin acoplado correctamente, engrasado en caso aplique.Placa del patín (estructura de fijación del pin que ingresa en la quinta rueda).",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="quinta_ru"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="quinta_ru"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="quinta_ru"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="quinta_ru" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Espejos Retrovisores </Form.Label>
              <List
                elements={[
                  "Buen estado, mínimo uno a cada lado y con ángulo amplio de visión.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="espejos"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="espejos"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="espejos"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="espejos" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Parabrisas y Limpiadores </Form.Label>
              <List
                elements={[
                  "Sin rajaduras, sin  quiños y en buen estado, que active la bomba de agua.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="parabrisas"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="parabrisas"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="parabrisas"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="parabrisas" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                {" "}
                Estado de neumáticos (Instalados y de respuesto){" "}
              </Form.Label>
              <List
                elements={[
                  "Profundidad de cocada no menor a 6mm. Sin de desgaste irregular de banda de rodamiento.",
                  "Máximo 2 reencauches en neumáticos de tracción; en posición direccional deben ser originales,",
                  "En buen estado, sin cortes, grietas diagonales, aberturas.",
                  "Aro y pestaña en buen estado.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_neumatico"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_neumatico"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_neumatico"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="estado_neumatico" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Estado General de Vehículo </Form.Label>
              <List
                elements={[
                  "Puertas y ventanas operativas, orden y limpieza en cabina de conductor, cinturones de seguridad operativos).",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_general"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_general"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_general"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="estado_general" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                {" "}
                Compartimientos del interior de la cabina y litera. Sección de
                pasajeros y techo de la cabina.{" "}
              </Form.Label>
              <List
                elements={[
                  "Sin alteración en los compartimientos, sin evidencia de soldadura.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="compartimiento"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="compartimiento"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="compartimiento"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="compartimiento" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Tuercas y Espárragos de Neumaticos </Form.Label>
              <List
                elements={[
                  "Marcadas  y en buen estado, recomendable trabatuercas en neumáticos direccionales.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tuercas"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tuercas"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tuercas"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="tuercas" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                {" "}
                Conos de seguridad altura minima 28 Pulgadas{" "}
              </Form.Label>
              <List
                elements={[
                  " Mínimo 4 con 1 banda de cinta reflectiva blanca de 2 pulgadas.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="conos"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="conos"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="conos"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="conos" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                {" "}
                Tacos o cuñas de seguridad con agarraderas{" "}
              </Form.Label>
              <List
                elements={[
                  "02 unidades en buen estado y dimensiones apropiadas para el vehículo.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tacos"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tacos"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tacos"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="tacos" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Kit de Herramientas </Form.Label>
              <List
                elements={[
                  "Herramientas básicas para el cambio de neumáticos , llaves mixtas, desarmadores, alicate, linterna, palanca de trinca, cable para carga de bateria, cable de acero para remolque, medidor de presión del neumático).",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_herrami"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_herrami"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_herrami"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="kit_herrami" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Kit de Anti Derrame </Form.Label>
              <List
                elements={[
                  "Equipamiento mínimo (15 paños absorventes, 02 salchichas absorventes, 01 bandeja antiderrame, 05 sacos para material contaminado, pala, pico).",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_anderrame"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_anderrame"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_anderrame"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="kit_anderrame" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Botiquín de Primeros Auxilios </Form.Label>
              <List
                elements={[
                  "De acuerdo al MTC (01 alcohol de 70° de 120 ml, 01 jabón antiséptico, 10 gasas esterilizadas fraccionadas de 10x10 cm, esparadrapo de 2.5 x 5m, venda elástica de 4x5 yardas,05 bandas adhesivas, 01 tijera punta roma de 3, 01 guante quirúrgico esterilizado de 7 1/2 (pares), algodón por 50 gramos.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="botiquin"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="botiquin"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="botiquin"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="botiquin" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Radio base de comunicación </Form.Label>
              <List elements={["Operativo."]} />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="radio"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="radio"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="radio"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="radio" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                {" "}
                Bocina del Vehículo (Claxon neumático y eléctrico){" "}
              </Form.Label>
              <List elements={["Operativo."]} />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="bocina"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="bocina"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="bocina"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="bocina" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Circulina ( En caso aplique) </Form.Label>
              <List elements={["Operativo."]} />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="circulina"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="circulina"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="circulina"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="circulina" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Title>Inspección del Acoplado | Evaluar</Title>
            <Tarjeta>
              <Form.Label> Luces (Laterales y Posteriores) </Form.Label>
              <List elements={["Operativa y protegidas."]} />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="luces_lateral"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luces_lateral"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luces_lateral"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="luces_lateral" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                {" "}
                02 Extintores PQS (09 Kg - colocados en soporte externo en
                semiremolque o tracto).{" "}
              </Form.Label>
              <List
                elements={[
                  "Fecha de recarga, con precintos en buen estado y  tarjetas de Inspección.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="extintores"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="extintores"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="extintores"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="extintores" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                {" "}
                Defensa anti-empotramiento lateral y trasera{" "}
              </Form.Label>
              <List
                elements={[
                  "Que sobresalga y proteja, con señalización reflectiva de acuerdo al MTC.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="defensa_anti"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="defensa_anti"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="defensa_anti"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="defensa_anti" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Sistema de suspensión </Form.Label>
              <List
                elements={[
                  "Paquete de muelles sin ruptura, montados en pin, abrazaderas en posición correcta  y ajustadas.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="sistema_sus"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="sistema_sus"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="sistema_sus"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="sistema_sus" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Estado de neumáticos instalados </Form.Label>
              <List
                elements={[
                  "Profundidad de cocada no menor a 6mm. Sin de desgaste irregular de banda de rodamiento.",
                  "Máximo 2 reencauches en neumáticos del acoplado.",
                  "En buen estado, sin cortes, grietas diagonales, aberturas.",
                  "Aro y pestaña en buen estado.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_neuma"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_neuma"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_neuma"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="estado_neuma" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Estado de Chasis/Estructura </Form.Label>
              <List elements={["En buen estado."]} />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_chasis"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_chasis"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_chasis"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="estado_chasis" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Pata Mecánica </Form.Label>
              <List
                elements={["Sin abolladuras, puntos de soldadura, tapas."]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="pata_meca"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pata_meca"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pata_meca"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="pata_meca" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                {" "}
                Puertas y compartimientos de herramientas{" "}
              </Form.Label>
              <List
                elements={[
                  "Sin compartimientos adicionales al interior, sin puntos de soldadura, abolladuras, de fácil acceso.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="puertas_compar"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="puertas_compar"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="puertas_compar"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="puertas_compar" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Tuercas y Espárragos de Neumáticos </Form.Label>
              <List elements={["Marcadas y en buen estado."]} />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tuercas_espar"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tuercas_espar"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tuercas_espar"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="tuercas_espar" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Manparón alto (mínimo 1.8 m) </Form.Label>
              <List elements={["Verificar Estado."]} />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="manparon"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="manparon"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="manparon"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="manparon" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Piso del acoplado </Form.Label>
              <List
                elements={[
                  "Sin compartimientos adicionales al interior, sin puntos de soldadura, abolladuras.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="piso_acoplado"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="piso_acoplado"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="piso_acoplado"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="piso_acoplado" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label> Contenedor </Form.Label>
              <List
                elements={[
                  "Sin compartimientos adicionales al interior, sin puntos de soldadura, abolladuras.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="contenedor"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="contenedor"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="contenedor"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="contenedor" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Form.Label>
                {" "}
                Elementos de aseguramiento de contenedor (Puntos de anclaje,
                ratchets, cadenas,piñas){" "}
              </Form.Label>
              <List
                elements={[
                  "En buen estado y cantidad suficiente para el tipo de carga.",
                ]}
              />
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="elementos_aseg"
                    type="radio"
                    value="CUMPLE"
                    label="CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="elementos_aseg"
                    type="radio"
                    value="NO CUMPLE"
                    label="NO CUMPLE"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="elementos_aseg"
                    type="radio"
                    value="NO APLICA"
                    label="NO APLICA"
                  />
                  <ErrorMessage name="elementos_aseg" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <FieldArray name="supervisores">
              {({ push, remove }) => (
                <>
                  <Tarjeta>
                    <Subtitle>Datos de los Supervisores</Subtitle>
                  </Tarjeta>
                  {values.supervisores.map((supervisor, index) => {
                    return (
                      <Tarjeta key={index}>
                        <Form.Label>Supervisor</Form.Label>
                        <Field
                          name={`supervisores[${index}].id`}
                          type="text"
                          as="select"
                          className="form-select"
                        >
                          <option value="">Seleccionar</option>
                          {Object.entries(supervisores)
                            .filter(([id, supervisor]) => {
                              return supervisor.area === "Supervisor";
                            })
                            .map(([id, supervisor]) => {
                              return (
                                <option key={id} value={id}>
                                  {supervisor.name}
                                </option>
                              );
                            })}
                        </Field>
                        <br/>
                        <ErrorMessage
                          name={`supervisores[${index}].id`}
                          as={Form.Label}
                        >
                          {(message) => <ErrorM>{message}</ErrorM>}
                        </ErrorMessage>
                        {index!==0 && <DeleteButton onClick={() => remove(index)} />}
                        {index === values.supervisores.length - 1 && (
                          <ActionButton
                            type="add"
                            float="right"
                            onClick={() =>
                              push({
                                id: "",
                              })
                            }
                          />
                            
                        )}
                      </Tarjeta>
                    );
                  })}
                </>
              )}
            </FieldArray>
            <Button type="submit"> Aceptar </Button>
          </FormMK>
        )}
      </Formik>
    </Container>
  );
};
export default VehiculosPesadosFormat;
