import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

toast.configure();
const Toast = (value, event) => {
  if (event === "success") {
    toast.success(value, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  } else if (event === "error") {
    toast.error(value, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  } else if (event === "warning") {
    toast.warn(value, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  } else if (event === "info") {
    toast.info(value, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  }
};
export default Toast;
