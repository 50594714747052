import styled from "styled-components";
export const TableWrapper = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  margin-top: 1rem;
  border-radius: 20px;
`;
export const Table = styled.table`
  border-collapse: collapse;

  width: 100%;
  table-layout: auto;
  & tbody tr:nth-child(even) {
    background-color: #f5f5f5;
  }
`;
export const TableData = styled.td`
  padding: 12px 15px;
  text-align: center;
  font-size: 11px;
  min-width: 125px;
  @media screen and (min-width: 900px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1400px) {
    font-size: 14px;
  }
`;
export const TableH = styled.th`
  padding: 10px 10px;
  text-align: center;
  font-size: 11px;
  border: 1px solid #264653;
  background-color: #1f487e;
  color: #fff;
  font-family: "Montserrat";
  @media screen and (min-width: 900px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1400px) {
    font-size: 14px;
  }
`;
