import React from "react";
import styled from "styled-components";
const Cuadrados = (props) => {
  return (
    <AnimationArea>
      {props.children}
      <BoxArea>
        <BoxAreaLi></BoxAreaLi>
        <BoxAreaLi></BoxAreaLi>
        <BoxAreaLi></BoxAreaLi>
        <BoxAreaLi></BoxAreaLi>
        <BoxAreaLi></BoxAreaLi>
        <BoxAreaLi></BoxAreaLi>
      </BoxArea>
    </AnimationArea>
  );
};
const AnimationArea = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BoxArea = styled.ul`
  background: linear-gradient(to left, #8942a8, #ba382f);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`;
const BoxAreaLi = styled.li`
  position: absolute;
  display: block;
  list-style: none;
  width: 25px;
  height: 25px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 20s linear infinite;
  bottom: -100px;
  &: nth-child(1) {
    left: 86%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  &: nth-child(2) {
    left: 12%;
    width: 30px;
    height: 30px;
    animation-delay: 1.5s;
    animation-duration: 10s;
  }
  &: nth-child(3) {
    left: 70%;
    width: 100px;
    height: 100px;
    animation-delay: 5.5s;
  }
  &: nth-child(4) {
    left: 70%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
  }
  &: nth-child(5) {
    left: 65%;
    width: 40px;
    height: 40px;
    animation-delay: 0s;
  }
  &: nth-child(6) {
    left: 15%;
    width: 110px;
    height: 110px;
    animation-delay: 3.5s;
  }
  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(-800px) rotate(360deg);
      opacity: 0;
    }
  }
`;
export default Cuadrados;
