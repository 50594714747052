import { jsPDF } from 'jspdf'
import { firebase } from '../firebase-configs'
import LatamIcon from '../icons/latamIcon'
import StarIcon from '../icons/starIcon'

const firebaseDB = firebase.database()


let doc = new jsPDF()
let row = 15
let column = 130
let isTable = true
let isFirm = false

const constructPDF = (title, questions, data) => {
	doc.setFontSize(20)
	const headerdoc = doc.splitTextToSize(title.nombre,150)
	if(headerdoc.length !== 1){
		doc.text(headerdoc,(90-title.nombre.length) + 28, row)
	}
	else{
		doc.text(headerdoc,90-title.nombre.length, row)
	}
	row=row+6+(headerdoc.length*2)
	doc.setFontSize(11)
	questions.map((item) => {
		row = row + 8
		if(item.type === 'Documento'){
			if(isFirm){
				row = row + 56
				isFirm = false
			}
			doc.setFont("","normal")
			doc.text(item.title + ' (' + data[item.title].docType + '): ' + data[item.title].number, 10, row)
			isTable = true
		}
		else if(item.type === 'Texto'){
			if(isFirm){
				row = row + 56
				isFirm = false
			}
			const splice = doc.splitTextToSize(item.title, 178)
			doc.setFont("","bold")
			doc.text(splice, 10, row)
			row = row + (splice.length - 1) * 4
			isTable = true
		}
		else if(item.type === 'Casilla'){
			if(isFirm){
				row = row + 56
				isFirm = false
			}
			doc.setFont("","normal")
			if(isTable){
				doc.cell(10, row - 14, 100, 10, ' ')
				doc.cell(110, row - 14, 15, 10, 'Si')
				doc.cell(125, row - 14, 15, 10, 'No')
				isTable = false
			}
			
			doc.cell(10, row - 4, 100, 8, item.title)
			if(data[item.title] === 'true'){
				doc.cell(110, row - 4, 15, 8, 'X')
				doc.cell(125, row - 4, 15, 8, ' ')
			}
			else{
				doc.cell(110, row - 4, 15, 8, ' ')
				doc.cell(125, row - 4, 15, 8, 'X')
			}
		}
		else if(item.type === 'Firma'){
			isFirm = true
			doc.addImage(data[item.title], 'PNG', column, row, 55, 40)
			doc.cell(column, row + 40, 55, 0, ' ')
			doc.text(item.title, column + Math.abs(25 - item.title.length), row + 48)
			row = row - 8
			column = column - 60
			doc.setFont("","normal")
		}
		else if(item.type === 'Seleccionable'){
			if(isFirm){
				row = row + 56
				isFirm = false
			}
			doc.setFont("","normal")
			doc.text(item.title + ': ' + data[item.title], 10, row)
			if(data[item.title] === 'LATAM'){
				doc.addImage(LatamIcon, 'PNG', 8, 8, 36.66, 13.1) //1100 * 393
			}
			else{
				doc.addImage(StarIcon, 'PNG', 9, 1, 25, 25)
			}
		}
		else{
			if(isFirm){
				row = row + 56
				isFirm = false
			}
			doc.setFont("","normal")
			doc.text(item.title + ': ' + data[item.title], 10, row)
		}
		return false
	})
	doc.text('WG-CONSULTORES  wgallardo@cip.org.pe 944770105', 57, 290)
	showData(title.nombre)
}

const showData = (title) => {
	window.open(doc.output('bloburl'), '_blank')
	doc.save(title + '.pdf')
}

const dowloadPdf = (toConvetData) => {
	doc = new jsPDF()
	row = 15
	column = 130
	isFirm = false
	const dataRef = firebaseDB.ref("Formularios/" + toConvetData.formKey);
	dataRef.once("value").then((snapshot) => {
		if (snapshot.val() !== null) {
			if (snapshot.val() !== undefined) {
				const title = snapshot.val()
				const questionsRef = firebaseDB.ref("Preguntas/" + toConvetData.formKey);
				questionsRef.once("value").then((snapshot) => {
					if (snapshot.val() !== null) {
						if (snapshot.val() !== undefined) {
							constructPDF(title, snapshot.val(), toConvetData)
							questionsRef.off();
						}
					}
				});
				dataRef.off();
			}
		}
	});
}

export default { dowloadPdf }