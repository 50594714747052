import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/NavbarVF";
import NavbarSupervisor from "../components/Navbar/NavBarSupervisor";
import NavbarTrabajador from "../components/Navbar/NavBarTrabajador";
import { Title } from "../components/FrontComponents/TextType";
import { Content } from "../components/FrontComponents/Structure";
import {
  TableH,
  Table,
  TableData,
  TableWrapper,
} from "../components/FrontComponents/Table";
import { ActionButton } from "../components/FrontComponents/ActionButtons/actionbutton";
import { getUserData, cloneUserData } from "../services/userData";
import { getAnswerData, getManualFormsData, getWorkerData } from "../services/databaseData";
import { downloadIpercPdf } from "../services/pdfManualExport/ipercExportPdf"
import { downloadVehiculosLigerosPdf } from "../services/pdfManualExport/vehiculosLigerosExportPdf"
import { downloadVehiculosPesadosPdf } from "../services/pdfManualExport/vehiculosPesadosFormatPdf"
import { updateAnswer, removeNotification } from "../services/uploadToDatabase";
import MMedidaCorrectiva from "../components/Modales/MMedidaCorrectiva";
let userData
const Solicitudes = () => {
  const [notifications, setNotifications] = useState(null)
  const [currentAnswerId, setCurrentAnswerId] = useState()
  const [show, setShow] = useState()
  const fetchData = async(data) => {
    setNotifications(data);
  }; 
  const showPdf = async (answerId) => {
    const data = await getAnswerData(answerId);
    if (data.formKey === "VehiculosPesados") {
      downloadVehiculosPesadosPdf(data);
    } else if (data.formKey === "VehiculosLigeros") {
      downloadVehiculosLigerosPdf(data);
    } else if (data.formKey === "Iperc") {
      downloadIpercPdf(data);
    }
  }
  
  const accept = async(answerId, payload) => {
    if(payload.type === "check"){
      const response = await updateAnswer({[userData.uid]: {acceptDate: Date.now()}}, answerId)
      if (response.error === "") {
        const notResponse = await removeNotification(userData.uid, answerId)
        if (notResponse.error === ""){
          console.log("Se firmo correctamente");
          await initialize();
        } else {
          console.log(response.error);
        }
      } else {
        console.log(response.error);
      }
    } else {
      setCurrentAnswerId(answerId)
      setShow(true)
    }
  }

  const handleSubmit = async(values) => {
    const response = await updateAnswer({[userData.uid]: {acceptDate: Date.now(), medida_correctiva: values.medida_correctiva}}, currentAnswerId)
    if (response.error === "") {
      const notResponse = await removeNotification(userData.uid, currentAnswerId)
      if (notResponse.error === ""){
        await initialize();
        setShow(false)
        console.log("Se firmo correctamente");
      } else {
        console.log(notResponse.error);
      }
    } else {
      console.log(response.error);
    }
  }

  const handleReject = async(answerId) => {
    const notResponse = await removeNotification(userData.uid, answerId)
    if (notResponse.error === ""){
      console.log("Se rechazo el formato");
      await initialize();
    } else {
      console.log(notResponse.error);
    }
  }
  
  const initialize = async() => {
    await cloneUserData()
    userData = getUserData();
    if (userData.notifications !== undefined) {
      const formsData = await getManualFormsData()
      const toFetch = {... userData.notifications}
      await Promise.all(
        Object.keys(userData.notifications).map(async(key) => {
          const answerData = await getAnswerData(key)
          let workerData = await getWorkerData(answerData.userId)
          if (workerData === null || workerData.name === null || workerData.name === undefined){
            workerData = {name: "Not found"}
          }
          toFetch[key].formName = formsData[answerData.formKey].nombre
          toFetch[key].createDate = answerData.createDate
          toFetch[key].workerName = workerData.name
        })
      )
      fetchData(toFetch);
    }
  };
  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      {getUserData().area === "Administrador" ? (
        <Navbar />
      ) : getUserData().area === "Supervisor" ? (
        <NavbarSupervisor />
      ) : (
        <NavbarTrabajador />
      )}
      <Content>
        <Title>Solicitudes o documentos por Aprobar </Title>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <TableH>Nombre del solicitante</TableH>
                <TableH>Nombre del formato</TableH>
                <TableH>Fecha</TableH>
                <TableH>Acciones</TableH>
              </tr>
            </thead>
            <tbody>
              {notifications &&
                Object.entries(notifications).map(([id, value]) => {
                  const date = new Date(value.createDate);
                  return (
                    <tr key={id}>
                      <TableData>{value.workerName}</TableData>
                      <TableData>{value.formName}</TableData>
                      <TableData>{ date.toLocaleDateString() + " - " + date.toLocaleTimeString() }</TableData>
                      <TableData>
                        <ActionButton
                          type="accept"
                          onClick={() => accept(id, value)}
                        />
                        <ActionButton type="view" onClick={() => showPdf(id)}/> 
                        <ActionButton type="reject" onClick={() => handleReject(id)} />
                      </TableData>
                    </tr>
                  );
                })}
              {show && (
                <MMedidaCorrectiva
                  show={show}
                  onHide={() => setShow(false)}
                  onSubmit={handleSubmit}
                />
              )}
            </tbody>
          </Table>
        </TableWrapper>
      </Content>
    </>
  );
};

export default Solicitudes;
