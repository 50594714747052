import React, { useState } from "react";
import { Title } from "../components/FrontComponents/TextType";
import { Button, ButtonWrapper } from "../components/FrontComponents/Buttons";
import {
  Table,
  TableData,
  TableH,
  TableWrapper,
} from "../components/FrontComponents/Table";
import { Content } from "../components/FrontComponents/Structure";
import AgregarTrabajadores from "../components/Modales/Modal";
import Navbar from "../components/Navbar/NavbarVF";
import NavbarSupervisor from "../components/Navbar/NavBarSupervisor";
import NavbarTrabajador from "../components/Navbar/NavBarTrabajador";
import { getUserData } from "../services/userData";
import { firebase } from "../firebase-configs";
import { useEffect } from "react";
import * as FaIcons from "react-icons/fa";
import { ActionButton } from "../components/FrontComponents/ActionButtons/actionbutton";
import { getWorkersData } from "../services/databaseData";
import { createUser, deleteUser } from "../services/apiRestConnection";
import { uploadNewUser, removeUser } from "../services/uploadToDatabase";
import Toast from "../components/FrontComponents/toast";

const Trabajadores = () => {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [workersData, setWorkersData] = useState([]);
  const [currentWorker, setCurrentWorker] = useState({});
  const [currentUid, setCurrentUid] = useState();
  const [toFetch, setToFetch] = useState();
  const handleClick = () => {
    setShow(true);
    setEdit(false);
  };

  const handleSubmit = async (values) => {
    if (edit) {
      const out = { ...values };
      if (typeof values.firma !== "string") {
        const strFirma = await imgToDataUrl(values.firma);
        out.firma = strFirma;
      }
      if (typeof values.photo !== "string") {
        const strPhoto = await imgToDataUrl(values.photo);
        out.photo = strPhoto;
      }
      if (typeof values.logo !== "string") {
        const strLogo = await imgToDataUrl(values.logo);
        out.logo = strLogo;
      }
      const response = await uploadNewUser(
        {
          name: out.name,
          email: out.email,
          area: out.area,
          firma: out.firma,
          photo: out.photo,
          logo: out.logo,
          empresa: out.empresa,
        },
        currentUid
      );
      if (response.error === "") {
        Toast("Se modificó correctamente.", "success");
        await initialize();
        setShow(false);
      } else {
        Toast("Ocurrió un error.", "error");
      }
    } else {
      const strPhoto = await imgToDataUrl(values.photo);
      const strFirma = await imgToDataUrl(values.firma);
      const strLogo = await imgToDataUrl(values.logo);
      const out = { ...values };
      out.photo = strPhoto;
      out.firma = strFirma;
      out.logo = strLogo;
      const newUser = await createUser({
        email: values.email,
        password: values.password,
      });

      if (newUser.error === undefined) {
        const response = await uploadNewUser(
          {
            name: out.name,
            email: out.email,
            area: out.area,
            firma: out.firma,
            photo: out.photo,
            logo: out.logo,
            empresa: out.empresa,
          },
          newUser.uid
        );
        if (response.error === "") {
          Toast("Se ingreso correctamente.", "success");
          await initialize();
          setShow(false);
        } else {
          Toast("Ocurrió un error.", "error");
        }
      } else {
        Toast("Ocurrio algo inesperado.", "error");
      }
    }
  };

  const handleRemove = async (uid) => {
    const deleteResponse = await deleteUser(uid);
    if (deleteResponse.error === undefined) {
      const response = await removeUser(uid);
      if (response.error === "") {
        Toast("Se elimino correctamente.", "success");
        await initialize();
      } else {
        Toast("Ocurrió un error.", "error");
      }
    } else {
      Toast("Ocurrió un error.", "error");
    }
  };

  const handleEdit = (data, uid) => {
    setCurrentWorker(data);
    setCurrentUid(uid);
    setShow(true);
    setEdit(true);
  };

  const fetchData = () => {
    const fetch = Object.keys(workersData)
      .filter((index) => {
        return (
          workersData[index].area !== "Administrador"
        );
      })
      .map((index) => {
        return (
          <tr key={index}>
            <TableData>{workersData[index].name}</TableData>
            <TableData>{workersData[index].area}</TableData>
            <TableData>{workersData[index].email}</TableData>
            <TableData>
              <ActionButton
                onClick={() => handleEdit(workersData[index], index)}
                type="edit"
              />
              <ActionButton onClick={() => handleRemove(index)} type="remove" />
            </TableData>
          </tr>
        );
      });
    setToFetch(fetch);
  };

  const imgToDataUrl = (file) => {
    const canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d"),
      img = document.createElement("img"),
      reader = new FileReader();

    reader.onload = function (e) {
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      img.onload = () => {
        ctx.canvas.width = 200;
        ctx.canvas.height = 200;
        ctx.drawImage(img, 0, 0, 200, 200);
        const out = canvas.toDataURL("image/png");
        resolve(out);
      };
    });
  };

  const initialize = async () => {
    const workersData = await getWorkersData();
    setWorkersData(workersData);
  };
  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    fetchData();
  }, [workersData]);

  return (
    <>
      {getUserData().area === "Administrador" ? (
        <Navbar />
      ) : getUserData().area === "Supervisor" ? (
        <NavbarSupervisor />
      ) : (
        <NavbarTrabajador />
      )}
      <Content>
        <Title>Trabajadores</Title>
        <ButtonWrapper>
          <Button color="secondary" onClick={() => handleClick()}>
            <FaIcons.FaPlus></FaIcons.FaPlus> Agregar
          </Button>
          {show && (
            <AgregarTrabajadores
              edit={edit}
              show={show}
              worker={currentWorker}
              onHide={() => setShow(false)}
              onSubmit={handleSubmit}
            />
          )}
        </ButtonWrapper>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <TableH>Nombres y Apellidos</TableH>
                <TableH>Área</TableH>
                <TableH>Correo</TableH>
                <TableH>Acciones</TableH>
              </tr>
            </thead>
            <tbody>{toFetch}</tbody>
          </Table>
        </TableWrapper>
      </Content>
    </>
  );
};

export default Trabajadores;
