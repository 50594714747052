import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Tarjeta = ({ children }) => {
  return (
    <Container
      style={{
        backgroundColor: "white",
        borderRadius: "1rem",
        borderTop: "10px solid rgb(61, 143, 45)",
        borderLeft: "7px solid #4285f4",
        fontFamily: "Google Sans",
      }}
      className="mb-3 p-3"
    >
      <Col>
        <Row className="mb-1 p-2">
          <Col>{children}</Col>
        </Row>
      </Col>
    </Container>
  );
};


export default Tarjeta;
