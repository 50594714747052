import React, { useState } from "react";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";

const AddQuestion = (props) => {
  const [data, setData] = useState(null);
  const handleChange = (event) => {
    if (event.target.id === "title") {
      setData({ ...data, title: event.target.value });
    } else if (event.target.id === "type") {
      setData({ ...data, type: event.target.value });
    }
  };
  const enterComponent = (show) => {
    if (show) {
      if (data === null) {
        setData(props.initialData);
      }
    } else {
      if (data !== null) {
        setData(null);
      }
    }
    return show;
  };
  return (
    <Modal
      show={enterComponent(props.handleShow)}
      onHide={props.hide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Row>
          <Col>
            <h2>{props.type} Pregunta</h2>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Form.Label className="fw-bold">Titulo de Pregunta</Form.Label>
              <Form.Control
                required
                id={"title"}
                style={{ width: "100%" }}
                type="text"
                placeholder="Titulo de la pregunta"
                defaultValue={props.initialData.title}
                onChange={handleChange}
              />
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label className="fw-bold">Tipo de Pregunta</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue="Choose..."
                  id={"type"}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  defaultValue={props.initialData.type}
                >
                  <option>Respuesta</option>
                  <option>Casilla</option>
                  <option>Fecha</option>
                  <option>Texto</option>
                  <option>Documento</option>
                  <option>Firma</option>
                  <option>Seleccionable</option>
                </Form.Control>
              </Form.Group>
              <br />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col>
            <Button
              id={props.type}
              className="mt-2"
              onClick={(event) => props.submit(event, data)}
            >
              {props.type}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default AddQuestion;
