import styled from "styled-components";
const ListElement = styled.li`
  font-size: 1rem;
`;
export const List = ({ elements }) => {
  return (
    <ul>
      {elements.map((element, index) => {
        return <ListElement key={index}>{element}</ListElement>;
      })}
    </ul>
  );
};
