import { Formik, Field, Form as FormMK, ErrorMessage } from "formik";
import React from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import Tarjeta from "../components/Tarjeta";
import Firm from "../components/firm/firm";
import ErrorM from "../components/ErrorMessage";
import { firebase } from "../firebase-configs";
import Toast from "../components/toast";
import pdf from "../services/plusPetrolExportPdf";
import api from "../services/autocompleteData";
import * as Yup from "yup";
import { Redirect } from "react-router-dom";
import Inputs from "../components/Inputs";

const firebaseDB = firebase.database();

const validate = Yup.object({
  documento: Yup.string().required("El documento es necesario"),
  nro_documento: Yup.number().required("El número de documento es necesario"),
  nombres_apellidos: Yup.string().required(
    "Los Nombres y apellidos son necesarios"
  ),
  edad: Yup.number().required("La edad es necesaria"),
  telefono: Yup.number().required("El teléfono es necesario"),
  sexo: Yup.string().required("Es necesario marcar este campo"),
  estado_civil: Yup.string().required("Es necesario marcar este campo"),
  empresa: Yup.string().required("Es necesario llenar este campo"),
  area_trabajo: Yup.string().required("Es necesario llenar este campo"),
  puesto: Yup.string().required("Es necesario llenar este campo"),
  fecha_nacimiento: Yup.date().required("La fecha de nacimiento es necesaria"),
  procedencia: Yup.string().required("Es necesario llenar este campo"),
  domicilio: Yup.string().required("Es necesario llenar este campo"),
  contacto_sintomas: Yup.string().required("Es necesario marcar este campo"),
  reside: Yup.string().required("Es necesario marcar este campo"),
  habita: Yup.string().required("Es necesario marcar este campo"),
  hospitalizado: Yup.string().required("Es necesario marcar este campo"),
  tuvo_covid: Yup.string().required("Es necesario marcar este campo"),
  sintomas: Yup.string(),
  ant_pregunta1: Yup.string(),
  viajado_fuera: Yup.string().required("Es necesario marcar este campo"),
  ciudad_visito: Yup.string().required("Es necesario marcar este campo"),
  diabetes: Yup.string().required("Es necesario marcar este campo"),
  hipertension: Yup.string().required("Es necesario marcar este campo"),
  obesidad: Yup.string().required("Es necesario marcar este campo"),
  asma: Yup.string().required("Es necesario marcar este campo"),
  estado: Yup.string().required("Es necesario marcar este campo"),
  insuficiencia: Yup.string().required("Es necesario marcar este campo"),
  cardiovascular: Yup.string().required("Es necesario marcar este campo"),
  cancer: Yup.string().required("Es necesario marcar este campo"),
  otras_enfermedades: Yup.string().required("Es necesario marcar este campo"),
  otras_especificar: Yup.string("Es necesario marcar este campo"),
  fiebre: Yup.string().required("Es necesario marcar este campo"),
  tos: Yup.string().required("Es necesario marcar este campo"),
  disosmia: Yup.string().required("Es necesario marcar este campo"),
  disguesia: Yup.string().required("Es necesario marcar este campo"),
  disfagia: Yup.string().required("Es necesario marcar este campo"),
  malestar_general: Yup.string().required("Es necesario marcar este campo"),
  rinorrea: Yup.string().required("Es necesario marcar este campo"),
  cefalea: Yup.string().required("Es necesario marcar este campo"),
  disnea: Yup.string().required("Es necesario marcar este campo"),
  congestion_ocular: Yup.string().required("Es necesario marcar este campo"),
  dolor_muscular: Yup.string().required("Es necesario marcar este campo"),
  irritabilidad: Yup.string().required("Es necesario marcar este campo"),
  escalofrios: Yup.string().required("Es necesario marcar este campo"),
  expectoracion: Yup.string().required("Es necesario marcar este campo"),
  fotofobia: Yup.string().required("Es necesario marcar este campo"),
  diarrea: Yup.string().required("Es necesario marcar este campo"),
  nauseas: Yup.string().required("Es necesario marcar este campo"),
  vomito: Yup.string().required("Es necesario marcar este campo"),
  otalgia: Yup.string().required("Es necesario marcar este campo"),
  dolor_articular: Yup.string().required("Es necesario marcar este campo"),
  otros_sintomas: Yup.string().required("Es necesario marcar este campo"),
  otros_sintomas_especificar: Yup.string(),
  hotel: Yup.string(),
  aeropuerto: Yup.string(),
  otros: Yup.string(),
  firma: Yup.string().required("La firma es necesaria"),
});

const initialData = {
  documento: "",
  nro_documento: "",
  nombres_apellidos: "",
  edad: "",
  telefono: "",
  sexo: "",
  estado_civil: "",
  empresa: "",
  area_trabajo: "",
  puesto: "",
  fecha_nacimiento: "",
  procedencia: "",
  domicilio: "",
  contacto_sintomas: "",
  reside: "",
  habita: "",
  hospitalizado: "",
  sintomas: "",
  ant_pregunta1: "",
  viajado_fuera: "",
  ciudad_visito: "",
  diabetes: "",
  hipertension: "",
  obesidad: "",
  asma: "",
  estado: "",
  insuficiencia: "",
  cardiovascular: "",
  cancer: "",
  otras_enfermedades: "",
  otras_especificar: "",
  fiebre: "",
  tos: "",
  disosmia: "",
  disguesia: "",
  disfagia: "",
  malestar_general: "",
  rinorrea: "",
  cefalea: "",
  disnea: "",
  congestion_ocular: "",
  dolor_muscular: "",
  irritabilidad: "",
  escalofrios: "",
  expectoracion: "",
  fotofobia: "",
  diarrea: "",
  nauseas: "",
  vomito: "",
  otalgia: "",
  dolor_articular: "",
  otros_sintomas: "",
  otros_sintomas_especificar: "",
  hotel: "",
  aeropuerto: "",
  otros: "",
  firma: "",
  formKey: "PlusPetrol",
};

const SoliPetro = () => {
  const [toForm, setToForm] = React.useState(false);
  const handleSubmit = (values) => {
    Object.assign(values, { createDate: Date.now() });
    const answersRef = firebaseDB.ref("Respuestas/");
    answersRef.push(values).then(() => {
      Toast("Información subida Correctamente", "success");
      pdf.dowloadPdf(values);
      setToForm(true);
    });
  };

  const setAllTrue = (formik) => {
    const str = "no";
    formik.setFieldValue("contacto_sintomas", str);
    formik.setFieldValue("reside", str);
    formik.setFieldValue("habita", str);
    formik.setFieldValue("tuvo_covid", str);
    formik.setFieldValue("hospitalizado", str);
    formik.setFieldValue("viajado_fuera", str);
    formik.setFieldValue("ciudad_visito", str);
    formik.setFieldValue("diabetes", str);
    formik.setFieldValue("hipertension", str);
    formik.setFieldValue("obesidad", str);
    formik.setFieldValue("asma", str);
    formik.setFieldValue("estado", str);
    formik.setFieldValue("insuficiencia", str);
    formik.setFieldValue("cardiovascular", str);
    formik.setFieldValue("cancer", str);
    formik.setFieldValue("otras_enfermedades", str);
    formik.setFieldValue("fiebre", str);
    formik.setFieldValue("tos", str);
    formik.setFieldValue("disosmia", str);
    formik.setFieldValue("disguesia", str);
    formik.setFieldValue("disfagia", str);
    formik.setFieldValue("malestar_general", str);
    formik.setFieldValue("rinorrea", str);
    formik.setFieldValue("cefalea", str);
    formik.setFieldValue("disnea", str);
    formik.setFieldValue("congestion_ocular", str);
    formik.setFieldValue("dolor_muscular", str);
    formik.setFieldValue("irritabilidad", str);
    formik.setFieldValue("escalofrios", str);
    formik.setFieldValue("expectoracion", str);
    formik.setFieldValue("fotofobia", str);
    formik.setFieldValue("diarrea", str);
    formik.setFieldValue("nauseas", str);
    formik.setFieldValue("vomito", str);
    formik.setFieldValue("otalgia", str);
    formik.setFieldValue("dolor_articular", str);
    formik.setFieldValue("otros_sintomas", str);
  };

  const setNroDoc = async (event, formik) => {
    formik.setFieldValue("nro_documento", event.target.value);
    if (formik.values.documento === "DNI") {
      const dniData = await api.getData(event.target.value);
      if (dniData !== false) {
        const names =
          dniData.nombres +
          " " +
          dniData.apellidoPaterno +
          " " +
          dniData.apellidoMaterno;
        formik.setFieldValue("nombres_apellidos", names);
      } else {
        Toast("No se encontraron datos");
      }
    }
  };

  return (
    <>
      {toForm ? <Redirect to="/subido" /> : null}
      <Container style={{ backgroundColor: "#a3dfff" }} className="p-3">
        <Formik
          initialValues={initialData}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <FormMK>
              <Tarjeta>
                <h2
                  style={{ fontFamily: "Google Sans" }}
                  className="pt-1 fw-bold"
                >
                  Declaración Jurada PlusPetrol
                </h2>
              </Tarjeta>
              <Tarjeta>
                <h4
                  style={{ fontFamily: "Google Sans" }}
                  className="pt-1 fw-bold"
                >
                  1.- Identificación
                </h4>
              </Tarjeta>

              <Tarjeta>
                <Form.Label>Tipo de documento</Form.Label>
                <Field
                  name="documento"
                  type="text"
                  as="select"
                  className="form-select"
                >
                  <option value="">Seleccionar</option>
                  <option value="DNI">DNI</option>
                  <option value="Pasaporte">Pasaporte</option>
                  <option value="Otro">Otro</option>
                </Field>
                <ErrorMessage name="documento" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Número del documento</Form.Label>
                <Field name="nro_documento" type="number" as={Form.Control}>
                  {() => (
                    <Col>
                      <input
                        className="form-control"
                        le={{ width: "100%" }}
                        onBlur={(event) => setNroDoc(event, formik)}
                      ></input>
                    </Col>
                  )}
                </Field>
                <ErrorMessage name="nro_documento" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Apellidos y Nombres: </Form.Label>
                <Field
                  name="nombres_apellidos"
                  type="input"
                  as={Form.Control}
                  disabled={
                    formik.values.documento === "DNI" &&
                    formik.values.nro_documento !== ""
                      ? true
                      : false
                  }
                />
                <ErrorMessage name="nombres_apellidos" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Edad</Form.Label>
                <Field name="edad" type="number" as={Form.Control} />
                <ErrorMessage name="edad" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Teléfono</Form.Label>
                <Field name="telefono" type="number" as={Form.Control} />
                <ErrorMessage name="telefono" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Sexo: </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="sexo"
                      type="radio"
                      value="Masculino"
                      label="Masculino"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="sexo"
                      type="radio"
                      value="Femenino"
                      label="Femenino"
                    />
                  </Col>
                </Row>
                <ErrorMessage name="sexo" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Estado Civil: </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="estado_civil"
                      type="radio"
                      value="Soltero"
                      label="Soltero/a"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="estado_civil"
                      type="radio"
                      value="Casado"
                      label="Casado/a"
                    />
                    <Field
                      inline
                      as={Form.Check}
                      name="estado_civil"
                      type="radio"
                      value="Conviviente"
                      label="Conviviente"
                    />
                    <Field
                      inline
                      as={Form.Check}
                      name="estado_civil"
                      type="radio"
                      value="Viudo"
                      label="Viudo/a"
                    />
                  </Col>
                </Row>
                <ErrorMessage name="estado_civil" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Empresa: </Form.Label>
                <Field name="empresa" type="input" as={Form.Control} />
                <ErrorMessage name="empresa" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Area de trabajo: </Form.Label>
                <Field name="area_trabajo" type="input" as={Form.Control} />
                <ErrorMessage name="area_trabajo" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Puesto: </Form.Label>
                <Field name="puesto" type="input" as={Form.Control} />
                <ErrorMessage name="puesto" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Fecha de Nacimiento </Form.Label>
                <Field name="fecha_nacimiento" type="date" as={Form.Control} />
                <ErrorMessage name="fecha_nacimiento" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Procedencia: </Form.Label>
                <Field name="procedencia" type="input" as={Form.Control} />
                <ErrorMessage name="procedencia" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Domicilio: </Form.Label>
                <Field name="domicilio" type="input" as={Form.Control} />
                <ErrorMessage name="domicilio" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <h4 style={{ fontFamily: "Google Sans" }} className=" fw-bold">
                  2.-Antecedentes
                </h4>
              </Tarjeta>
              <Tarjeta>
                <h5 style={{ fontFamily: "Google Sans" }} className=" fw-bold">
                  2.1.-Epidemiología
                </h5>
              </Tarjeta>
              <Tarjeta>
                Presionar el botón si usted quiere responder a todas las
                preguntas en no: <br />
                <Button
                  className="mt-1"
                  onClick={() => setAllTrue(formik)}
                  variant="danger"
                >
                  Marcar Todo en no
                </Button>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>
                  ¿Ha tenido contacto con alguna persona con síntomas de
                  infección respiratoria en los últimos 14 días?{" "}
                </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="contacto_sintomas"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="contacto_sintomas"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="contacto_sintomas" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>
                  Reside o ha viajado, en los ultimos 14 días a ciudades del
                  Perú fuera de Lima?
                </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="reside"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="reside"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="reside" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>
                  En su domicilio, habita personal de salud en actividad
                  atendiendo pacientes con COVID-19?
                </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="habita"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="habita"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="habita" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Usted tuvo diagnostico de COVID 19 ?</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="tuvo_covid"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="tuvo_covid"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="habita" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>¿Estuvo hospitalizado?</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="hospitalizado"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="hospitalizado"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="hospitalizado" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>¿Cuales fueron sus síntomas?</Form.Label>
                <Field as={Form.Control} name="sintomas" type="input" />
                <ErrorMessage name="sintomas" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Fecha de diagnóstico</Form.Label>
                <Field as={Form.Control} name="ant_pregunta1" type="date" />
                <ErrorMessage name="ant_pregunta1" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <h5 style={{ fontFamily: "Google Sans" }} className=" fw-bold">
                  2.2.-Viajes
                </h5>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>
                  ¿Ha viajado fuera del Perú en los últimos 14 días?
                </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="viajado_fuera"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="viajado_fuera"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="viajado_fuera" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>
                  Si viajó, ¿En la ciudad que visitó había casos de infección
                  por coronavirus?
                </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="ciudad_visito"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="ciudad_visito"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="ciudad_visito" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <h5 style={{ fontFamily: "Google Sans" }} className=" fw-bold">
                  2.3.-Comorbilidad / enfermedades crónicas
                </h5>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Diabetes mellitos</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="diabetes"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="diabetes"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="diabetes" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Hipertensión arterial:</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="hipertension"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="hipertension"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="hipertension" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Obesidad IMC{">"}35</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="obesidad"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="obesidad"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="obesidad" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>
                  Asma bronquial, Enfermedad pulmonar crónica
                </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="asma"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="asma"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="asma" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>
                  Enfermedad, tratamiento y estado inmuno supresor?
                </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="estado"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="estado"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="estado" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Insuficiencia renal crónica</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="insuficiencia"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="insuficiencia"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="insuficiencia" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Enfermedad cardiovascular</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="cardiovascular"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="cardiovascular"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="cardiovascular" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Cáncer</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="cancer"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="cancer"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="cancer" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Otras Enfermedades</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="otras_enfermedades"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="otras_enfermedades"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="otras_enfermedades" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Especifique</Form.Label>

                <Field
                  disabled={
                    formik.values.otras_enfermedades === "si" ? false : true
                  }
                  as={Form.Control}
                  name="otras_especificar"
                  type="input"
                />
                <ErrorMessage name="otras_especificar" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <h4
                  style={{ fontFamily: "Google Sans" }}
                  className="pt-1 fw-bold"
                >
                  3.- Síntomas
                </h4>
                <Form.Label>
                  En las últimas 48 horas ha presentado alguno de los siguientes
                  síntomas:
                </Form.Label>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Fiebre ((T°{">"}37,5°C) </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="fiebre"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="fiebre"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="fiebre" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Tos</Form.Label>
                <Row>
                  <Col>
                    {" "}
                    <Field
                      inline
                      as={Form.Check}
                      name="tos"
                      type="radio"
                      value="si"
                      label="Si"
                    />
                    <Field
                      inline
                      as={Form.Check}
                      name="tos"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="tos" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Alteración del olfato (disosmia)</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="disosmia"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="disosmia"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="disosmia" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Alteración del gusto (disgeusia)</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="disguesia"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="disguesia"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="disguesia" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Dolor de garganta (disfagia)</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="disfagia"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="disfagia"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="disfagia" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Malestar general</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="malestar_general"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="malestar_general"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="malestar_general" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Congestión o secreción nasal (rinorrea)</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="rinorrea"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="rinorrea"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="rinorrea" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Dolor de cabeza (cefalea)</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="cefalea"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="cefalea"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="cefalea" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>
                  Dificultad respiratoria (disnea, sensación de falta de aire)
                </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="disnea"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="disnea"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="disnea" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>
                  Enrojecimiento ocular (congestión ocular)
                </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="congestion_ocular"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="congestion_ocular"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="congestion_ocular" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Dolor muscular</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="dolor_muscular"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="dolor_muscular"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="dolor_muscular" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Irritabilidad / Confusión</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="irritabilidad"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="irritabilidad"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="irritabilidad" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Escalofríos</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="escalofrios"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="escalofrios"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="escalofrios" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Expectoración</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="expectoracion"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="expectoracion"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="expectoracion" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Fotofobia (molestia visual a la luz)</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="fotofobia"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="fotofobia"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="fotofobia" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Diarrea</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="diarrea"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="diarrea"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="diarrea" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Náuseas (deseo de vomitar)</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="nauseas"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="nauseas"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="nauseas" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>
                  Vómito (expulsar por la boca lo ingerido)
                </Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="vomito"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="vomito"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="vomito" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Otalgia (dolor de oído)</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="otalgia"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="otalgia"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="otalgia" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Dolor articular</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="dolor_articular"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="dolor_articular"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="dolor_articular" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>Otras molestias o síntomas</Form.Label>
                <Row>
                  <Col>
                    <Field
                      inline
                      as={Form.Check}
                      name="otros_sintomas"
                      type="radio"
                      value="si"
                      label="Si"
                    />

                    <Field
                      inline
                      as={Form.Check}
                      name="otros_sintomas"
                      type="radio"
                      value="no"
                      label="No"
                    />
                  </Col>
                </Row>

                <ErrorMessage name="otros_sintomas" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Form.Label>(Especificar)</Form.Label>
                <Field
                  disabled={
                    formik.values.otros_sintomas === "si" ? false : true
                  }
                  as={Form.Control}
                  name="otros_sintomas_especificar"
                  type="input"
                />
                <ErrorMessage name="otros_sintomas_especificar" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <h5
                  style={{ fontFamily: "Google Sans" }}
                  className="pt-1 fw-bold"
                >
                  <p>
                    Declaro sujetarme a lo que disponga Pluspetrol, respecto a
                    la posibilidad o no de viajar, atendiendo a mi estado de
                    salud y lo declarado en el presente documento.
                  </p>
                  <p>
                    Declaro que en caso de tener positivo alguno de los síntomas
                    durante mi permanencia en las instalaciones, acudiré
                    inmediatamente a la Unidad Médica para la atención
                    respectiva.
                  </p>
                </h5>
              </Tarjeta>
              <Tarjeta>
                <h4
                  style={{ fontFamily: "Google Sans" }}
                  className="pt-1 fw-bold"
                >
                  Declaración realizada en (especificar):
                </h4>
              </Tarjeta>
              <Tarjeta>
                Hotel:
                <Field
                  disabled={
                    formik.values.aeropuerto === "" &&
                    formik.values.otros === ""
                      ? false
                      : true
                  }
                  as={Form.Control}
                  name="hotel"
                  type="input"
                />
                <ErrorMessage name="hotel" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                Aeropuerto:
                <Field
                  disabled={
                    formik.values.hotel === "" && formik.values.otros === ""
                      ? false
                      : true
                  }
                  as={Form.Control}
                  name="aeropuerto"
                  type="input"
                />
                <ErrorMessage name="aeropuerto" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                Otros:
                <Field
                  disabled={
                    formik.values.aeropuerto === "" &&
                    formik.values.hotel === ""
                      ? false
                      : true
                  }
                  as={Form.Control}
                  name="otros"
                  type="input"
                />
                <ErrorMessage name="otros" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Tarjeta>
                <Field name="firma">
                  {({ field }) => (
                    <Firm
                      value={field.value}
                      firmChange={(value) =>
                        formik.setFieldValue(field.name, value)
                      }
                    >
                      Firma
                    </Firm>
                  )}
                </Field>
                <ErrorMessage name="firma" as={Form.Label}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </Tarjeta>
              <Row>
                <Col>
                  <Button
                    style={{ width: "100%" }}
                    block
                    variant="success"
                    type="submit"
                  >
                    Grabar
                  </Button>
                </Col>
              </Row>
            </FormMK>
          )}
        </Formik>
      </Container>
    </>
  );
};
export default SoliPetro;
