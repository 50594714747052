import React, { useEffect, useState } from "react";
import {
  Formik,
  Form as FormMK,
  FieldArray,
  ErrorMessage,
  Field,
} from "formik";
import * as Yup from "yup";
import { Container, Button, Form } from "react-bootstrap";
import Tarjeta from "../components/Tarjeta";
import ErrorM from "../components/ErrorMessage";
import { TextField } from "../components/FrontComponents/Inputs/textinput";
import { DeleteButton } from "../components/FrontComponents/ActionButtons";
import { getWorkersData } from "../services/databaseData";
import { Subtitle } from "../components/FrontComponents/TextType";
import { useCurrentPosition } from "../hooks/useCurrentPosition";
import { uploadAnswer, sendNotification } from "../services/uploadToDatabase";
import Toast from "../components/toast";
import { Redirect } from "react-router-dom";
import { getUserData } from "../services/userData";
import { ActionButton } from "../components/FrontComponents/ActionButtons/actionbutton";

const initialData = {
  empresa: "",
  area: "",
  ubicacion: "",
  tarea: "",
  secuencias: [
    {
      nombre: "",
      fuego: "",
      descripcion: "",
      riesgo: "",
      eva_iper: "",
      controles: "",
      eva_riesgo: "",
    },
  ],
  controlsecuencias: [
    {
      nombre: "",
    },
  ],
  trabajadores: [
    {
      id: "",
    },
  ],
  supervisores: [
    {
      id: "",
    },
  ],
};
const validate = Yup.object({
  empresa: Yup.string().required("Este campo es necesario."),
  area: Yup.string().required("Este campo es necesario."),
  ubicacion: Yup.string().required("Este campo es necesario."),
  tarea: Yup.string().required("Este campo es necesario."),
  secuencias: Yup.array().of(
    Yup.object().shape({
      nombre: Yup.string().required("Este campo es necesario"),
      fuego: Yup.string().required("Este campo es necesario"),
      descripcion: Yup.string().required("Este campo es necesario"),
      riesgo: Yup.string().required("Este campo es necesario"),
      eva_iper: Yup.string().required("Este campo es necesario"),
      controles: Yup.string().required("Este campo es necesario"),
      eva_riesgo: Yup.string().required("Este campo es necesario"),
    })
  ),
  controlsecuencias: Yup.array().of(
    Yup.object().shape({
      nombre: Yup.string().required("Este campo es necesario"),
    })
  ),
  trabajadores: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required("Este campo es necesario"),
    })
  ),
  supervisores: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required("Este campo es necesario"),
    })
  ),
});
const IpercFormat = () => {
  const [workers, setWorkers] = useState({});
  const [latitud, longitud] = useCurrentPosition(0);
  const [upload, setUpload] = useState(false);
  const handleSubmit = async (values) => {
    const userData = getUserData();
    values.latitud = latitud;
    values.longitud = longitud;
    values.formKey = "Iperc";
    values.createDate = Date.now();
    values.userId = userData.uid;
    const data = await uploadAnswer(values);
    if (data.error === "") {
      await sendNotification(values.supervisores, data.key, {
        medida_correctiva: "",
      });
      await sendNotification(values.trabajadores, data.key);
      Toast("Información subida Correctamente", "success");
      setUpload(true);
    } else {
      console.log(data.error);
    }
  };
  useEffect(() => {
    const getDataUser = async () => {
      const workersData = await getWorkersData();
      setWorkers(workersData);
    };
    getDataUser();
  }, []);
  return (
    <Container style={{ backgroundColor: "#a3dfff" }} className="p-3">
      {upload ? <Redirect to="/subido" /> : null}
      <Formik
        initialValues={initialData}
        validationSchema={validate}
        onSubmit={handleSubmit}
      >
        {({ getFieldProps, values, errors }) => (
          <FormMK>
            <Tarjeta>
              <h2 className="pt-1 fw-bold">IPERC Continuo</h2>
            </Tarjeta>
            <Tarjeta>
              <TextField title="Nombre de la empresa:" name="empresa" />
            </Tarjeta>
            <Tarjeta>
              <TextField title="Area" name="area" />
            </Tarjeta>
            <Tarjeta>
              <TextField title="Ubicación exacta(Lugar):" name="ubicacion" />
            </Tarjeta>
            <Tarjeta>
              <TextField title="Nombre de la tarea:" name="tarea" />
            </Tarjeta>

            <FieldArray name="secuencias">
              {({ push, remove }) => (
                <>
                  {values.secuencias.map((secuencia, index) => {
                    return (
                      <Tarjeta key={index}>
                        <TextField
                          title={`Paso ${index + 1}`}
                          name={`secuencias[${index}].nombre`}
                        />
                        <Subtitle>
                          ¿Paso de la tarea involucra exponer las mano(s) a la
                          línea de fuego?:
                        </Subtitle>
                        <br />
                        <Field
                          inline
                          as={Form.Check}
                          name={`secuencias[${index}].fuego`}
                          type="radio"
                          value="SI"
                          label="SI"
                        />
                        <Field
                          inline
                          as={Form.Check}
                          name={`secuencias[${index}].fuego`}
                          type="radio"
                          value="NO"
                          label="NO"
                        />

                        <ErrorMessage
                          name={`secuencias[${index}].fuego`}
                          as={Subtitle}
                        >
                          {(message) => <ErrorM>{message}</ErrorM>}
                        </ErrorMessage>
                        <br />
                        <TextField
                          title={`Descripción del peligro`}
                          name={`secuencias[${index}].descripcion`}
                        />
                        <TextField
                          title={`Riesgo`}
                          name={`secuencias[${index}].riesgo`}
                        />
                        <Subtitle>Evaluación IPER:</Subtitle>
                        <br />
                        <Field
                          inline
                          as={Form.Check}
                          name={`secuencias[${index}].eva_iper`}
                          type="radio"
                          value="A"
                          label="A"
                        />
                        <Field
                          inline
                          as={Form.Check}
                          name={`secuencias[${index}].eva_iper`}
                          type="radio"
                          value="M"
                          label="M"
                        />
                        <Field
                          inline
                          as={Form.Check}
                          name={`secuencias[${index}].eva_iper`}
                          type="radio"
                          value="B"
                          label="B"
                        />
                        <ErrorMessage
                          name={`secuencias[${index}].eva_iper`}
                          as={Subtitle}
                        >
                          {(message) => <ErrorM>{message}</ErrorM>}
                        </ErrorMessage>
                        <br />
                        <TextField
                          title={`Controles criticos identificados e implementados`}
                          name={`secuencias[${index}].controles`}
                        />
                        <Subtitle>Evaluación Riesgo Residual:</Subtitle>
                        <br />
                        <Field
                          inline
                          as={Form.Check}
                          name={`secuencias[${index}].eva_riesgo`}
                          type="radio"
                          value="A"
                          label="A"
                        />
                        <Field
                          inline
                          as={Form.Check}
                          name={`secuencias[${index}].eva_riesgo`}
                          type="radio"
                          value="M"
                          label="M"
                        />
                        <Field
                          inline
                          as={Form.Check}
                          name={`secuencias[${index}].eva_riesgo`}
                          type="radio"
                          value="B"
                          label="B"
                        />
                        <br/>
                        <ErrorMessage
                          name={`secuencias[${index}].eva_riesgo`}
                          as={Subtitle}
                        >
                          {(message) => <ErrorM>{message}</ErrorM>}
                        </ErrorMessage>
                        {index > 0 && (
                          <DeleteButton onClick={() => remove(index)} />
                        )}
                        {index === values.secuencias.length - 1 && (
                          <ActionButton
                            type="add"
                            float="right"
                            onClick={() =>
                              push({
                                nombre: "",
                                fuego: "",
                                descripcion: "",
                                riesgo: "",
                                eva_iper: "",
                                controles: "",
                                eva_riesgo: "",
                              })
                            }
                          />
                        )}
                      </Tarjeta>
                    );
                  })}
                </>
              )}
            </FieldArray>
            <FieldArray name="controlsecuencias">
              {({ push, remove }) => (
                <>
                  <Tarjeta>
                    <Subtitle>
                      Secuencia para controlar el peligro y reducir el riesgo
                    </Subtitle>
                  </Tarjeta>
                  {values.controlsecuencias.map((controlsecuencia, index) => {
                    return (
                      <Tarjeta key={index}>
                        <TextField
                          title={`Paso ${index + 1}`}
                          name={`controlsecuencias[${index}].nombre`}
                        />
                        <br />
                        {index > 0 && (
                          <DeleteButton onClick={() => remove(index)} />
                        )}
                        {index === values.controlsecuencias.length - 1 && (
                          <ActionButton
                            type="add"
                            float="right"
                            onClick={() =>
                              push({
                                nombre: "",
                              })
                            }
                          />
                        )}
                      </Tarjeta>
                    );
                  })}
                </>
              )}
            </FieldArray>
            <FieldArray name="trabajadores">
              {({ push, remove }) => (
                <>
                  <Tarjeta>
                    <Subtitle>Datos de los trabajadores</Subtitle>
                  </Tarjeta>
                  {values.trabajadores.map((trabajador, index) => {
                    return (
                      <Tarjeta key={index}>
                        <Form.Label>{`Trabajador ${index + 1}`}</Form.Label>
                        <Field
                          name={`trabajadores[${index}].id`}
                          type="text"
                          as="select"
                          className="form-select"
                        >
                          <option value="">Seleccionar</option>
                          {Object.entries(workers)
                            .filter(([id, worker]) => {
                              return (
                                worker.area !== "Supervisor" &&
                                worker.area !== "Administrador"
                              );
                            })
                            .map(([id, worker]) => {
                              return (
                                <option key={id} value={id}>
                                  {worker.name}
                                </option>
                              );
                            })}
                        </Field>
                        <br />
                        <ErrorMessage
                          name={`trabajadores[${index}].id`}
                          as={Form.Label}
                        >
                          {(message) => <ErrorM>{message}</ErrorM>}
                        </ErrorMessage>
                        {index !== 0 && (
                          <DeleteButton onClick={() => remove(index)} />
                        )}
                        {index === values.trabajadores.length - 1 && (
                          <ActionButton
                            type="add"
                            float="right"
                            onClick={() =>
                              push({
                                id: "",
                              })
                            }
                          />
                        )}
                      </Tarjeta>
                    );
                  })}
                </>
              )}
            </FieldArray>
            <FieldArray name="supervisores">
              {({ push, remove }) => (
                <>
                  <Tarjeta>
                    <Subtitle>Datos de los Supervisores</Subtitle>
                  </Tarjeta>
                  {values.supervisores.map((supervisor, index) => {
                    return (
                      <Tarjeta key={index}>
                        <Form.Label>Supervisor</Form.Label>
                        <Field
                          name={`supervisores[${index}].id`}
                          type="text"
                          as="select"
                          className="form-select"
                        >
                          {console.log(workers)}
                          <option value="">Seleccionar</option>
                          {Object.entries(workers)
                            .filter(([id, worker]) => {
                              return worker.area === "Supervisor";
                            })
                            .map(([id, worker]) => {
                              return (
                                <option key={id} value={id}>
                                  {worker.name}
                                </option>
                              );
                            })}
                        </Field>
                        <br />
                        <ErrorMessage
                          name={`supervisores[${index}].id`}
                          as={Form.Label}
                        >
                          {(message) => <ErrorM>{message}</ErrorM>}
                        </ErrorMessage>
                        {index !== 0 && (
                          <DeleteButton onClick={() => remove(index)} />
                        )}
                        {index === values.supervisores.length - 1 && (
                          <ActionButton
                            type="add"
                            float="right"
                            onClick={() =>
                              push({
                                id: "",
                              })
                            }
                          />
                        )}
                      </Tarjeta>
                    );
                  })}
                </>
              )}
            </FieldArray>
            <Button type="submit">Subir</Button>
          </FormMK>
        )}
      </Formik>
    </Container>
  );
};

export default IpercFormat;
