import React from "react";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div>
      <h1>Not Found</h1>
      <h3>No se encontro la pagina especificada</h3>
      <Link to="/">Return to home</Link>
    </div>
  );
};

export default LandingPage;