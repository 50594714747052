import React, { useState, useEffect } from "react";
import "./FormPage/formPage.css";
import Navbar from "../components/Navbar/NavbarVF";
import NavbarSupervisor from "../components/Navbar/NavBarSupervisor";
import NavbarTrabajador from "../components/Navbar/NavBarTrabajador";
import { Button, Spinner } from "react-bootstrap";
import * as BsIcons from "react-icons/bs";
import { firebase } from "../firebase-configs";
import pdf from "../services/exportPdf";
import Toast from "../components/toast";
import { ReactComponent as IconExcel } from "../icons/file-excel-solid.svg";
import { getUserData } from "../services/userData";
import { downloadExcel } from "../services/excelDownload";
import { Content } from "../components/FrontComponents/Structure";
import {
  TableWrapper,
  Table,
  TableData,
  TableH,
} from "../components/FrontComponents/Table";
import { Title } from "../components/FrontComponents/TextType";
import { Wrapper } from "../components/FrontComponents/wrapper";
import { InputWrapper } from "../components/FrontComponents/inputwrapper";
import { DatePicker } from "../components/FrontComponents/Inputs/datepicker";
import { ActionButton } from "../components/FrontComponents/ActionButtons/actionbutton";
import { downloadVehiculosLigerosPdf } from "../services/pdfManualExport/vehiculosLigerosExportPdf";
import Mubication from "../components/Modales/Mubication";
import { downloadVehiculosPesadosPdf } from "../services/pdfManualExport/vehiculosPesadosFormatPdf";
import { downloadIpercPdf } from "../services/pdfManualExport/ipercExportPdf";
import { getWorkerData, getFormData } from "../services/databaseData";
import { removeNotification, removeAnswer } from "../services/uploadToDatabase"

const firebaseDB = firebase.database();

let indexer = {};

const RespuestasPage = () => {
  const [toFetch, setToFetch] = useState([]);
  const [data, setData] = useState([]);
  const [currentData, setCurretData] = useState({});
  const [searchData, setSearchData] = useState();
  const [show, setShow] = useState(false);
  const types = {};
  const respuestas = {};
  const orderdata = {};
  Object.entries(data)
    .filter(([key, value]) => {
      let date = new Date(value.createDate);
      return date.toLocaleString().split(" ")[0] === searchData;
    })
    .map((data) => {
      Object.assign(types, {
        [data[1].formKey]: {},
      });
      Object.assign(respuestas, { [data[0]]: data[1] });
    });
  for (let resp in respuestas) {
    for (let type in types) {
      if (respuestas[resp].formKey === type) {
        types[type] = { ...types[type], [resp]: respuestas[resp] };
      }
    }
  }
  for (let answer in data) {
    orderdata[data[answer].formKey] = {
      ...orderdata[data[answer].formKey],
      [answer]: data[answer],
    };
  }
  const handleClick = (data) => {
    if (data.formKey === "PlusPetrol") {
      downloadVehiculosPesadosPdf(data);
    } else if (data.formKey === "VehiculosPesados") {
      downloadVehiculosPesadosPdf(data);
    } else if (data.formKey === "VehiculosLigeros") {
      downloadVehiculosLigerosPdf(data);
    } else if (data.formKey === "Iperc") {
      downloadIpercPdf(data);
    } else {
      pdf.dowloadPdf(data);
    }
  };
  const handleDelete = async(id, data) => {
    const remAnswResponse = await removeAnswer(id)
    if (remAnswResponse.error === ""){
      if(data.supervisores !== undefined){
        for (let wrk of data.supervisores){
          const wrkId = wrk.id || wrk.nombre
          await removeNotification(wrkId, id)
        }
      }
      if(data.trabajadores !== undefined){
        for (let wrk of data.trabajadores){
          await removeNotification(wrk.id, id)
        }
      }
      Toast( "Se elimino correctamente.", "success");
      initialize()
    } else {
      Toast("Oops, ocurrio un error.")
    }
  };
  const handleChange = (event) => {
    const objDate = new Date(event.target.value.replace(/-/g, "/"));
    setSearchData(objDate.toLocaleDateString());
  };

  const handleSearch = () => {
    fetchSearchData();
  };

  const handleDownloadExcel = async () => {
    // Logica de agrupacion por formulario
    searchData ? await downloadExcel(types) : await downloadExcel(orderdata);
  };

  const fetchSearchData = async() => {
    if (searchData !== undefined) {
      if (indexer[searchData] !== undefined) {
        const fetch = await Promise.all(
          Object.keys(indexer[searchData]).map(async(key) => {
            const date = new Date(data[indexer[searchData][key]].createDate);
            let workerData = await getWorkerData(data[indexer[searchData][key]].userId)
            if (workerData === null || workerData.name === null || workerData.name === undefined){
              workerData = {name: "Not found"}
            }
            const formData = await getFormData(data[indexer[searchData][key]].formKey)
            return (
              <tr key={key}>
                <TableData>{workerData.name}</TableData>
              <TableData>{formData.nombre}</TableData>
                <TableData>
                  {date.toLocaleDateString() + " - " + date.toLocaleTimeString()}
                </TableData>
                <TableData>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://google.com/maps/place/${
                      data[indexer[searchData][key]].latitud
                    },${data[indexer[searchData][key]].longitud}`}
                  >
                    <BsIcons.BsEyeFill
                      style={{ fontSize: "25px", color: "#1D3461" }}
                    />
                  </a>
                </TableData>
                <TableData>
                  <ActionButton
                    type="download"
                    onClick={() => handleClick(data[indexer[searchData][key]])}
                  />
                  {getUserData().area === "Administrador" ? (
                    <ActionButton
                      type="remove"
                      onClick={() => handleDelete(key, data[key])}
                    />
                  ) : (
                    null
                  )}
                </TableData>
              </tr>
            );
          })
        )
        setToFetch(fetch);
      } else {
        Toast(
          "Se ingreso una fecha en la cual no habia ninguna respuesta",
          "info"
        );
      }
    } else {
      Toast("No se ingreso ninguna fecha", "error");
    }
  };

  const fetchData = async(data) => {
    setSearchData(undefined);
    const fetch = await Promise.all(
      Object.keys(data).map(async(key, index) => {
        const date = new Date(data[key].createDate);
        let workerData = await getWorkerData(data[key].userId)
        if (workerData === null || workerData.name === null || workerData.name === undefined){
          workerData = {name: "Not found"}
        }
        const formData = await getFormData(data[key].formKey)
        indexer[date.toLocaleDateString()] = {
          ...indexer[date.toLocaleDateString()],
          [index]: key,
        };
        return (
          <tr key={index}>
            <TableData>{workerData.name}</TableData>
            <TableData>{formData.nombre}</TableData>
            <TableData>
              {date.toLocaleDateString() + " - " + date.toLocaleTimeString()}
            </TableData>
            <TableData>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://google.com/maps/place/${data[key].latitud},${data[key].longitud}`}
              >
                <BsIcons.BsEyeFill
                  style={{ fontSize: "25px", color: "#1D3461" }}
                />
              </a>
            </TableData>
            <TableData>
              <ActionButton
                type="download"
                onClick={() => handleClick(data[key])}
              />
              {getUserData().area === "Administrador" ? (
                <ActionButton
                  type="remove"
                  onClick={() => handleDelete(key, data[key])}
                />
              ) : (
                null
              )}
            </TableData>
          </tr>
        );
      })
    )
    setToFetch(fetch);
  };

  const initialize = () => {
    const answersRef = firebaseDB.ref("Respuestas");
    answersRef.once("value").then((snapshot) => {
      if (snapshot.val() !== null) {
        if (getUserData().email === "pluspetrol@formatosweb.com") {
          const dataPlusPetrol = {};
          let contIndex = 0;
          Object.keys(snapshot.val()).map((index) => {
            if (snapshot.val()[index].formKey === "PlusPetrol") {
              Object.assign(dataPlusPetrol, {
                [contIndex]: snapshot.val()[index],
              });
              contIndex += 1;
            }
          });
          setData(dataPlusPetrol);
          fetchData(dataPlusPetrol);
        } else {
          setData(snapshot.val());
          fetchData(snapshot.val());
          answersRef.off();
        }
      }
    });
  }

  useEffect(() => {
    initialize()
  }, []);

  return (
    <div>
      {getUserData().area === "Administrador" ? (
        <Navbar />
      ) : getUserData().area === "Supervisor" ? (
        <NavbarSupervisor />
      ) : (
        <NavbarTrabajador />
      )}
      <Content>
        <Title>Lista de Respuestas</Title>
        <Wrapper>
          <InputWrapper>
            <DatePicker onChange={handleChange} />
          </InputWrapper>
          <Button
            size="lg"
            className="ms-2"
            variant="success"
            onClick={handleSearch}
          >
            <BsIcons.BsSearch />
          </Button>
          <Button
            size="lg"
            className="ms-2"
            variant="primary"
            onClick={() => fetchData(data)}
          >
            Reset
          </Button>
          <Button
            size="lg"
            className="ms-2"
            variant="success"
            onClick={handleDownloadExcel}
          >
            <IconExcel className="me-1 mb-1" style={{ width: "1rem" }} />
            Exportar en excel
          </Button>
        </Wrapper>

        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <TableH>Nombre</TableH>
                <TableH>Nombre del formato</TableH>
                <TableH>Fecha de resolucion</TableH>
                <TableH>Ubicación</TableH>
                <TableH>Acción</TableH>
              </tr>
            </thead>
            <tbody>{toFetch}</tbody>
          </Table>
        </TableWrapper>

        {show && (
          <Mubication
            coordinates={currentData}
            show={show}
            onHide={() => setShow(false)}
          />
        )}
      </Content>
    </div>
  );
};
export default RespuestasPage;
