import React from "react";
import styled from "styled-components";
import { ColorPalettes } from "../Colors";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as FiIcons from "react-icons/fi";

const fontsize = "25px";
export const EditButton = styled(FaIcons.FaEdit)`
  cursor: pointer;
  margin-left: 10px;
  font-size: ${fontsize};
  color: ${ColorPalettes.verdeclaro};
`;
export const RemoveButton = styled(MdIcons.MdDelete)`
  cursor: pointer;
  margin-left: 10px;
  font-size: ${fontsize};
  color: ${ColorPalettes.verdeclaro};
`;
export const ViewButton = styled(BsIcons.BsEyeFill)`
  cursor: pointer;
  margin-left: 10px;
  font-size: ${fontsize};
  color: ${ColorPalettes.verdeclaro};
`;
export const CopyButton = styled(FiIcons.FiPaperclip)`
  cursor: pointer;
  margin-left: 10px;
  font-size: ${fontsize};
  color: ${ColorPalettes.verdeclaro};
`;
export const RejectButton = styled(FaIcons.FaTimes)`
  cursor: pointer;
  margin-left: 10px;
  font-size: ${fontsize};
  color: ${ColorPalettes.verdeclaro};
  &:hover {
    color: #355fb8;
  }
`;
export const AcceptButton = styled(FaIcons.FaCheck)`
  cursor: pointer;
  margin-left: 10px;
  font-size: ${fontsize};
  color: ${ColorPalettes.verdeclaro};
  &:hover {
    color: #355fb8;
  }
`;
export const DownloadButton = styled(FaIcons.FaDownload)`
  cursor: pointer;
  margin-left: 10px;
  font-size: ${fontsize};
  color: ${ColorPalettes.verdeclaro};
  &:hover {
    color: #355fb8;
  }
`;
export const AddButton = styled(FaIcons.FaPlus)`
  float: ${(props) => (props.float === "right" ? "right" : "none")};
  cursor: pointer;
  margin-left: 10px;
  font-size: ${fontsize};
  color: ${ColorPalettes.verdeclaro};
  &:hover {
    color: #355fb8;
  }
`;
export const ActionButton = ({ onClick, type, float }) => {
  switch (type) {
    case "edit":
      return <EditButton onClick={onClick} />;
    case "remove":
      return <RemoveButton onClick={onClick} />;
    case "view":
      return <ViewButton onClick={onClick} />;
    case "copy":
      return <CopyButton onClick={onClick} />;
    case "download":
      return <DownloadButton onClick={onClick} />;
    case "reject":
      return <RejectButton onClick={onClick} />;
    case "accept":
      return <AcceptButton onClick={onClick} />;
    case "add":
      return <AddButton float={float} onClick={onClick} />;
    default:
      return <EditButton onClick={onClick} />;
  }
};
