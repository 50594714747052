import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Route, Redirect } from "react-router-dom";
import { getUserData, isUserAuth } from "../services/userData";
const AuthRoute = ({ component: Component, ...rest }) => {
  const [isLogin, setIsLogin] = useState(
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Spinner animation="border" size="lg" />
    </div>
  );

  const getUser = async (isAuth) => {
    if (isAuth) {
      if (rest.area !== undefined) {
        let correctArea = false;
        for (var i = 0; i < rest.area.length; i++) {
          if (rest.area[i] === getUserData().area) {
            correctArea = true;
            break;
          }
        }
        if (correctArea) {
          setIsLogin(
            <>
              <Component />
            </>
          );
        } else {
          setIsLogin(<Redirect to="/" />);
        }
      } else {
        setIsLogin(<Component />);
      }
    } else {
      setIsLogin(<Redirect to="/" />);
    }
  };

  useEffect(() => {
    const init = async() => {
      const isAuth = await isUserAuth()
      getUser(isAuth);
    }
    init()
  }, []);

  if (!rest.guest) {
    return (
      <>
        <Route
          {...rest}
          render={() => {
            return isLogin;
          }}
        />
      </>
    );
  }
  return (
    <Route
      {...rest}
      render={() => {
        return (
          <>
            <Component />
          </>
        );
      }}
    />
  );
};

export default AuthRoute;
