import { jsPDF } from "jspdf";
import vehiculosPesadosIcon from "../../icons/vehiculosPesadosIcon.js"
import { resetTable, cell, selectValue, setSelectValueOptionWidth} from "../pdfFunctions.js";
import vehiculosPesados from "../../manualFormsDataStructure/vehiculospesadosNames.json"
import { getWorkerData } from "../databaseData.js";

const initUserData = {
  name: "",
  email: "",
  area: "",
  firma: vehiculosPesadosIcon,
  logo: vehiculosPesadosIcon,
  empresa: "",
}

export const downloadVehiculosPesadosPdf = async(values) => {
  resetTable()
  let userData = await getWorkerData(values.userId)
  const doc = new jsPDF();
  const heightCell = 5;
  const colorCells = "#cccccc"
  const selectValItems = ["CUMPLE", "NO CUMPLE", "NO APLICA"]

  //Head
  if(userData.logo !== undefined){
    doc.addImage(userData.logo, "PNG",  1, 5, 33.5, 5)
  } else {
    doc.addImage(vehiculosPesadosIcon, "PNG", 1, 5, 33.5, 5)
    userData = initUserData
  }

  doc.setFontSize(10);
  cell(doc, 0, 0, 36, 15, "", 1)
  cell(doc, 0, 0, 104, 15, "LISTA DE VERIFICACIÓN DE VEHÍCULOS PESADOS", 1, {align: "center"})

  resetTable()
  doc.setFontSize(7);

  cell(doc, 140, 0, 30, heightCell, "Codigo:", 1, {align: "center"})
  cell(doc, 0, 0, 40, heightCell, "R.BMB.PRY.SST.EST.01.02", 1, {align: "center"})
  cell(doc, 140, 0, 30, heightCell, "Version:", 2, {align: "center"})
  cell(doc, 0, 0, 40, heightCell, "01", 2, {align: "center"})
  cell(doc, 140, 0, 30, heightCell, "Fecha de aprobacion:", 3, {align: "center"})
  cell(doc, 0, 0, 40, heightCell, "25-08-2021", 3, {align: "center"})

  resetTable()
  cell(doc, 0, 15, 36, heightCell, vehiculosPesados.empresa, 1)
  cell(doc, 0, 0, 40, heightCell, userData.empresa, 1)
  cell(doc, 0, 0, 32, heightCell, vehiculosPesados.tipo_vehiculo, 1)
  cell(doc, 0, 0, 32, heightCell, values.tipo_vehiculo, 1)
  cell(doc, 0, 0, 30, heightCell, vehiculosPesados.placa_tracto, 1)
  cell(doc, 0, 0, 40, heightCell, values.placa_tracto, 1)
  const date = new Date(values.createDate);
  cell(doc, 0, 0, 36, heightCell, vehiculosPesados.lugar, 2)
  cell(doc, 0, 0, 40, heightCell, values.latitud + ", " + values.longitud, 2)
  cell(doc, 0, 0, 13, heightCell, "FECHA", 2)
  cell(doc, 0, 0, 19, heightCell, date.toLocaleDateString(), 2)
  cell(doc, 0, 0, 13, heightCell, "HORA", 2)
  cell(doc, 0, 0, 19, heightCell, date.toLocaleTimeString(), 2)
  cell(doc, 0, 0, 30, heightCell, vehiculosPesados.placa_acoplado, 2)
  cell(doc, 0, 0, 40, heightCell, values.placa_acoplado, 2)

  cell(doc, 0, 15, 36, heightCell, vehiculosPesados.nombre_conductor, 3)
  cell(doc, 0, 0, 40, heightCell, userData.name, 3)
  cell(doc, 0, 0, 32, heightCell, vehiculosPesados.dni, 3)
  cell(doc, 0, 0, 32, heightCell, values["Documento de identidad"].number, 3)
  cell(doc, 0, 0, 30, heightCell, vehiculosPesados.placa_otros, 3)
  cell(doc, 0, 0, 40, heightCell, values.placa_otros, 3)

  //Body

  resetTable()

  setSelectValueOptionWidth(20)

  cell(doc, 0, 30, 150, heightCell, "VERIFICACIÓN DE DOCUMENTOS DEL VEHICULO", 1, {color: colorCells})
  cell(doc, 0, 30, 20, heightCell, "CUMPLE", 1, {align: "center", color: colorCells})
  cell(doc, 0, 30, 20, heightCell, "NO CUMPLE", 1, {align: "center", color: colorCells})
  cell(doc, 0, 30, 20, heightCell, "NO APLICA", 1, {align: "center", color: colorCells})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.const_vehicularTr, 2, {items: selectValItems, value: values.const_vehicularTr})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.const_vehicularSe, 3, {items: selectValItems, value: values.const_vehicularSe})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.revi_trac, 4, {items: selectValItems, value: values.revi_trac})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.revi_semi, 5, {items: selectValItems, value: values.revi_semi})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.segu_oblig, 6, {items: selectValItems, value: values.segu_oblig})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.poliza_trac, 7, {items: selectValItems, value: values.poliza_trac})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.poliza_semi, 8, {items: selectValItems, value: values.poliza_semi})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.tarjeta_trac, 9, {items: selectValItems, value: values.tarjeta_trac})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.tarjeta_remol, 10, {items: selectValItems, value: values.tarjeta_remol})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.plan_contin, 11, {items: selectValItems, value: values.plan_contin})
  cell(doc, 0, 30, 150, heightCell, "VERIFICACIÓN DE DOCUMENTOS Y EQUIPOS DEL CONDUCTOR", 12, {color: colorCells})
  cell(doc, 0, 30, 20, heightCell, "CUMPLE", 12, {align: "center", color: colorCells})
  cell(doc, 0, 30, 20, heightCell, "NO CUMPLE", 12, {align: "center", color: colorCells})
  cell(doc, 0, 30, 20, heightCell, "NO APLICA", 12, {align: "center", color: colorCells})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.licencia_vi, 13, {items: selectValItems, value: values.licencia_vi})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.sctr, 14, {items: selectValItems, value: values.sctr})
  selectValue(doc, 0, 0, 210, heightCell, vehiculosPesados.equipo_perso, 15, {items: selectValItems, value: values.equipo_perso})
  cell(doc, 0, 30, 70, heightCell, "INSPECCIÓN DEL TRACTO", 16, {color: colorCells})
  cell(doc, 0, 30, 80, heightCell, "EVALUAR", 16, {color: colorCells, align: "center"})
  cell(doc, 0, 30, 20, heightCell, "CUMPLE", 16, {align: "center", color: colorCells})
  cell(doc, 0, 30, 20, heightCell, "NO CUMPLE", 16, {align: "center", color: colorCells})
  cell(doc, 0, 30, 20, heightCell, "NO APLICA", 16, {align: "center", color: colorCells})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.encen_eva, 17)
  selectValue(doc, 0, 0, 140, heightCell, "Encendido y Apagado Adecuado", 17, {items: selectValItems, value: values.encen_eva})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.baterias, 18)
  selectValue(doc, 0, 0, 140, heightCell, "Cubiertas y Bornes protegidos con elemento aislante. Caja de la batería", 18, {items: selectValItems, value: values.baterias})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.tanques, 19)
  selectValue(doc, 0, 0, 140, heightCell, "Sin abolladuras, soldaduras.", 19, {items: selectValItems, value: values.tanques})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.bloqueador, 20)
  selectValue(doc, 0, 0, 140, heightCell, "Que permita desenergizar  el equipo, recomendable fuera de la cabina", 20, {items: selectValItems, value: values.bloqueador})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.luces, 21)
  selectValue(doc, 0, 0, 140, heightCell, "Altas bajas, direccionales, de freno y parqueo funcionando (verificar micas)", 21, {items: selectValItems, value: values.luces})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.alarmas, 22)
  selectValue(doc, 0, 0, 140, heightCell, "Que se active automáticamente al colocar la marcha en retroceso", 22, {items: selectValItems, value: values.alarmas})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.quinta_ru, 23)
  selectValue(doc, 0, 0, 140, heightCell, "king pin acoplado correctamente , engrasado en caso aplique. Placa del patín (estructura de fijación del pin que ingresa en la quinta rueda).", 23, {items: selectValItems, value: values.quinta_ru})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.espejos, 24)
  selectValue(doc, 0, 0, 140, heightCell, "Buen estado, mínimo uno a cada lado y con ángulo amplio de visión", 24, {items: selectValItems, value: values.espejos})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.parabrisas, 25)
  selectValue(doc, 0, 0, 140, heightCell, "Sin rajaduras, sin  quiños y en buen estado, que active la bomba de agua", 25, {items: selectValItems, value: values.parabrisas})
  resetTable()
  cell(doc, 0, 155, 70, 15, vehiculosPesados.estado_neumatico, 1)
  cell(doc, 0, 155, 80, 15, ["- Profundidad de cocada no menor a 6mm. Sin de desgaste irregular de banda de rodamiento.","- Máximo 2 reencauches en neumáticos del acoplado.","- En buen estado, sin cortes, grietas diagonales, aberturas.","- Aro y pestaña en buen estado."], 1)
  selectValue(doc, 0, 0, 140, 15, "", 1, {items: selectValItems, value: values.estado_neumatico})
  resetTable()
  cell(doc, 0, 170, 70, heightCell, vehiculosPesados.estado_general, 1)
  selectValue(doc, 0, 0, 140, heightCell, "Puertas y ventanas operativas, orden y limpieza en cabina de conductor, cinturones de seguridad operativos ).", 1, {items: selectValItems, value: values.estado_general})
  cell(doc, 0, 170, 70, heightCell, vehiculosPesados.compartimiento, 2)
  selectValue(doc, 0, 0, 140, heightCell, "Sin alteración en los compartimientos, sin evidencia de soldadura.", 2, {items: selectValItems, value: values.compartimiento})
  cell(doc, 0, 170, 70, heightCell, vehiculosPesados.tuercas, 3)
  selectValue(doc, 0, 0, 140, heightCell, "Marcadas  y en buen estado, recomendable trabatuercas en neumáticos direccionales.", 3, {items: selectValItems, value: values.tuercas})
  cell(doc, 0, 170, 70, heightCell, vehiculosPesados.conos, 4)
  selectValue(doc, 0, 0, 140, heightCell, 'Mínimo 4 con 1 banda de cinta reflectiva blanca de 2"', 4, {items: selectValItems, value: values.conos})
  cell(doc, 0, 170, 70, heightCell, vehiculosPesados.tacos, 5)
  selectValue(doc, 0, 0, 140, heightCell, "02 unidades en buen estado y dimensiones apropiadas para el vehículo", 5, {items: selectValItems, value: values.tacos})
  resetTable()
  cell(doc, 0, 195, 70, 10, vehiculosPesados.kit_herrami, 1)
  selectValue(doc, 0, 0, 140, 10, "Herramientas básicas para el cambio de neumáticos , llaves mixtas, desarmadores, alicate, linterna, palanca de trinca, cable para carga de bateria, cable de acero para remolque, medidor de presión del neumático) * En revisión", 1, {items: selectValItems, value: values.kit_herrami})
  resetTable()
  cell(doc, 0, 205, 70, 10, vehiculosPesados.kit_anderrame, 1)
  selectValue(doc, 0, 0, 140, 10, "Equipamiento mínimo (15 paños absorventes, 02 salchichas absorventes, 01 bandeja antiderrame, 05 sacos para material contaminado, pala, pico) * En revisión", 1, {items: selectValItems, value: values.kit_anderrame})
  resetTable()
  cell(doc, 0, 215, 70, 15, vehiculosPesados.botiquin, 1)
  selectValue(doc, 0, 0, 140, 15, 'De acuerdo al MTC (01 alcohol de 70° de 120 ml, 01 jabón antiséptico, 10 gasas esterilizadas fraccionadas de 10x10 cm, esparadrapo de 2.5 x 5m, venda elástica de 4x5 yardas,05 bandas adhesivas, 01 tijera punta roma de 3", 01 guante quirúrgico esterilizado de 7 1/2 (pares), algodón por 50 gramos.* En revisión', 1, {items: selectValItems, value: values.botiquin})
  resetTable()
  cell(doc, 0, 230, 70, heightCell, vehiculosPesados.radio, 1)
  selectValue(doc, 0, 0, 140, heightCell, "Operativo", 1, {items: selectValItems, value: values.radio})
  cell(doc, 0, 230, 70, heightCell, vehiculosPesados.bocina, 2)
  selectValue(doc, 0, 0, 140, heightCell, "Operativo", 2, {items: selectValItems, value: values.bocina})
  cell(doc, 0, 230, 70, heightCell, vehiculosPesados.circulina, 3)
  selectValue(doc, 0, 0, 140, heightCell, "Operativo", 3, {items: selectValItems, value: values.circulina})
  cell(doc, 0, 30, 70, heightCell, "INSPECCIÓN DEL ACOPLADO", 4, {color: colorCells})
  cell(doc, 0, 30, 80, heightCell, "EVALUAR", 4, {color: colorCells, align: "center"})
  cell(doc, 0, 30, 20, heightCell, "CUMPLE", 4, {align: "center", color: colorCells})
  cell(doc, 0, 30, 20, heightCell, "NO CUMPLE", 4, {align: "center", color: colorCells})
  cell(doc, 0, 30, 20, heightCell, "NO APLICA", 4, {align: "center", color: colorCells})
  cell(doc, 0, 230, 70, heightCell, vehiculosPesados.luces_lateral, 5)
  selectValue(doc, 0, 0, 140, heightCell, "Operativa y protegidas", 5, {items: selectValItems, value: values.luces_lateral})
  cell(doc, 0, 230, 70, heightCell, vehiculosPesados.extintores, 6)
  selectValue(doc, 0, 0, 140, heightCell, "Fecha de recarga, con precintos en buen estado y tarjetas de Inspección", 6, {items: selectValItems, value: values.extintores})
  cell(doc, 0, 230, 70, heightCell, vehiculosPesados.defensa_anti, 7)
  selectValue(doc, 0, 0, 140, heightCell, "Que sobresalga y proteja, con señalización reflectiva de acuerdo al MTC", 7, {items: selectValItems, value: values.defensa_anti})
  cell(doc, 0, 230, 70, heightCell, vehiculosPesados.sistema_sus, 8)
  selectValue(doc, 0, 0, 140, heightCell, "Paquete de muelles sin ruptura, montados en pin, abrazaderas en posición correcta  y ajustadas.", 8, {items: selectValItems, value: values.sistema_sus})
  resetTable()
  cell(doc, 0, 270, 70, 15, vehiculosPesados.estado_neuma, 1)
  cell(doc, 0, 0, 80, 15, [" - Profundidad de cocada no menor a 6mm. Sin de desgaste irregular de banda de rodamiento.","- Máximo 2 reencauches en neumáticos del acoplado.","- En buen estado, sin cortes, grietas diagonales, aberturas.","- Aro y pestaña en buen estado."], 1)
  selectValue(doc, 0, 0, 140, 15, "", 1, {items: selectValItems, value: values.estado_neuma})
  resetTable()
  cell(doc, 0, 285, 70, heightCell, vehiculosPesados.estado_chasis, 1)
  selectValue(doc, 0, 0, 140, heightCell, "En buen estado", 1, {items: selectValItems, value: values.estado_chasis})
  cell(doc, 0, 185, 70, heightCell, vehiculosPesados.pata_meca, 2)
  selectValue(doc, 0, 0, 140, heightCell, "Sin abolladuras, puntos de soldadura, tapas", 2, {items: selectValItems, value: values.pata_meca})
  resetTable()
  doc.addPage()
  cell(doc, 0, 5, 70, heightCell, vehiculosPesados.puertas_compar, 1)
  selectValue(doc, 0, 0, 140, heightCell, "Sin compartimientos adicionales al interior, sin puntos de soldadura, abolladuras, de fácil acceso.", 1, {items: selectValItems, value: values.puertas_compar})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.tuercas_espar, 2)
  selectValue(doc, 0, 0, 140, heightCell, "Marcadas y en buen estado", 2, {items: selectValItems, value: values.tuercas_espar})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.manparon, 3)
  selectValue(doc, 0, 0, 140, heightCell, "Verificar Estado", 3, {items: selectValItems, value: values.manparon})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.piso_acoplado, 4)
  selectValue(doc, 0, 0, 140, heightCell, "Sin compartimientos adicionales al interior, sin puntos de soldadura, abolladuras.", 4, {items: selectValItems, value: values.piso_acoplado})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.contenedor, 5)
  selectValue(doc, 0, 0, 140, heightCell, "Sin compartimientos adicionales al interior, sin puntos de soldadura, abolladuras.", 5, {items: selectValItems, value: values.contenedor})
  cell(doc, 0, 0, 70, heightCell, vehiculosPesados.elementos_aseg, 6)
  selectValue(doc, 0, 0, 140, heightCell, "En buen estado y cantidad suficiente para el tipo de carga", 6, {items: selectValItems, value: values.elementos_aseg})
  cell(doc, 0, 0, 210, heightCell, "INSPECCIONADO POR:", 7, {color: colorCells})
  let currentY = 40

  for (let value of values.supervisores) {
    const workerData = await getWorkerData(value.id)
    if(values[value.id] !== undefined){
      resetTable()
      cell(doc, 0, currentY, 45, heightCell, "NOMBRES Y APELLIDOS:", 1)
      cell(doc, 0, 0, 60, heightCell, workerData.name, 1)
      cell(doc, 0, 0, 45, heightCell, "CARGO:", 1)
      cell(doc, 0, 0, 60, heightCell, workerData.area, 1)
      resetTable()
      cell(doc, 0, currentY, 45, 20, "EMPRESA:", 1)
      cell(doc, 0, 0, 60, 20, workerData.empresa, 1)
      cell(doc, 0, 0, 45, 20, "FIRMA:", 1)
      cell(doc, 0, 0, 60, 20, "", 1)
      doc.addImage(workerData.firma, "PNG", 170, currentY + heightCell, 20, 15)
    } else {
      resetTable()
      cell(doc, 0, currentY, 45, heightCell, "NOMBRES Y APELLIDOS:", 1)
      cell(doc, 0, 0, 60, heightCell, workerData.name, 1)
      cell(doc, 0, 0, 45, heightCell, "CARGO:", 1)
      cell(doc, 0, 0, 60, heightCell, workerData.area, 1)
      resetTable()
      cell(doc, 0, currentY, 45, 20, "EMPRESA:", 1)
      cell(doc, 0, 0, 60, 20, workerData.empresa, 1)
      cell(doc, 0, 0, 45, 20, "FIRMA:", 1)
      cell(doc, 0, 0, 60, 20, "", 1)
    }
    currentY += heightCell + 20
  }

  window.open(doc.output("bloburl"), "_blank");

}
