import React, { useState } from "react";
import {
  SidebarDataAdmin,
  SidebarDataSupervisor,
  SidebarDataTrabajador,
} from "../SidebarData";
import { ColorPalettes } from "../FrontComponents/Colors";
import SubMenu from "./SubMenu";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
const NavSup = styled.nav`
  position: fixed;
  height: 70px;
  top: -80px;
  background: ${ColorPalettes.verdeclaro};
  z-index: 900;
  width: 100%;

  @media screen and (max-width: 1024px) {
    top: 0px;
  }
`;
const MobileIcon = styled.div`
  display: flex;
  position: relative;
  font-size: 1.5rem;
  padding: 0 1.3rem;
  color: #fff;
  height: 100%;
  align-items: center;
  @media screen and (max-width: 280px) {
  }
`;
const Bars = styled(FaBars)`
  cursor: pointer;
`;
const SidebarNav = styled.nav`
  position: fixed;
  background: ${ColorPalettes.verdeclaro};
  width: 250px;
  display: flex;
  justify-content: center;
  transition: 350ms;
  z-index: 10;
  left: 30px;
  top: 30px;
  bottom: 30px;
  border-radius: 15px;
  overflow-y: auto;
  @media screen and (max-width: 1024px) {
    left: ${({ isOpen }) => (isOpen ? "0" : "-400px")};
    width: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    top: 0;
    bottom: 0;
    border-radius: 0;
    z-index: 1000;
  }
`;
const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 1.5rem 1.5rem;
  color: white;
  @media screen and (max-width: 1024px) {
    padding: 0.8rem 0;
  }
`;
const Title = styled.h1`
  font-size: 36px;
  cursor: pointer;
  font-family: "Dancing Script", cursive;
  &:hover {
    color: blue;
  }
`;
const SidebarWrap = styled.div`
  width: 100%;
`;

const NavbarSupervisor = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    //Vuelve visible el Sidebar
    setIsOpen(!isOpen);
  };

  /* const selectItems = () => {
    if(metaData.correo === 'supervisor'){
      setNewState(supervisorData)
      fetchData(supervisorData)
    }
  } */

  return (
    <>
      <NavSup isOpen={isOpen} onClick={toggle}>
        <MobileIcon onClick={toggle}>
          <Bars />
        </MobileIcon>
      </NavSup>

      <SidebarNav isOpen={isOpen}>
        <SidebarWrap>
          <ImageWrapper onClick={toggle}>
            <Title>Formatos Web</Title>
          </ImageWrapper>
          {SidebarDataSupervisor.map((item, index) => {
            return <SubMenu toggle={toggle} item={item} key={index} />;
          })}
        </SidebarWrap>
      </SidebarNav>
    </>
  );
};

export default NavbarSupervisor;
