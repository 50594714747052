import { firebase } from "../firebase-configs";

const firebaseDB = firebase.database();
const firebaseAuth = firebase.auth();

let userData = null;

export const cloneUserData = async (user) => {
  let dbRef;
  let snapshot;
  let currentUser;

  dbRef = firebaseDB.ref("Workers");
  snapshot = await dbRef.once("value");

  if (user === undefined) {
    currentUser = await firebaseAuth.currentUser;
  } else {
    currentUser = user;
  }
  userData = {...snapshot.val()[currentUser.uid], uid: currentUser.uid};
};

export const clearUserData = () => {
  userData = null;
};

export const getUserData = () => {
  return userData;
};

export const isUserAuth = async () => {
  let currUser;
  await firebase.auth().onAuthStateChanged((user) => {
    currUser = user;
  });

  if (currUser !== null) {
    if (userData === null) {
      await cloneUserData(currUser);
    }
    return true;
  }
  return false;
};
