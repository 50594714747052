import React, { useState, useEffect } from "react"; //Hooks
import Navbar from "../../components/Navbar/NavbarVF";
import NavbarSupervisor from "../../components/Navbar/NavBarSupervisor";
import NavbarTrabajador from "../../components/Navbar/NavBarTrabajador";
import { getUserData } from "../../services/userData";
import * as FaIcons from "react-icons/fa";
import { Title } from "../../components/FrontComponents/TextType";
import { ActionButton } from "../../components/FrontComponents/ActionButtons/actionbutton";

import AddForm from "../../components/addForm";
import "./formPage.css";
import {
  Table,
  TableData,
  TableH,
  TableWrapper,
} from "../../components/FrontComponents/Table";
import { firebase } from "../../firebase-configs";
import { Link } from "react-router-dom";
import * as FiIcons from "react-icons/fi";
import Toast from "../../components/toast";
import {
  ButtonWrapper,
  Button,
} from "../../components/FrontComponents/Buttons";
import { Content } from "../../components/FrontComponents/Structure";

const firebaseDB = firebase.database();
let formKey;

const FormPage = () => {
  const [show, setShow] = useState(false);
  const [fetchFormats, setFetchFormat] = useState([]);
  const [fetchManualFormats, setManualFormatsData] = useState([]);
  let data = [];

  //Eliminar formulario manual Plus Petrol (no se eliminar, en firebase se cambia el valor de a false y no se muestra en la web)
  const deleteManualFormat = (item) => {
    if (window.confirm("Estas Seguro de eliminar?")) {
      firebaseDB.ref("FormulariosManuales/" + item + "/state").set(false);
      geTableDataataFromFirebase();
      Toast("Eliminado Correctamente", "success");
    }
  };

  //Eliminar formulario dinamicos
  const handleDelete = (key) => {
    if (window.confirm("Estas Seguro de eliminar?")) {
      firebaseDB
        .ref("Formularios/" + key)
        .remove()
        .then(() => {
          fetchData(data);
        })
        .catch((error) => {
          console.log(error);
        });
      firebaseDB
        .ref("Preguntas/" + key)
        .remove()
        .then(() => {
          delete data[key];
          fetchData(data);
        })
        .catch((error) => {
          console.log(error);
        });
      Toast("Eliminado Correctamente", "success");
    }
  };
  const handleModify = (key) => {
    formKey = key;
    setShow(true);
  };
  const handleAddForm = () => {
    firebaseDB
      .ref("Formularios")
      .push()
      .then((event) => {
        formKey = event._delegate.key;
        setShow(true);
      });
  };
  const handleOnSubmit = () => {
    geTableDataataFromFirebase();
    setShow(false);
  };
  const geTableDataataFromFirebase = () => {
   
    const manualFormatsRef = firebaseDB.ref("FormulariosManuales");
    manualFormatsRef.once("value").then((snapshot) => {
      if (snapshot.val() !== null) {
        fetchManualFormatsData(snapshot.val());
        manualFormatsRef.off();
      }
    });
  };
  //Copia el link dado Key como id del formulario y typeLink como el tipo de link (hacia una pagina, formulario manual o automatico)
  const copyLink = (key, typeLink) => {
    if (typeLink === "page") {
      navigator.clipboard.writeText(
        window.location.protocol + "//" + window.location.host + "#/typedecl"
      );
    } else if (typeLink === "manualForm") {
      navigator.clipboard.writeText(
        window.location.protocol +
          "//" +
          window.location.host +
          "#/" +
          key
      )
    } else {
      navigator.clipboard.writeText(
        window.location.protocol + "//" + window.location.host + "#/form/" + key
      );
    }
    Toast(
      "Se copio al portapapeles",
      "info"
    );
  };
  const fetchData = (data) => {
    const fetch = Object.keys(data).map((key, index) => {
      return (
        <tr key={index}>
          <TableData>{data[key].nombre}</TableData>
          <TableData>
            <Link className="ms-1" to={"/form/" + key}>
              <ActionButton type="view" />
            </Link>
            <ActionButton type="copy" onClick={() => copyLink(key)} />
            <ActionButton type="edit" onClick={() => handleModify(key)} />
            <ActionButton
              type="remove"
              onClick={() => handleDelete(key)}
            />
          </TableData>
        </tr>
      );
    });
    setFetchFormat(fetch);
  };

  const fetchManualFormatsData = (data) => {
    const fetch = Object.keys(data).map((index) => {
      if (data[index].state) {
        return (
          <tr key={index}>
            <TableData>{data[index].nombre}</TableData>
            <TableData>
              <Link className="ms-1" to={"/" + index}>
                <ActionButton type="view" />
              </Link>
              <ActionButton
                type="copy"
                onClick={() => copyLink(index, "manualForm")}
              />
              <ActionButton
                type="remove"
                onClick={() => deleteManualFormat(index)}
              />
            </TableData>
          </tr>
        );
      }
    });
    setManualFormatsData(fetch);
  };
  useEffect((_) => {
    geTableDataataFromFirebase();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {getUserData().area === "Administrador" ? (
        <Navbar />
      ) : getUserData().area === "Supervisor" ? (
        <NavbarSupervisor />
      ) : (
        <NavbarTrabajador />
      )}
      <Content>
        <Title>Lista de Formularios</Title>
        <ButtonWrapper>
          <Button color="secondary" onClick={handleAddForm}>
            <FaIcons.FaPlus></FaIcons.FaPlus> Agregar
          </Button>
          <Button color="secondary" onClick={() => copyLink(null, "page")}>
            <FiIcons.FiPaperclip /> Link
          </Button>
        </ButtonWrapper>

        <TableWrapper>
          <Table>
            <thead style={{ backgroundColor: "#343a40", color: "#fff" }}>
              <tr>
                <TableH>Nombre</TableH>
                <TableH>Acción</TableH>
              </tr>
            </thead>
            <thead>
              {fetchFormats}
              {fetchManualFormats}
            </thead>
          </Table>
        </TableWrapper>
        <AddForm
          onSubmit={handleOnSubmit}
          edit={formKey}
          show={show}
          onHide={() => setShow(false)}
        />
      </Content>
    </div>
  );
};

export default FormPage;
