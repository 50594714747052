import styled from "styled-components";
function getJustifyItem(justify) {
  if (justify === "center") {
    return "center";
  }
  if (justify === "start") {
    return "flex-start";
  }
  if (justify === "end") {
    return "flex-end";
  }
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: ${({ justify }) => getJustifyItem(justify)};
  gap: 10px;
  flex-wrap: wrap;
`;
