import React from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import * as MdIcons from "react-icons/md";
import Firma from "./firm/firm";
import * as AiIcons from "react-icons/ai";

const Question = (props) => {
  let data = {};
  const Buttons = (
    <>
      <Button size="sm" variant="warning" onClick={props.modify}>
        <MdIcons.MdEdit />
      </Button>
      <Button
        size="sm"
        variant="danger"
        className="ms-1"
        onClick={props.delete}
      >
        <MdIcons.MdDelete />
      </Button>
    </>
  );

  const handleChange = (event, key) => {
    if (props.type === "Casilla") {
      data = { ...data, [key]: event.target.id };
    } else if (props.type === "Documento") {
      data[key] = event.target.value;
      data.number = 0;
    } else if (props.type === "Firma") {
      data = { [key]: event };
    } else {
      data = { ...data, [key]: event.target.value };
    }
    props.sendData(data, props.children);
  };

  const Showbutton = () => {
    const offButton = props.estado;
    if (offButton) {
      return Buttons;
    }
    return null;
  };
  let returnData = false;
  if (props.type === "Respuesta") {
    returnData = (
      <Container
        style={{
          backgroundColor: "white",
          borderRadius: "1rem",
          borderTop: "10px solid rgb(61, 143, 45)",
          borderLeft: "7px solid #4285f4",
        }}
        className="mb-3"
      >
        <Col className="mt-2 p-3">
          <Row className="mb-2">
            <Col md="10">
              <h5
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                {props.children}
              </h5>
            </Col>
            <Col md="2" className="text-end pb-1">
              {Showbutton()}
            </Col>
          </Row>
          <Row>
            <Col className="pb-3" md="12">
              {props.typeControl === "input" ? (
                <Form.Control
                  as={props.typeControl}
                  required={props.req}
                  defaultValue={props.initValue}
                  onChange={(event) => handleChange(event, props.children)}
                />
              ) : (
                <Form.Control
                  as={props.typeControl}
                  required={props.req}
                  defaultValue={props.initValue}
                  onChange={(event) => handleChange(event, props.children)}
                >
                  {props.initValue}
                </Form.Control>
              )}
            </Col>
          </Row>
        </Col>
      </Container>
    );
  } else if (props.type === "Fecha") {
    returnData = (
      <Container
        style={{
          backgroundColor: "white",
          borderRadius: "1rem",
          borderTop: "10px solid rgb(61, 143, 45)",
          borderLeft: "7px solid #4285f4",
        }}
        className="mb-3"
      >
        <Col md="12" className="mt-2 p-3">
          <Row className="mb-2">
            <Col>
              <h5
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                {props.children}
              </h5>
            </Col>
            <Col className="text-end">{Showbutton()}</Col>
          </Row>
          <Row>
            <Col className="pb-3" md="12">
              <Form.Control
                required={props.req}
                onChange={(event) => handleChange(event, props.children)}
                type="date"
              />
            </Col>
          </Row>
        </Col>
      </Container>
    );
  } else if (props.type === "Seleccionable") {
    returnData = (
      <Container
        style={{
          backgroundColor: "white",
          borderRadius: "1rem",
          borderTop: "10px solid rgb(61, 143, 45)",
          borderLeft: "7px solid #4285f4",
        }}
        className="mb-3"
      >
        <Col md="12" className="mt-2 p-3">
          <Row className="mb-2">
            <Col>
              <h5
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                {props.children}
              </h5>
            </Col>
            <Col className="text-end">{Showbutton()}</Col>
          </Row>
          <Row>
            <Col className="pb-3" md="12">
              <select
                className="rounded-3"
                required={props.req}
                onChange={(event) => handleChange(event, props.children)}
              >
                <option value="">Elija</option>
                <option value="LATAM">LATAM</option>
                <option value="STAR PERU">STAR PERU</option>
              </select>
            </Col>
          </Row>
        </Col>
      </Container>
    );
  } else if (props.type === "Casilla") {
    returnData = (
      <Container
        style={{
          backgroundColor: "white",
          borderRadius: "1rem",
          borderTop: "10px solid rgb(61, 143, 45)",
          borderLeft: "7px solid #4285f4",
        }}
        className="mb-3"
      >
        <Col md="12" className="mt-2 p-3">
          <Row className="mb-2">
            <Col md="9">
              <h5
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                {props.children}
              </h5>
            </Col>
            <Col md="3" className="text-end">
              {Showbutton()}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                inline
                required={props.req}
                id={"true"}
                onChange={(event) => handleChange(event, props.children)}
                type="radio"
                label="Si"
                name={props.children}
                style={{ width: "5rem" }}
              />

              <Form.Check
                inline
                id={"false"}
                onChange={(event) => handleChange(event, props.children)}
                type="radio"
                label="No"
                name={props.children}
              />
            </Col>
          </Row>
        </Col>
      </Container>
    );
  } else if (props.type === "Texto") {
    returnData = (
      <Container
        style={{
          backgroundColor: "white",
          borderRadius: "1rem",
          borderTop: "10px solid rgb(61, 143, 45)",
          borderLeft: "7px solid #4285f4",
        }}
        className="mb-3 "
      >
        <Col md="12" className="mt-2 p-3">
          <Row className="mb-2">
            <Col md="10">
              <h4
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                {props.children}
              </h4>
            </Col>
            <Col md="2" className="text-end">
              {Showbutton()}
            </Col>
          </Row>
        </Col>
      </Container>
    );
  } else if (props.type === "Documento") {
    returnData = (
      <Container
        style={{
          backgroundColor: "white",
          borderRadius: "1rem",
          borderTop: "10px solid rgb(61, 143, 45)",
          borderLeft: "7px solid #4285f4",
        }}
        className="mb-3 "
      >
        <Col md="12" className="mt-2 p-3">
          <Row>
            <Col md="12">
              <Row>
                <Col md="10">
                  <h5
                    style={{ fontFamily: "Google Sans" }}
                    className="pt-1 fw-bold"
                  >
                    {props.children}
                  </h5>
                </Col>
                <Col md="2" className="text-end pt-3">
                  {Showbutton()}
                </Col>
              </Row>
              <Row className="pb-3">
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label
                    className="fw-bold"
                    style={{ fontFamily: "Google Sans" }}
                  >
                    Tipo de documento:
                  </Form.Label>
                  <Form.Control
                    style={{ fontFamily: "Google Sans" }}
                    onChange={(event) => handleChange(event, "docType")}
                    as="select"
                  >
                    <option>DNI</option>
                    <option>Pasaporte</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label
                    className="fw-bold"
                    style={{ fontFamily: "Google Sans" }}
                  >
                    Número de Documento:
                  </Form.Label>

                  <Form.Control
                    onBlur={(event) => props.search(event, props.children)}
                    type="number"
                    placeholder="Ejm: 6182151"
                  />
                </Form.Group>
              </Row>
            </Col>
          </Row>
        </Col>
      </Container>
    );
  } else if (props.type === "Firma") {
    returnData = (
      <Col className="container p-3" style={{ borderRadius: "1rem" }} md="12">
        <Col md="12" className="text-end pt-3">
          {Showbutton()}
        </Col>
        <Row>
          <Col md="12" className="pb-3">
            <Row>
              <Firma
                firmChange={(event) => handleChange(event, props.children)}
              >
                {props.children}
              </Firma>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }

  return returnData;
};

export default Question;
