import { FastField, Field, Form as FormMK, Formik } from "formik";
import styled from "styled-components";
import ErrorM from "./ErrorMessage";
import { Subtitle } from "./TextType";
export const FieldWrapper = styled.div`
  text-align: left;
  height: 5.7rem;
`;

export const FieldStyle = styled.input`
  width: 100%;
  padding: 0.4rem;
  font-size: 15px;
  border-radius: 5px;
  outline: 0;
  border: 0.5px solid;
  border-color: ${({ meta }) => meta.touched && meta.error && "red"};
`;

export const FormMKStyle = styled(FormMK)`
  width: 100%;
`;
export const FormikStyled = styled(Formik)`
  width: 100%;
`;

export const Input = ({ children, ...rest }) => {
  return (
    /* borderColor: meta.touched && meta.error && "red" */
    <FieldWrapper>
      <FastField name={rest.name}>
        {({ field, meta }) => (
          <>
            <Subtitle modal>{children}</Subtitle>
            <FieldStyle {...rest} {...field} meta={meta} />
            {meta.touched && meta.error && <ErrorM>{meta.error}</ErrorM>}
          </>
        )}
      </FastField>
    </FieldWrapper>
  );
};
