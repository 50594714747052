import React from "react";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
export const SidebarDataAdmin = [
  {
    title: "Formatos",
    path: "/formpage",
    icon: <BsIcons.BsBookFill />,
    cName: "nav-text",
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <MdIcons.MdDashboard />,
    cName: "nav-text",
  },
  {
    title: "Mantenimientos",
    path: "#",
    icon: <BsIcons.BsTable />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    cName: "nav-text",
    subNav: [{ title: "Areas", path: "/areas" }],
  },
  {
    title: "Trabajadores",
    path: "/Trabajadores",
    icon: <FaIcons.FaUserTie />,
    cName: "nav-text",
  },
  

  {
    title: "Salir",
    path: "/",
    icon: <IoIcons.IoMdExit />,
    cName: "nav-text",
  },
];
export const SidebarDataSupervisor = [
  {
    title: "Formatos",
    path: "/formpage",
    icon: <BsIcons.BsBookFill />,
    cName: "nav-text",
  },
  {
    title: "Lista de Respuestas",
    path: "/Respuestas",
    icon: <IoIcons.IoIosAnalytics />,
    cName: "nav-text",
  },
  {
    title: "Solicitudes",
    path: "/solicitud",
    icon: <IoIcons.IoMdNotifications />,
    cName: "nav-text",
  },
  {
    title: "Salir",
    path: "/",
    icon: <IoIcons.IoMdExit />,
    cName: "nav-text",
  },
];
export const SidebarDataTrabajador = [
  {
    title: "Formatos",
    path: "/typedecl",
    icon: <BsIcons.BsBookFill />,
    cName: "nav-text",
  },
  {
    title: "Solicitudes",
    path: "/solicitud",
    icon: <IoIcons.IoMdNotifications />,
    cName: "nav-text",
  },
  {
    title: "Salir",
    path: "/",
    icon: <IoIcons.IoMdExit />,
    cName: "nav-text",
  },
];
