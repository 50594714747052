import axios from "axios"

const endPoint = "https://visionary-arithmetic-c3e117.netlify.app/.netlify/functions/api/"

export const test = async() => {
    return await axios.get(endPoint + "test")
}

export const createUser = async(values) => {
    const resp = await axios.post(endPoint + "worker", values, {validateStatus: async(status) => {
        if(status !== 200){
            console.log("Error " + status)
        }
    }})
    return resp.data
}

export const deleteUser = async(uid) => {
    const resp = await axios.delete(endPoint + "worker/" + uid, {validateStatus: async(status) => {
        if(status !== 200){
            console.log("Error " + status)
        }
    }})
    return resp.data
}