import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { firebase } from "../../firebase-configs";
const SidebarLink = styled(Link)`
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  font-weight: ${({ subnav }) => (subnav ? 700 : 500)};
  &:hover {
    background: #fff;
    border-left: 4px solid #fff;
    cursor: pointer;
    color: #3f37c9;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #26417e;
  height: 40px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;

  &:hover {
    background: #fff;
    cursor: pointer;
    color: #3f37c9;
  }
  `;
const ContentSiderbarLink = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 20px 1fr;
  `;
 

const SubMenu = ({ item, toggle }) => {
    const [subnav, setSubnav] = useState(false);
    const handleClick = (event) => {
      if (item.title === "Salir") {
        console.log(item.title);
        firebase
          .auth()
          .signOut()
          .catch((error) => {
            console.log(error);
          });
      }
      console.log(item.subNav);
      if (item.subNav) {
        showSubnav(event);
      } else {
        toggle();
      }
    }
    const showSubnav = (event) => {
      event.preventDefault();
      setSubnav(!subnav);
    };
  
    return (
      <>
        <SidebarLink
          to={item.path}
          onClick={handleClick}
          subnav={subnav.toString()}
        >
          <ContentSiderbarLink>
            <div>{item.icon}</div>
            <SidebarLabel>{item.title}</SidebarLabel>
          </ContentSiderbarLink>
          <div>
            {item.subNav && subnav
              ? item.iconOpened
              : item.subNav
              ? item.iconClosed
              : null}
          </div>
        </SidebarLink>
        {subnav &&
          item.subNav.map((item, index) => {
            return (
              <DropdownLink onClick={toggle} to={item.path} key={index}>
                {item.icon}
                <SidebarLabel>{item.title}</SidebarLabel>
              </DropdownLink>
            );
          })}
      </>
    );
  };
  

export default SubMenu;
