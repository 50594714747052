import { firebase } from "../firebase-configs";

const firebaseDB = firebase.database();

export const getAllFormsData = async () => {
  let formRef;
  let snapshot;
  let out = {};

  formRef = firebaseDB.ref("Formularios");
  snapshot = await formRef.once("value");

  out = { ...snapshot.val() };

  out = Object.assign(out, await getManualFormsData());

  return out;
};

export const getManualFormsData = async () => {
  let formRef;
  let snapshot;
  let out = {};

  formRef = firebaseDB.ref("FormulariosManuales");
  snapshot = await formRef.once("value");

  Object.keys(snapshot.val()).map((key) => {
    if (snapshot.val()[key].state) {
      out[key] = { ...snapshot.val()[key] };
    }
  });

  return out;
};

export const getWorkersData = async () => {
  const workersRef = firebaseDB.ref("Workers");
  const snapshot = await workersRef.once("value");

  return snapshot.val();
};

export const getWorkerData = async (id) => {
  const workersRef = firebaseDB.ref("Workers/" + id);
  const snapshot = await workersRef.once("value");

  return snapshot.val();
}

export const getAreasData = async() => {
  const workersRef = firebaseDB.ref("Areas");
  const snapshot = await workersRef.once("value");

  return snapshot.val();
}

export const getAnswerData = async(answerId) => {
  const ref = firebaseDB.ref("Respuestas/" + answerId)
  const snapshot = await ref.once("value")

  return snapshot.val()
}

export const getFormData = async(formId) => {
  const ref = firebaseDB.ref("FormulariosManuales/" + formId)
  const snapshot = await ref.once("value")

  return snapshot.val()
}