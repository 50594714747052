import styled from "styled-components";
import { ErrorMessageStyled } from "../../ErrorMessageStyled";
import { useField } from "formik";
import { Subtitle } from "../TextType";
const TextInput = styled.input`
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  width: 100%;
  outline: none;
  color: #4e4d4e;
  margin-bottom: 5px;
  transition: border-color 0.1s ease-out;
  transition: border-width 0.1s ease-out;
  &:focus {
    border-color: #1f487e;
    border-width: 2px;
  }
`;

export const TextField = ({ title, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <Subtitle>{title}</Subtitle>
      <TextInput {...field} {...props} />
      <ErrorMessageStyled component="div" name={props.name} />
    </>
  );
};
