import { firebase } from "../firebase-configs";

const firebaseDB = firebase.database();

export const uploadVehiculosLigeros = async(values) => {
    const ref = firebaseDB.ref("Respuestas/");
    try {
        const out = await ref.push(values)
        ref.off()
        return {error: "", key: out.key}
    } catch (error) {
        ref.off()
        return {error: error}
    }
}

export const uploadAnswer = async(values) => {
    const ref = firebaseDB.ref("Respuestas/");
    try {
        const out = await ref.push(values)
        ref.off()
        return {error: "", key: out.key}
    } catch (error) {
        ref.off()
        return {error: error}
    }
}

export const sendNotification = async(values, key, payload) => {
    values.map(async(item) => {
        const id = item.nombre !== undefined ? item.nombre : item.id
        const ref = firebaseDB.ref("Workers/" + id + "/notifications/" + key);
        if(payload !== undefined){
            await ref.set({payload: payload, type: "form"})
        } else {
            await ref.set({type: "check"}) 
        }
        ref.off()
    })
}

export const uploadNewArea = async(values) => {
    const ref = firebaseDB.ref("Areas/");
    try {
        const out = await ref.push(values)
        ref.off()
        return {error: "", key: out.key}
    } catch (error) {
        ref.off()
        return {error: error}
    }
}

export const uploadNewUser = async(values, uid) => {
    return await generalSet("Workers/" + uid, values)
}

export const updateAnswer = async(values, answerId) => {
    return await generalUpdate("Respuestas/" + answerId, values)
}

const generalUpdate = async(table, values) => {
    const ref = firebaseDB.ref(table + "/");
    try {
        const out = await ref.update(values)
        ref.off()
        return {error: ""}
    } catch (error) {
        ref.off()
        return {error: error}
    }
}

const generalSet = async(table, values) => {
    const ref = firebaseDB.ref(table + "/");
    try {
        const out = await ref.set(values)
        ref.off()
        return {error: ""}
    } catch (error) {
        ref.off()
        return {error: error}
    }
}

const generalUpload = async(table, values) => {
    const ref = firebaseDB.ref(table + "/");
    try {
        const out = await ref.push(values)
        ref.off()
        return {error: "", key: out.key}
    } catch (error) {
        ref.off()
        return {error: error}
    }
}

//remove items functions

const generalRemove = async(table, id) => {
    const ref = firebaseDB.ref(table + "/" + id)
    try {
        await ref.remove()
        ref.off()
        return {error: ""}
    } catch (error) {
        ref.off()
        return {error: error}
    }
}

export const removeArea = async(key) => {
    const ref = firebaseDB.ref("Areas/" + key)
    try {
        await ref.remove()
        ref.off()
        return {error: ""}
    } catch (error) {
        ref.off()
        return {error: error}
    }
}

export const removeUser = async(uid) => {
    return generalRemove("Workers", uid)
}

export const removeNotification = async(uid, answerId) => {
    return generalRemove("Workers/" + uid + "/notifications", answerId)
}

export const removeAnswer = async(answerId) => {
    return generalRemove("Respuestas/", answerId)
}