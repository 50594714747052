import React from "react";
import styled from "styled-components";

/* const Contenedor = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0b1721;
`; */

const Label = styled.label`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 1px solid #c7c7c7;
  &: after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    border-bottom: 3px solid #2ecece;
    transform: translateX(-100%);
    transition: all 0.3s ease;
  }
`;

const Span = styled.span`
  position: absolute;
  bottom: 5px;
  left: 0;
  transition: all 0.3s ease;
  color: #000;
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  background: none;
  color: #000000;
  padding-top: 12px;
  border: none;
  outline: 0px;
`;
const Form = styled.form`
  width: 100%;
  height: 50px;
  max-width: 400px;
  position: relative;
  overflow: hidden;
  & ${Input}: focus + ${Label} ${Span}, & ${Input}:valid + ${Label} ${Span} {
    transform: translateY(-140%);
    font-size: 14px;
    color: #2ecece;
  }
`;
const Question = () => {
  return (
    <Form>
      <Input required={true} />
      <Label>
        <Span>hola</Span>
      </Label>
    </Form>
  );
};
export default Question;
