import React, { useState } from "react";
import Animated from "./Animated";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Form } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import * as GiIcons from "react-icons/gi";
import { Redirect } from "react-router-dom";
import { firebase } from "../firebase-configs";
import Toast from "./toast";
import { cloneUserData, getUserData } from "../services/userData";
import styled from "styled-components";

const firebaseAuth = firebase;

const LoginForm = () => {
  const [data, setProvider] = useState();
  const [toForms, setToForms] = useState(false);
  const [toResp, setToResp] = useState(false);

  const handleChange = (event) => {
    if (event.target.placeholder === "Usuario") {
      setProvider({ ...data, user: event.target.value });
    } else {
      setProvider({ ...data, password: event.target.value });
    }
  };

  const authUser = async (event) => {
    event.preventDefault();

    if (data !== undefined){
      try {
        const currUser = await firebaseAuth
          .auth()
          .signInWithEmailAndPassword(data.user, data.password);
        await cloneUserData(currUser.user);
        Toast("Ingreso Correctamente", "success");
      } catch (e) {
        Toast("Email o contraseña incorrectos", "error");
      }
    } else {
      Toast("Email o contraseña incorrectos", "error");
      return
    }
    const userData = getUserData()
    if (userData.area === "Administrador" || userData.area === "Supervisor") {
      setToForms(true);
    } else {
      setToResp(true);
    }
  };

  return (
    <>
      {toForms ? <Redirect to="/formPage" /> : null}
      {toResp ? <Redirect to="/typedecl" /> : null}
      <Animated>
        <div>
          <Row>
            <HeaderLogin>
              <h3 style={{ color: "white" }}>Iniciar Sesión</h3>
            </HeaderLogin>
            <Col
              style={{
                padding: "1rem 2rem",
                backgroundColor: "#fff",
                width: "22rem",
                minWidth: "264px",
                height: "23rem",
              }}
            >
              <form onSubmit={authUser}>
                <Form.Group role="form" controlId="formGridNombre">
                  <Inputfield>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <FaIcons.FaUser className="image" />
                    </div>
                    <input
                      required
                      onChange={handleChange}
                      className="inputo"
                      type="email"
                      placeholder="Usuario"
                    />
                  </Inputfield>
                  <Inputfield>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <GiIcons.GiPadlock className="image" />
                    </div>
                    <input
                      className="inputo"
                      onChange={handleChange}
                      required
                      type="password"
                      placeholder="Contraseña"
                    />
                  </Inputfield>
                  {/* <Form.Control
                    required
                    onChange={handleChange}
                    type="email"
                    placeholder="Usuario"
                  />
                  <Form.Label style={{ color: "white" }}>Contraseña</Form.Label>
                  <Form.Control
                    required
                    onChange={handleChange}
                    type="password"
                    placeholder="Contraseña"
                  /> */}
                  <Button
                    style={{
                      backgroundColor: "#4481eb",
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                    type="submit"
                    size="lg"
                    className="mt-3"
                  >
                    Iniciar Sesión
                  </Button>
                </Form.Group>
              </form>
            </Col>
          </Row>
        </div>
      </Animated>
    </>
  );
};
const Inputfield = styled.div`
  width: 100%;
  background-color: #f0f0f0;
  margin: 20px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
  .inputo {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #000;
  }
  .image {
    width: 100%;
    text-align: center;
    line-height: 55px;
    color: #acacac;
    transition: 0.5s;
    font-size: 2rem;
  }
`;
const HeaderLogin = styled.div`
  background: #4481eb;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
`;
export default LoginForm;
