import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const configs = {
  apiKey: "AIzaSyDqdwvZNPwT7FPSIE76M4FFIpH6k4IBCcw",
  authDomain: "test-formatos-web.firebaseapp.com",
  databaseURL: "https://test-formatos-web-default-rtdb.firebaseio.com",
  projectId: "test-formatos-web",
  storageBucket: "test-formatos-web.appspot.com",
  messagingSenderId: "533606296335",
  appId: "1:533606296335:web:613f84e2230f4eac89ec7a",
  measurementId: "G-7Y0X01F06E",
};

const initFirebase = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(configs);
  }
};

initFirebase();

export { firebase };
