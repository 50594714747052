                                                    let currentY = 0;
let currentX = 0;
let currentRow = 0;
let selectValueOptionsWidth = 5

export const setSelectValueOptionWidth = (width) => {
  selectValueOptionsWidth = width
}

export const resetTable = () => {
  currentY = 0;
  currentX = 0;
  currentRow = 0;
};

export const cell = (doc, x, y, width, height, txt, rowNumber, style) => {
  let textAngle = 0
  if (txt === undefined) {
    txt = "";
  }
  if (currentY === 0) {
    currentY = y;
  }
  if (currentX === 0) {
    currentX = x;
  }
  if (currentRow === 0) {
    currentRow = rowNumber;
    
  } else {
    if (currentRow !== rowNumber) {
      if(currentX === 0){
        currentY = currentY + height;
      }
      currentY = currentY + height;
      currentX = x;
      currentRow = rowNumber;
    }
  }
  const inlineDimentionText = doc.getTextDimensions(txt)
  if(style !== undefined){
    style.baseline = "top"
    style.lineHeightFactor = 1.15
  } else {
    style = {baseline: "top", lineHeightFactor: 1.15}
  }
  if (style !== undefined && style.angle !== undefined) {
    textAngle = style.angle
    txt = doc.splitTextToSize(
      txt,
      height - 1
    )
  } else {
    txt = doc.splitTextToSize(
      txt,
      width - 1
    )
  }
  if (style !== undefined && style.color !== undefined) {
    doc.setFillColor(style.color);
    doc.rect(currentX, currentY, width, height, "FD");
  } else {
    doc.rect(currentX, currentY, width, height);
  }
  const textDimentions = doc.getTextDimensions(txt);
  if (style !== undefined && style.align !== undefined) {
    if (style.align === "center") {
      doc.text(
        txt,
        textAngle === 0 ? currentX + width / 2 : currentX + inlineDimentionText.h + ((width - textDimentions.h) / 2),
        textAngle === 0 ? currentY + ((height - textDimentions.h) / 2): currentY - inlineDimentionText.h + (textDimentions.w + height) / 2,
        textAngle === 0 ? style : {angle: style.angle, baseline: style.baseline}
      );
    } else if (style.align === "right") {
      doc.text(
        txt,
        textAngle === 0 ? width + currentX - 1 : currentX + inlineDimentionText.h - (textDimentions.h - width) / 2,
        textAngle === 0 ? currentY + (height - textDimentions.h) / 2 : currentY - inlineDimentionText.h + textDimentions.w + 1,
        textAngle === 0 ? style : {angle: style.angle, baseline: style.baseline}
      );
    } else {
      doc.text(
        txt,
        textAngle === 0 ? currentX + 1 : currentX + inlineDimentionText.h - (textDimentions.h - width) / 2,
        textAngle === 0 ? currentY + (height - textDimentions.h) / 2: currentY - inlineDimentionText.h + height,
        style
      );
    }
  } else {
    doc.text(
      txt,
      textAngle === 0 ? currentX + 1 : currentX + inlineDimentionText.h - (textDimentions.h - width) / 2,
      textAngle === 0 ? currentY + (height - textDimentions.h) / 2: currentY - inlineDimentionText.h + height,
      style
    );
  }
  currentX = currentX + width;
  y = y + height;
};

export const tfValue = (doc, x, y, width, alturaTabla, txt, rowNumber, data) => {
  cell(doc, x, y, width - 30, alturaTabla, txt, rowNumber);
  if (data === "si") {
    cell(doc, x, y, 15, alturaTabla, "X", rowNumber, { align: "center" });
    cell(doc, x, y, 15, alturaTabla, "", rowNumber);
  } else {
    cell(doc, x, y, 15, alturaTabla, "", rowNumber);
    cell(doc, x, y, 15, alturaTabla, "X", rowNumber, { align: "center" });
  }
};

export const selectValue = (doc, x, y, width, height, txt, rowNumber, data) => {
  if(txt !== ""){
    cell(doc, x, y, width - (data.items.length * selectValueOptionsWidth), height, txt, rowNumber);
  }
  for(const item of data.items){
    if(item === data.value){
      cell(doc, x, y, selectValueOptionsWidth, height, "X", rowNumber, { align: "center" });
    } else {
      cell(doc, x, y, selectValueOptionsWidth, height, "", rowNumber);
    }
  }
}