import styled from "styled-components";
import { ColorPalettes } from "./Colors";
export const Title = styled.h1`
  font-size: 21px;
  font-weight: bold;
  text-align: left;

  color: #313131;
  margin-bottom: ${({ modal }) => (modal ? "0rem" : "1.5rem")};
  /* margin-bottom: 1.5rem; */
  @media screen and (min-width: 500px) {
    font-size: 25px;
  }
  @media screen and (min-width: 800px) {
    font-size: 27px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 25px;
  }
  @media screen and (min-width: 1400px) {
    font-size: ${({ modal }) => (modal ? "25px" : "40px")};
    /* font-size: 57px; */
  }
`;

export const Subtitle = styled.h4`
  display: inline;
  font-weight: 600;
  font-size: 15px;
  color: ${ColorPalettes.azuloscuro};
  line-height: 40px;

  @media screen and (min-width: 500px) {
    font-size: 17px;
  }
  @media screen and (min-width: 800px) {
    font-size: 19px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 17px;
  }
  @media screen and (min-width: 1400px) {
    font-size: ${({ modal }) => (modal ? "18px" : "22px")};
  }
`;
