import { useEffect, useState } from "react";
export const useCurrentPosition = (initialValue) => {
  const [latitud, setLatitud] = useState(initialValue);
  const [longitud, setLongitud] = useState(initialValue);
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    let crd = pos.coords;
    setLatitud(crd.latitude);
    setLongitud(crd.longitude);
  }

  function error(err) {
    console.warn("ERROR(" + err.code + "): " + err.message);
  }
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success, error, options);
  }, []);
  return [latitud, longitud];
};
