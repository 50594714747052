import { jsPDF } from "jspdf";
import vehiculosLigerosIcon from "../../icons/vehiculosLigerosIcon.js";
import vehiculosligerosCars from "../../icons/vehiculosligerosCars.js";
import { resetTable, cell, selectValue, setSelectValueOptionWidth} from "../pdfFunctions.js";
import vehiculosLigeros from "../../manualFormsDataStructure/vehiculosligerosNames.json"
import { getWorkerData } from "../databaseData.js";

const initUserData = {
  name: "",
  email: "",
  area: "",
  firma: vehiculosLigerosIcon,
  logo: vehiculosLigerosIcon,
  empresa: "",
}

export const downloadVehiculosLigerosPdf = async(values) => {
  resetTable()
  let userData = await getWorkerData(values.userId)
  const doc = new jsPDF();
  const heightCell = 4;
  const box1 = 8;
  const selectHead = ["SI", "NO", "NA"]
  const selectBox = ["1", "0"]


  //Cuadro de la esquina superior derecha
  doc.setFontSize(4);
  cell(doc,161,0,20,heightCell,"Código",1);
  cell(doc,161,0,20,heightCell,"R.BMB.PRY.SST.EST.01.07",1);
  cell(doc,161,0,20,heightCell,"Versión",2);
  cell(doc,161,0,20,heightCell,"01",2);
  cell(doc,161,0,20,heightCell,"Fecha de Aprobación:",3);
  cell(doc,161,0,20,heightCell,"25-08-2021",3);
  resetTable()
  //Logo de la Empresa
  if(userData !== null && userData.logo !== undefined){
    doc.addImage(userData.logo, "PNG",  1, 5, 33.5, 5)
  } else {
    doc.addImage(vehiculosLigerosIcon, "PNG", 5, 5);
    userData = initUserData
  }
  doc.setFontSize(12);
  doc.text("CHECK LIST VEHICULOS LIVIANOS", 110, 10, {align:"center"});
  doc.setFontSize(8);
  doc.text("CAMIONETA", 110, 14,"center");
  //Primera tabla
  resetTable();
  //placa de la unidad
  cell(doc, 5, 20, 46, heightCell, vehiculosLigeros.placa, 1, {
    color: "#cccccc",
  });
  cell(doc, 5, 20, 50, heightCell, values.placa, 1);
  //kilometraje Inicial
  cell(doc, 5, 0, 46, heightCell, vehiculosLigeros.kilometraje, 2, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 50, heightCell, values.kilometraje, 2);
  //Segunda tabla
  resetTable();
  //Fecha
  const date = new Date(values.createDate);
  cell(doc, 105, 20, 44, heightCell, "Fecha:", 1, {
    color: "#cccccc",
  });
  cell(doc, 5, 0, 15, heightCell,date.toLocaleDateString(), 1);
  cell(doc, 5, 0, 17, heightCell,"HRS", 1, {color: "#cccccc",});
  cell(doc, 5, 0, 20, heightCell,date.toLocaleTimeString(), 1);
  //Lugar de Inspección
  cell(doc, 105, 20, 44, heightCell, "Lugar de Inspección:", 2, {color: "#cccccc",});
  cell(doc, 5, 0, 52, heightCell,values.latitud + ", " + values.longitud, 2);
  //SEGUNDA NUEVA TABLA PRIMERA
  resetTable();
  doc.setFontSize(7);
  cell(doc, 5, 38, 5, 188, "UNIDAD", 1, {angle: 90,align:"center",color: "#1e81b0"})
  resetTable();
  doc.setFontSize(5);
  cell(doc, 10, 30, 40, box1, "DESCRIPCION", 2, { 
    color: "#1e81b0",
  });
  cell(doc, 10, 30, 15, heightCell, "TIENE", 2, {
    color: "#1e81b0",
  });
  cell(doc, 10, 30, 10, heightCell, "ESTADO", 2, {
    color: "#1e81b0",
  });
  cell(doc, 10, 30, 13, box1, "CANTIDAD REQUERIDA", 2, {
    color: "#1e81b0",
  });
  cell(doc, 10, 30, 13, box1, "CANTIDAD EXISTENTE", 2, {
    color: "#1e81b0",
  });
  cell(doc, 50, 30, 5, heightCell, "SI", 3, {
    color: "#abdbe3",
  });
  cell(doc, 60, 0, 5, heightCell, "NO", 3, {
    color: "#abdbe3",
  });
  cell(doc, 60, 0, 5, heightCell, "NA", 3, {
    color: "#abdbe3",
  });
  cell(doc, 0, 0, 5, heightCell, "B", 3, {
    color: "#abdbe3",
  });
  cell(doc, 0, 0, 5, heightCell, "M", 3, {
    color: "#abdbe3",
  });
  //cuadro con tipo de letra volteada
  resetTable();
  doc.setFontSize(5);
  cell(doc, 105, 38, 5, 32, "ELEMENTOS DE PROTECCION PERSONAL", 1, {angle: 90, align:"center", color: "#1e81b0"})
  resetTable();
  doc.setFontSize(5);
  cell(doc, 105, 70, 5, 24, "DOCUMENTOS CONDUCTOR (vigentes)", 1, {angle: 90, align:"center", color: "#1e81b0"})
  resetTable();
  doc.setFontSize(5);
  cell(doc, 105, 94, 5, 40, "DOCUMENTOS UNIDAD (vigentes)", 1, {angle: 90, align:"center", color: "#1e81b0"})
  resetTable();
  doc.setFontSize(5);
  cell(doc, 105, 134, 5, 48, "HERRAMIENTAS E IMPLEMENTOS", 1, {angle: 90, align:"center", color: "#1e81b0"})
  resetTable();
  doc.setFontSize(5);
  cell(doc, 105, 182, 5, 40, "IMPLEMENTOS DE SEGURIDAD", 2, {angle: 90, align:"center", color: "#1e81b0"})
  
  resetTable();
  doc.setFontSize(5);
  cell(doc, 110, 30, 40, box1, "DESCRIPCION", 2, { 
    color: "#1e81b0",
  });
  cell(doc, 110, 30, 15, heightCell, "TIENE", 2, {
    color: "#1e81b0",
  });
  cell(doc, 0, 30, 10, heightCell, "ESTADO", 2, {
    color: "#1e81b0",
  });
  cell(doc, 0, 30, 13, box1, "CANTIDAD REQUERIDA", 2, {
    color: "#1e81b0",
  });
  cell(doc, 0, 30, 13, box1, "CANTIDAD EXISTENTE", 2, {
    color: "#1e81b0",
  });
  cell(doc, 150, 30, 5, heightCell, "SI", 3, {
    color: "#abdbe3",
  });
  cell(doc, 0, 0, 5, heightCell, "NO", 3, {
    color: "#abdbe3",
  });
  cell(doc, 0, 0, 5, heightCell, "NA", 3, {
    color: "#abdbe3",
  });
  cell(doc, 0, 0, 5, heightCell, "B", 3, {
    color: "#abdbe3",
  });
  cell(doc, 0, 0, 5, heightCell, "M", 3, {
    color: "#abdbe3",
  });
  resetTable();
  setSelectValueOptionWidth(5);
  //parabrisas delantero
  selectValue(doc, 10, 38, 55, heightCell, vehiculosLigeros.parabri_delantero, 1, {items: selectHead, value: values.parabri_delantero})
  selectValue(doc, 0, 0, 55, heightCell, "", 1, {items: selectBox, value: values.parabri_delantero_est})
  cell(doc,0,0,13,heightCell,values.parabri_delantero_req.toString(),1,{align:"center"})
  cell(doc,0,0,13,heightCell,values.parabri_delantero_exi.toString(),1,{align:"center"})
  //ventana lateral 
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.ventana_lateral, 2, {items: selectHead, value: values.ventana_lateral})
  selectValue(doc, 0, 0, 55, heightCell, "", 2, {items: selectBox, value: values.parabri_delantero_est})
  cell(doc,0,0,13,heightCell,values.ventana_lateral_req.toString(),2,{align:"center"})
  cell(doc,0,0,13,heightCell,values.ventana_lateral_exi.toString(),2,{align:"center"})
  //parabrisas trasero
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.parabri_trasero, 3, {items: selectHead, value: values.parabri_trasero})
  selectValue(doc, 0, 0, 55, heightCell, "", 3, {items: selectBox, value: values.parabri_trasero_est})
  cell(doc,0,0,13,heightCell,values.parabri_trasero_req.toString(),3,{align:"center"})
  cell(doc,0,0,13,heightCell,values.parabri_trasero_exi.toString(),3,{align:"center"})
  //cabina ordenada y limpia
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.cabina, 4, {items: selectHead, value: values.cabina})
  selectValue(doc, 0, 0, 55, heightCell, "", 4, {items: selectBox, value: values.cabina_est})
  cell(doc,0,0,13,heightCell,"",4,{align:"center"})
  cell(doc,0,0,13,heightCell,values.cabina_exi.toString(),4,{align:"center"})
  //asientos con apoya cabezas
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.asiento_apoyacabeza, 5, {items: selectHead, value: values.asiento_apoyacabeza})
  selectValue(doc, 0, 0, 55, heightCell, "", 5, {items: selectBox, value: values.asiento_apoyacabeza_est})
  cell(doc,0,0,13,heightCell,values.asiento_apoyacabeza_req.toString(),5,{align:"center"})
  cell(doc,0,0,13,heightCell,values.asiento_apoyacabeza_exi.toString(),5,{align:"center"})
  //Fundas con asientos
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.funda, 6, {items: selectHead, value: values.funda})
  selectValue(doc, 0, 0, 55, heightCell, "", 6, {items: selectBox, value: values.funda_est})
  cell(doc,0,0,13,heightCell,"",6,{align:"center"})
  cell(doc,0,0,13,heightCell,values.funda_exi.toString(),6,{align:"center"})
  //cinturones de seguridad
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.cinturon_seguridad, 7, {items: selectHead, value: values.cinturon_seguridad})
  selectValue(doc, 0, 0, 55, heightCell, "", 7, {items: selectBox, value: values.cinturon_seguridad_est})
  cell(doc,0,0,13,heightCell,values.cinturon_seguridad_req.toString(),7,{align:"center"})
  cell(doc,0,0,13,heightCell,values.cinturon_seguridad_exi.toString(),7,{align:"center"})
  //air bag
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.air_bag, 8, {items: selectHead, value: values.air_bag})
  selectValue(doc, 0, 0, 55, heightCell, "", 8, {items: selectBox, value: values.air_bag_est})
  cell(doc,0,0,13,heightCell,values.air_bag_req.toString(),8,{align:"center"})
  cell(doc,0,0,13,heightCell,values.air_bag_exi.toString(),8,{align:"center"})
  //encendido y apagado del motor 
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.encendido_motor, 9, {items: selectHead, value: values.encendido_motor})
  selectValue(doc, 0, 0, 55, heightCell, "", 9, {items: selectBox, value: values.encendido_motor_est})
  cell(doc,0,0,13,heightCell,"",9,{align:"center"})
  cell(doc,0,0,13,heightCell,values.encendido_motor_exi.toString(),9,{align:"center"})
  //frenos de servicio/parqueo
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.frenos_servicios, 10, {items: selectHead, value: values.frenos_servicios})
  selectValue(doc, 0, 0, 55, heightCell, "", 10, {items: selectBox, value: values.frenos_servicios_est})
  cell(doc,0,0,13,heightCell,"",10,{align:"center"})
  cell(doc,0,0,13,heightCell,values.frenos_servicios_exi.toString(),10,{align:"center"})
  //direccion
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.direccion, 11, {items: selectHead, value: values.direccion})
  selectValue(doc, 0, 0, 55, heightCell, "", 11, {items: selectBox, value: values.direccion_est})
  cell(doc,0,0,13,heightCell,"", 11,{align:"center"})
  cell(doc,0,0,13,heightCell,values.direccion_exi.toString(), 11,{align:"center"})
  //funcionamiento de indicadores de alerta
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.funcionamiento_indicador, 12, {items: selectHead, value: values.funcionamiento_indicador})
  selectValue(doc, 0, 0, 55, heightCell, "", 12, {items: selectBox, value: values.funcionamiento_indicador_est})
  cell(doc,0,0,13,heightCell,"", 12,{align:"center"})
  cell(doc,0,0,13,heightCell,values.funcionamiento_indicador_exi.toString(), 12,{align:"center"})
  //claxon electrico
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.claxon, 13, {items: selectHead, value: values.claxon})
  selectValue(doc, 0, 0, 55, heightCell, "", 13, {items: selectBox, value: values.claxon_est})
  cell(doc,0,0,13,heightCell,"", 13,{align:"center"})
  cell(doc,0,0,13,heightCell,values.claxon_exi.toString(), 13,{align:"center"})
  //sistema limpiaparabrisas
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.limpiaparabrisas, 14, {items: selectHead, value: values.limpiaparabrisas})
  selectValue(doc, 0, 0, 55, heightCell, "", 14, {items: selectBox, value: values.limpiaparabrisas_est})
  cell(doc,0,0,13,heightCell,"", 14,{align:"center"})
  cell(doc,0,0,13,heightCell,values.limpiaparabrisas_exi.toString(), 14,{align:"center"})
  //sistema de aire acondicionado y calefacción
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.aire_acondicionado, 15, {items: selectHead, value: values.aire_acondicionado})
  selectValue(doc, 0, 0, 55, heightCell, "", 15, {items: selectBox, value: values.aire_acondicionado_est})
  cell(doc,0,0,13,heightCell,"", 15,{align:"center"})
  cell(doc,0,0,13,heightCell,values.aire_acondicionado_exi.toString(), 15,{align:"center"})
  //autoradio
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.autoradio, 16, {items: selectHead, value: values.autoradio})
  selectValue(doc, 0, 0, 55, heightCell, "", 16, {items: selectBox, value: values.autoradio_est})
  cell(doc,0,0,13,heightCell,"", 16,{align:"center"})
  cell(doc,0,0,13,heightCell,values.autoradio_exi.toString(), 16,{align:"center"})
  //encendedor/ceniceros
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.encendedor, 17, {items: selectHead, value: values.encendedor})
  selectValue(doc, 0, 0, 55, heightCell, "", 17, {items: selectBox, value: values.encendedor_est})
  cell(doc,0,0,13,heightCell,"", 17,{align:"center"})
  cell(doc,0,0,13,heightCell,values.encendedor_exi.toString(), 17,{align:"center"})
  //tapasol
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.tapasol, 18, {items: selectHead, value: values.tapasol})
  selectValue(doc, 0, 0, 55, heightCell, "", 18, {items: selectBox, value: values.tapasol_est})
  cell(doc,0,0,13,heightCell,"", 18,{align:"center"})
  cell(doc,0,0,13,heightCell,values.tapasol_exi.toString(), 18,{align:"center"})
  //pisos
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.pisos, 19, {items: selectHead, value: values.pisos})
  selectValue(doc, 0, 0, 55, heightCell, "", 19, {items: selectBox, value: values.pisos_est})
  cell(doc,0,0,13,heightCell,"", 19,{align:"center"})
  cell(doc,0,0,13,heightCell,values.pisos_exi.toString(), 19,{align:"center"})
  //lus baja/Alta de faro delantero
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.luz_baja, 20, {items: selectHead, value: values.luz_baja})
  selectValue(doc, 0, 0, 55, heightCell, "", 20, {items: selectBox, value: values.luz_baja_est})
  cell(doc,0,0,13,heightCell,"", 20,{align:"center"})
  cell(doc,0,0,13,heightCell,values.luz_baja_exi.toString(), 20,{align:"center"})
  //faro piratas
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.faro, 21, {items: selectHead, value: values.faro})
  selectValue(doc, 0, 0, 55, heightCell, "", 21, {items: selectBox, value: values.faro_est})
  cell(doc,0,0,13,heightCell,"", 21,{align:"center"})
  cell(doc,0,0,13,heightCell,values.faro_exi.toString(), 21,{align:"center"})
  //luz direccional y micas
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.luz_direccional, 22, {items: selectHead, value: values.luz_direccional})
  selectValue(doc, 0, 0, 55, heightCell, "", 22, {items: selectBox, value: values.luz_direccional_est})
  cell(doc,0,0,13,heightCell,"", 22,{align:"center"})
  cell(doc,0,0,13,heightCell,values.luz_direccional_exi.toString(), 22,{align:"center"})
  //luz de freno y micas
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.luz_freno, 23, {items: selectHead, value: values.luz_freno})
  selectValue(doc, 0, 0, 55, heightCell, "", 23, {items: selectBox, value: values.luz_freno_est})
  cell(doc,0,0,13,heightCell,"", 23,{align:"center"})
  cell(doc,0,0,13,heightCell,values.luz_freno_exi.toString(), 23,{align:"center"})
  //luz de retroceso y micas
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.luz_retroceso, 24, {items: selectHead, value: values.luz_retroceso})
  selectValue(doc, 0, 0, 55, heightCell, "", 24, {items: selectBox, value: values.luz_retroceso_est})
  cell(doc,0,0,13,heightCell,"", 24,{align:"center"})
  cell(doc,0,0,13,heightCell,values.luz_retroceso_exi.toString(), 24,{align:"center"})
  //luz de placa
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.luz_placa, 25, {items: selectHead, value: values.luz_placa})
  selectValue(doc, 0, 0, 55, heightCell, "", 25, {items: selectBox, value: values.luz_placa_est})
  cell(doc,0,0,13,heightCell,"", 25,{align:"center"})
  cell(doc,0,0,13,heightCell,values.luz_placa_exi.toString(), 25,{align:"center"})
  //Faros neblineros y Micas  (I - D)
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.faros_neblineros, 26, {items: selectHead, value: values.faros_neblineros})
  selectValue(doc, 0, 0, 55, heightCell, "", 26, {items: selectBox, value: values.faros_neblineros_est})
  cell(doc,0,0,13,heightCell,"", 26,{align:"center"})
  cell(doc,0,0,13,heightCell,values.faros_neblineros_exi.toString(), 26,{align:"center"})
  //Circulina ambar asegurada al techo (switch encendido)
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.circulina_ambar, 27, {items: selectHead, value: values.circulina_ambar})
  selectValue(doc, 0, 0, 55, heightCell, "", 27, {items: selectBox, value: values.circulina_ambar_est})
  cell(doc,0,0,13,heightCell,"", 27,{align:"center"})
  cell(doc,0,0,13,heightCell,values.circulina_ambar_exi.toString(), 27,{align:"center"})
  //Alarma retroceso (switch encendido)
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.alarma_retroceso, 28, {items: selectHead, value: values.alarma_retroceso})
  selectValue(doc, 0, 0, 55, heightCell, "", 28, {items: selectBox, value: values.alarma_retroceso_est})
  cell(doc,0,0,13,heightCell,"", 28,{align:"center"})
  cell(doc,0,0,13,heightCell,values.alarma_retroceso_exi.toString(), 28,{align:"center"})
  //Espejos laterales  (I - D)
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.espejo_lateral, 29, {items: selectHead, value: values.espejo_lateral})
  selectValue(doc, 0, 0, 55, heightCell, "", 29, {items: selectBox, value: values.espejo_lateral_est})
  cell(doc,0,0,13,heightCell,"", 29,{align:"center"})
  cell(doc,0,0,13,heightCell,values.espejo_lateral_exi.toString(), 29,{align:"center"})
  //Retrovisor de cabina
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.retrovisor_cabina, 30, {items: selectHead, value: values.retrovisor_cabina})
  selectValue(doc, 0, 0, 55, heightCell, "", 30, {items: selectBox, value: values.retrovisor_cabina_est})
  cell(doc,0,0,13,heightCell,"", 30,{align:"center"})
  cell(doc,0,0,13,heightCell,values.retrovisor_cabina_exi.toString(), 30,{align:"center"})
  //Fuga/derrame de derivados de hidrocarburos
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.retrovisor_cabina, 31, {items: selectHead, value: values.retrovisor_cabina})
  selectValue(doc, 0, 0, 55, heightCell, "", 31, {items: selectBox, value: values.retrovisor_cabina_est})
  cell(doc,0,0,13,heightCell,"", 31,{align:"center"})
  cell(doc,0,0,13,heightCell,values.retrovisor_cabina_exi.toString(), 31,{align:"center"})
  //Nivel del liquido refrigerante
  cell(doc,10,42,40,heightCell,vehiculosLigeros.liquido_refri,32)
  cell(doc,0,51,51,heightCell,values.liquido_refri,32,{align:"center"})
  //Nivel de  aceite de Motor
  cell(doc,10,42,40,heightCell,vehiculosLigeros.aceite_motor,33)
  cell(doc,0,51,51,heightCell,values.aceite_motor,33,{align:"center"})
  //Nivel de  aceite de dirección (ATF)
  cell(doc,10,42,40,heightCell,vehiculosLigeros.aceite_direccion,34)
  cell(doc,0,51,51,heightCell,values.aceite_motor,34,{align:"center"})		
  //Niveles del liquido de embriague
  cell(doc,10,42,40,heightCell,vehiculosLigeros.liquido_embria,35)
  cell(doc,0,51,51,heightCell,values.aceite_motor,35,{align:"center"})	
  //Niveles del liquido freno		
  cell(doc,10,42,40,heightCell,vehiculosLigeros.liquido_freno,36)
  cell(doc,0,51,51,heightCell,values.aceite_motor,36,{align:"center"})	
  //Niveles agua limpiaparabrisa			
  cell(doc,10,42,40,heightCell,vehiculosLigeros.agua_limpiapara,37)
  cell(doc,0,51,51,heightCell,values.liquido_refri,37,{align:"center"})
  //Estado de la bateria y bornes
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.estado_bateria, 38, {items: selectHead, value: values.estado_bateria})
  selectValue(doc, 0, 0, 55, heightCell, "", 38, {items: selectBox, value: values.estado_bateria_est})
  cell(doc,0,0,13,heightCell,"", 38,{align:"center"})
  cell(doc,0,0,13,heightCell,values.estado_bateria_exi.toString(), 38,{align:"center"})
  //Tapa de combustible
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.tapa_combus, 39, {items: selectHead, value: values.tapa_combus})
  selectValue(doc, 0, 0, 55, heightCell, "", 39, {items: selectBox, value: values.tapa_combus_est})
  cell(doc,0,0,13,heightCell,"", 39,{align:"center"})
  cell(doc,0,0,13,heightCell,values.tapa_combus_exi.toString(), 39,{align:"center"})
  //Chasis y carrocería
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.chasis_carroce, 40, {items: selectHead, value: values.chasis_carroce})
  selectValue(doc, 0, 0, 55, heightCell, "", 40, {items: selectBox, value: values.chasis_carroce_est})
  cell(doc,0,0,13,heightCell,"", 40,{align:"center"})
  cell(doc,0,0,13,heightCell,values.chasis_carroce_exi.toString(), 40,{align:"center"})
  //Neumáticos (sin cortes, grietas, diagonales y aberturas)
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.neumaticos, 41, {items: selectHead, value: values.neumaticos})
  selectValue(doc, 0, 0, 55, heightCell, "", 41, {items: selectBox, value: values.neumaticos_est})
  cell(doc,0,0,13,heightCell,"", 41,{align:"center"})
  cell(doc,0,0,13,heightCell,values.neumaticos_exi.toString(), 41,{align:"center"})
  //Presión de aire de llantas
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.presion_aire, 42, {items: selectHead, value: values.presion_aire})
  selectValue(doc, 0, 0, 55, heightCell, "", 42, {items: selectBox, value: values.presion_aire_est})
  cell(doc,0,0,13,heightCell,"", 42,{align:"center"})
  cell(doc,0,0,13,heightCell,values.presion_aire_exi.toString(), 42,{align:"center"})
  //Aros (no presentan deformación)
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.aros, 43, {items: selectHead, value: values.aros})
  selectValue(doc, 0, 0, 55, heightCell, "", 43, {items: selectBox, value: values.aros_est})
  cell(doc,0,0,13,heightCell,"", 43,{align:"center"})
  cell(doc,0,0,13,heightCell,values.aros_exi.toString(), 43,{align:"center"})
  //Tuercas / esparragos alineados y marcados
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.tuercas, 44, {items: selectHead, value: values.tuercas})
  selectValue(doc, 0, 0, 55, heightCell, "", 44, {items: selectBox, value: values.tuercas_est})
  cell(doc,0,0,13,heightCell,"", 44,{align:"center"})
  cell(doc,0,0,13,heightCell,values.tuercas_exi.toString(), 44,{align:"center"})
  //Trabatuercas (4)
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.trabatuercas, 45, {items: selectHead, value: values.trabatuercas})
  selectValue(doc, 0, 0, 55, heightCell, "", 45, {items: selectBox, value: values.trabatuercas_est})
  cell(doc,0,0,13,heightCell,values.trabatuercas_req.toString(), 45,{align:"center"})
  cell(doc,0,0,13,heightCell,values.trabatuercas_exi.toString(), 45,{align:"center"})
  //Llanta de repuesto (de preferencia en la tolva)
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.llanta_repuesto, 46, {items: selectHead, value: values.llanta_repuesto})
  selectValue(doc, 0, 0, 55, heightCell, "", 46, {items: selectBox, value: values.llanta_repuesto_est})
  cell(doc,0,0,13,heightCell,values.llanta_repuesto_req.toString(), 46,{align:"center"})
  cell(doc,0,0,13,heightCell,values.llanta_repuesto_exi.toString(), 46,{align:"center"})
  //Escarpines
  selectValue(doc, 10, 42, 55, heightCell, vehiculosLigeros.escarpines, 47, {items: selectHead, value: values.escarpines})
  selectValue(doc, 0, 0, 55, heightCell, "", 47, {items: selectBox, value: values.escarpines_est})
  cell(doc,0,0,13,heightCell,"", 47,{align:"center"})
  cell(doc,0,0,13,heightCell,values.escarpines_exi.toString(), 47,{align:"center"})
  
  resetTable()
  //Siguiente tabla
  //Casco de seguridad con barbiquejo			
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.casco_seguridad, 1, {items: selectHead, value: values.casco_seguridad})
  selectValue(doc, 0, 0, 55, heightCell, "", 1, {items: selectBox, value: values.casco_seguridad_est})
  cell(doc,0,0,13,heightCell,values.casco_seguridad_req.toString(), 1,{align:"center"})
  cell(doc,0,0,13,heightCell,values.casco_seguridad_exi.toString(), 1,{align:"center"})
  //Lentes/sobrelentes de seguridad claros y oscuros
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.lentes_seguridad, 2, {items: selectHead, value: values.lentes_seguridad})
  selectValue(doc, 0, 0, 55, heightCell, "", 2, {items: selectBox, value: values.lentes_seguridad_est})
  cell(doc,0,0,13,heightCell,values.lentes_seguridad_req.toString(), 2,{align:"center"})
  cell(doc,0,0,13,heightCell,values.lentes_seguridad_exi.toString(), 2,{align:"center"})  
  //Tapón de oido
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.tapon_oido, 3, {items: selectHead, value: values.tapon_oido})
  selectValue(doc, 0, 0, 55, heightCell, "", 3, {items: selectBox, value: values.tapon_oido_est})
  cell(doc,0,0,13,heightCell,values.tapon_oido_req.toString(), 3,{align:"center"})
  cell(doc,0,0,13,heightCell,values.tapon_oido_exi.toString(), 3,{align:"center"}) 
  //Guantes: Badana 
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.guantes, 4, {items: selectHead, value: values.guantes})
  selectValue(doc, 0, 0, 55, heightCell, "", 4, {items: selectBox, value: values.guantes_est})
  cell(doc,0,0,13,heightCell,values.guantes_req.toString(), 4,{align:"center"})
  cell(doc,0,0,13,heightCell,values.guantes_exi.toString(), 4,{align:"center"})
  //Chaleco de seguridad naranja con cintas reflectivas
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.chaleco_seguridad, 5, {items: selectHead, value: values.chaleco_seguridad})
  selectValue(doc, 0, 0, 55, heightCell, "", 5, {items: selectBox, value: values.chaleco_seguridad_est})
  cell(doc,0,0,13,heightCell,values.chaleco_seguridad_req.toString(), 5,{align:"center"})
  cell(doc,0,0,13,heightCell,values.chaleco_seguridad_exi.toString(), 5,{align:"center"})
  //Mascarilla descartable KN95
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.mascarilla, 6, {items: selectHead, value: values.mascarilla})
  selectValue(doc, 0, 0, 55, heightCell, "", 6, {items: selectBox, value: values.mascarilla_est})
  cell(doc,0,0,13,heightCell,values.mascarilla_req.toString(), 6,{align:"center"})
  cell(doc,0,0,13,heightCell,values.mascarilla_exi.toString(), 6,{align:"center"})
  //Respirador de media cara con cartuchos P100
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.respirador, 7, {items: selectHead, value: values.respirador})
  selectValue(doc, 0, 0, 55, heightCell, "", 7, {items: selectBox, value: values.respirador_est})
  cell(doc,0,0,13,heightCell,values.respirador_req.toString(), 7,{align:"center"})
  cell(doc,0,0,13,heightCell,values.respirador_exi.toString(), 7,{align:"center"})
  //Zapatos de seguridad con punta reforzada
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.zapato_seguridad, 8, {items: selectHead, value: values.zapato_seguridad})
  selectValue(doc, 0, 0, 55, heightCell, "", 8, {items: selectBox, value: values.zapato_seguridad_est})
  cell(doc,0,0,13,heightCell,values.zapato_seguridad_req.toString(), 8,{align:"center"})
  cell(doc,0,0,13,heightCell,values.zapato_seguridad_exi.toString(), 8,{align:"center"})
  //DNI
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.dni, 9, {items: selectHead, value: values.dni})
  selectValue(doc, 0, 0, 55, heightCell, "", 9, {items: selectBox, value: values.dni_est})
  cell(doc,0,0,13,heightCell,values.dni_req.toString(), 9,{align:"center"})
  cell(doc,0,0,13,heightCell,values.dni_exi.toString(), 9,{align:"center"})
  //Licencia MTC:  AI  (    )   AII  (    )
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.licencia, 10, {items: selectHead, value: values.licencia})
  selectValue(doc, 0, 0, 55, heightCell, "", 10, {items: selectBox, value: values.licencia_est})
  cell(doc,0,0,13,heightCell,values.licencia_req.toString(), 10,{align:"center"})
  cell(doc,0,0,13,heightCell,values.licencia_exi.toString(), 10,{align:"center"})
  //Fotocheck:  Empresa (    )
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.fotocheck, 11, {items: selectHead, value: values.fotocheck})
  selectValue(doc, 0, 0, 55, heightCell, "", 11, {items: selectBox, value: values.fotocheck_est})
  cell(doc,0,0,13,heightCell,values.fotocheck_req.toString(), 11,{align:"center"})
  cell(doc,0,0,13,heightCell,values.fotocheck_exi.toString(), 11,{align:"center"})
  //Certificación Matpel Nivel: I (    )   II (    )   III (    )
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.certificacion, 12, {items: selectHead, value: values.licencia})
  selectValue(doc, 0, 0, 55, heightCell, "", 12, {items: selectBox, value: values.certificacion_est})
  cell(doc,0,0,13,heightCell,values.certificacion_req.toString(), 12,{align:"center"})
  cell(doc,0,0,13,heightCell,values.certificacion_exi.toString(), 12,{align:"center"})
  //Pase medico
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.pase_medico, 13, {items: selectHead, value: values.pase_medico})
  selectValue(doc, 0, 0, 55, heightCell, "", 13, {items: selectBox, value: values.pase_medico_est})
  cell(doc,0,0,13,heightCell,values.pase_medico_req.toString(), 13,{align:"center"})
  cell(doc,0,0,13,heightCell,values.pase_medico_exi.toString(), 13,{align:"center"})
  //SCTR  (Salud y Pensión)
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.scrt, 14, {items: selectHead, value: values.scrt})
  selectValue(doc, 0, 0, 55, heightCell, "", 14, {items: selectBox, value: values.scrt_est})
  cell(doc,0,0,13,heightCell,values.scrt_req.toString(), 14,{align:"center"})
  cell(doc,0,0,13,heightCell,values.scrt_exi.toString(), 14,{align:"center"})
  //Certificación de identificación vehicular (Tarjeta propiedad)
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.certifi_identi, 15, {items: selectHead, value: values.certifi_identi})
  selectValue(doc, 0, 0, 55, heightCell, "", 15, {items: selectBox, value: values.certifi_identi_est})
  cell(doc,0,0,13,heightCell,values.certifi_identi_req.toString(), 15,{align:"center"})
  cell(doc,0,0,13,heightCell,values.certifi_identi_exi.toString(), 15,{align:"center"})
  //Certificado inspección técnica vehicular
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.certifi_inspec, 16, {items: selectHead, value: values.certifi_inspec})
  selectValue(doc, 0, 0, 55, heightCell, "", 16, {items: selectBox, value: values.certifi_inspec_est})
  cell(doc,0,0,13,heightCell,values.certifi_inspec_req.toString(), 16,{align:"center"})
  cell(doc,0,0,13,heightCell,values.certifi_inspec_exi.toString(), 16,{align:"center"})
  //SOAT
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.soat, 17, {items: selectHead, value: values.soat})
  selectValue(doc, 0, 0, 55, heightCell, "", 17, {items: selectBox, value: values.soat_est})
  cell(doc,0,0,13,heightCell,values.soat_req.toString(), 17,{align:"center"})
  cell(doc,0,0,13,heightCell,values.soat_exi.toString(), 17,{align:"center"})
  //Constancia GPS
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.constancia, 18, {items: selectHead, value: values.constancia})
  selectValue(doc, 0, 0, 55, heightCell, "", 18, {items: selectBox, value: values.constancia_est})
  cell(doc,0,0,13,heightCell,values.constancia_req.toString(), 18,{align:"center"})
  cell(doc,0,0,13,heightCell,values.constancia_exi.toString(), 18,{align:"center"})
  //Plan de Preparación y Respuestas
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.preparacion, 19, {items: selectHead, value: values.preparacion})
  selectValue(doc, 0, 0, 55, heightCell, "", 19, {items: selectBox, value: values.preparacion_est})
  cell(doc,0,0,13,heightCell,values.preparacion_req.toString(), 19,{align:"center"})
  cell(doc,0,0,13,heightCell,values.preparacion_exi.toString(), 19,{align:"center"})
  //Cartilla teléfonos de emergencia
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.cartilla_telef, 20, {items: selectHead, value: values.cartilla_telef})
  selectValue(doc, 0, 0, 55, heightCell, "", 20, {items: selectBox, value: values.cartilla_telef_est})
  cell(doc,0,0,13,heightCell,values.cartilla_telef_req.toString(), 20,{align:"center"})
  cell(doc,0,0,13,heightCell,values.cartilla_telef_exi.toString(), 20,{align:"center"})
  //Hoja de resumen de seguridad (SDS)
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.hoja_resumen, 21, {items: selectHead, value: values.hoja_resumen})
  selectValue(doc, 0, 0, 55, heightCell, "", 21, {items: selectBox, value: values.hoja_resumen_est})
  cell(doc,0,0,13,heightCell,values.hoja_resumen_req.toString(), 21,{align:"center"})
  cell(doc,0,0,13,heightCell,values.hoja_resumen_exi.toString(), 21,{align:"center"})
  //Hoja de ruta y plan de viaje (punto de control)
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.hoja_ruta, 22, {items: selectHead, value: values.hoja_ruta})
  selectValue(doc, 0, 0, 55, heightCell, "", 22, {items: selectBox, value: values.hoja_ruta_est})
  cell(doc,0,0,13,heightCell,values.hoja_ruta_req.toString(), 22,{align:"center"})
  cell(doc,0,0,13,heightCell,values.hoja_ruta_exi.toString(), 22,{align:"center"})
  //Poliza seguro veh. (RC)
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.poliza_seguro, 23, {items: selectHead, value: values.poliza_seguro})
  selectValue(doc, 0, 0, 55, heightCell, "", 23, {items: selectBox, value: values.poliza_seguro_est})
  cell(doc,0,0,13,heightCell,values.poliza_seguro_req.toString(), 23,{align:"center"})
  cell(doc,0,0,13,heightCell,values.poliza_seguro_exi.toString(), 23,{align:"center"})
  //PET de transporte de concentrado de molibdeno
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.pet_transporte, 24, {items: selectHead, value: values.pet_transporte})
  selectValue(doc, 0, 0, 55, heightCell, "", 24, {items: selectBox, value: values.pet_transporte_est})
  cell(doc,0,0,13,heightCell,values.pet_transporte_req.toString(), 24,{align:"center"})
  cell(doc,0,0,13,heightCell,values.pet_transporte_exi.toString(), 24,{align:"center"})
  //Pala con mango de plástico
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.pala, 25, {items: selectHead, value: values.pala})
  selectValue(doc, 0, 0, 55, heightCell, "", 25, {items: selectBox, value: values.pala_est})
  cell(doc,0,0,13,heightCell,values.pala_req.toString(), 25,{align:"center"})
  cell(doc,0,0,13,heightCell,values.pala_exi.toString(), 25,{align:"center"})
  //Pico con buje plástico
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.pico, 26, {items: selectHead, value: values.pico})
  selectValue(doc, 0, 0, 55, heightCell, "", 26, {items: selectBox, value: values.pico_est})
  cell(doc,0,0,13,heightCell,values.pico_req.toString(), 26,{align:"center"})
  cell(doc,0,0,13,heightCell,values.pico_exi.toString(), 26,{align:"center"})
  //Cable Pasacorriente
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.cable_pasacorri, 27, {items: selectHead, value: values.cable_pasacorri})
  selectValue(doc, 0, 0, 55, heightCell, "", 27, {items: selectBox, value: values.pico_est})
  cell(doc,0,0,13,heightCell,values.cable_pasacorri_req.toString(), 27,{align:"center"})
  cell(doc,0,0,13,heightCell,values.cable_pasacorri_exi.toString(), 27,{align:"center"})
  //Kit herramintas basicos
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.kit_herrami, 28, {items: selectHead, value: values.kit_herrami})
  selectValue(doc, 0, 0, 55, heightCell, "", 28, {items: selectBox, value: values.kit_herrami_est})
  cell(doc,0,0,13,heightCell,values.kit_herrami_req.toString(), 28,{align:"center"})
  cell(doc,0,0,13,heightCell,values.kit_herrami_exi.toString(), 28,{align:"center"})
  //Multiplicador de torque para rueda
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.multiplicador, 29, {items: selectHead, value: values.multiplicador})
  selectValue(doc, 0, 0, 55, heightCell, "", 29, {items: selectBox, value: values.multiplicador_est})
  cell(doc,0,0,13,heightCell,values.multiplicador_req.toString(), 29,{align:"center"})
  cell(doc,0,0,13,heightCell,values.multiplicador_exi.toString(), 29,{align:"center"})
  //bara luminosa (roja y verde)
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.bara_lumi, 30, {items: selectHead, value: values.bara_lumi})
  selectValue(doc, 0, 0, 55, heightCell, "", 30, {items: selectBox, value: values.bara_lumi_est})
  cell(doc,0,0,13,heightCell,values.bara_lumi_req.toString(), 30,{align:"center"})
  cell(doc,0,0,13,heightCell,values.bara_lumi_exi.toString(), 30,{align:"center"})
  //Medidor de presión del neumático (Aire)
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.medidor_presion, 31, {items: selectHead, value: values.medidor_presion})
  selectValue(doc, 0, 0, 55, heightCell, "", 31, {items: selectBox, value: values.medidor_presion_est})
  cell(doc,0,0,13,heightCell,values.medidor_presion_req.toString(), 31,{align:"center"})
  cell(doc,0,0,13,heightCell,values.medidor_presion_exi.toString(), 31,{align:"center"})
  //Gata
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.gata, 32, {items: selectHead, value: values.gata})
  selectValue(doc, 0, 0, 55, heightCell, "", 32, {items: selectBox, value: values.gata_est})
  cell(doc,0,0,13,heightCell,values.gata_req.toString(), 32,{align:"center"})
  cell(doc,0,0,13,heightCell,values.gata_exi.toString(), 32,{align:"center"})
  //Palancas y llave de rueda
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.palancas, 33, {items: selectHead, value: values.palancas})
  selectValue(doc, 0, 0, 55, heightCell, "", 33, {items: selectBox, value: values.palancas_est})
  cell(doc,0,0,13,heightCell,values.palancas_req.toString(), 33,{align:"center"})
  cell(doc,0,0,13,heightCell,values.palancas_exi.toString(), 33,{align:"center"})
  //Triangulo de Seguridad
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.triangulo_segu, 34, {items: selectHead, value: values.triangulo_segu})
  selectValue(doc, 0, 0, 55, heightCell, "", 34, {items: selectBox, value: values.triangulo_segu_est})
  cell(doc,0,0,13,heightCell,values.triangulo_segu_req.toString(), 34,{align:"center"})
  cell(doc,0,0,13,heightCell,values.triangulo_segu_exi.toString(), 34,{align:"center"})
  //Linterna
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.linterna, 35, {items: selectHead, value: values.linterna})
  selectValue(doc, 0, 0, 55, heightCell, "", 35, {items: selectBox, value: values.linterna_est})
  cell(doc,0,0,13,heightCell,values.linterna_req.toString(), 35,{align:"center"})
  cell(doc,0,0,13,heightCell,values.linterna_exi.toString(), 35,{align:"center"})
  //Paletas (PARE Y SIGA)
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.paletas, 36, {items: selectHead, value: values.paletas})
  selectValue(doc, 0, 0, 55, heightCell, "", 36, {items: selectBox, value: values.paletas_est})
  cell(doc,0,0,13,heightCell,values.paletas_req.toString(), 36,{align:"center"})
  cell(doc,0,0,13,heightCell,values.paletas_exi.toString(), 36,{align:"center"})
  //Botiquín y Check list
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.boquitin, 37, {items: selectHead, value: values.boquitin})
  selectValue(doc, 0, 0, 55, heightCell, "", 37, {items: selectBox, value: values.boquitin_est})
  cell(doc,0,0,13,heightCell,values.boquitin_req.toString(), 37,{align:"center"})
  cell(doc,0,0,13,heightCell,values.boquitin_exi.toString(), 37,{align:"center"})
  //Extintor Exterior 6Kg - PQS
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.extintor, 38, {items: selectHead, value: values.extintor})
  selectValue(doc, 0, 0, 55, heightCell, "", 38, {items: selectBox, value: values.extintor_est})
  cell(doc,0,0,13,heightCell,values.extintor_req.toString(), 38,{align:"center"})
  cell(doc,0,0,13,heightCell,values.extintor_exi.toString(), 38,{align:"center"})
  //Cono de Seguridad de 70 cm con 2 cintas reflectivas
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.cono_seguridad, 39, {items: selectHead, value: values.cono_seguridad})
  selectValue(doc, 0, 0, 55, heightCell, "", 39, {items: selectBox, value: values.cono_seguridad_est})
  cell(doc,0,0,13,heightCell,values.cono_seguridad_req.toString(), 39,{align:"center"})
  cell(doc,0,0,13,heightCell,values.cono_seguridad_exi.toString(), 39,{align:"center"})
  //Taco/cuña de fibra con agarradera
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.taco_cuña, 40, {items: selectHead, value: values.taco_cuña})
  selectValue(doc, 0, 0, 55, heightCell, "", 40, {items: selectBox, value: values.taco_cuña_est})
  cell(doc,0,0,13,heightCell,values.taco_cuña_req.toString(), 40,{align:"center"})
  cell(doc,0,0,13,heightCell,values.taco_cuña_exi.toString(), 40,{align:"center"})
  //Kit Contra Derrames y Check list
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.kit_derrame, 41, {items: selectHead, value: values.kit_derrame})
  selectValue(doc, 0, 0, 55, heightCell, "", 41, {items: selectBox, value: values.kit_derrame_est})
  cell(doc,0,0,13,heightCell,values.kit_derrame_req.toString(), 41,{align:"center"})
  cell(doc,0,0,13,heightCell,values.kit_derrame_exi.toString(), 41,{align:"center"})
  //Radio base comunicación
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.radio_base, 42, {items: selectHead, value: values.radio_base})
  selectValue(doc, 0, 0, 55, heightCell, "", 42, {items: selectBox, value: values.radio_base_est})
  cell(doc,0,0,13,heightCell,values.radio_base_req.toString(), 42,{align:"center"})
  cell(doc,0,0,13,heightCell,values.radio_base_exi.toString(), 42,{align:"center"})
  //Antena de Autoradio y Radio de comunicaciones
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.antena_autoradio, 43, {items: selectHead, value: values.antena_autoradio})
  selectValue(doc, 0, 0, 55, heightCell, "", 43, {items: selectBox, value: values.antena_autoradio_est})
  cell(doc,0,0,13,heightCell,values.antena_autoradio_req.toString(), 43,{align:"center"})
  cell(doc,0,0,13,heightCell,values.antena_autoradio_exi.toString(), 43,{align:"center"})
  //Barra antivuelco interna (ROPS)
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.barra_interno, 44, {items: selectHead, value: values.barra_interno})
  selectValue(doc, 0, 0, 55, heightCell, "", 44, {items: selectBox, value: values.barra_interno_est})
  cell(doc,0,0,13,heightCell,values.barra_interno_req.toString(), 44,{align:"center"})
  cell(doc,0,0,13,heightCell,values.barra_interno_exi.toString(), 44,{align:"center"})
  //Barra antivuelco externa
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.barra_externo, 45, {items: selectHead, value: values.barra_externo})
  selectValue(doc, 0, 0, 55, heightCell, "", 45, {items: selectBox, value: values.barra_externo_est})
  cell(doc,0,0,13,heightCell,values.barra_externo_req.toString(), 45,{align:"center"})
  cell(doc,0,0,13,heightCell,values.barra_externo_exi.toString(), 45,{align:"center"})
  //Letrero de convoy con juego de Numeración del 1 al 10
  selectValue(doc, 110, 38, 55, heightCell, vehiculosLigeros.letrero_numera, 46, {items: selectHead, value: values.letrero_numera})
  selectValue(doc, 0, 0, 55, heightCell, "", 46, {items: selectBox, value: values.letrero_numera_est})
  cell(doc,0,0,13,heightCell,values.letrero_numera_req.toString(), 46,{align:"center"})
  cell(doc,0,0,13,heightCell,values.letrero_numera_exi.toString(), 46,{align:"center"})
  doc.addPage()
  resetTable()
   //texto del medio
  doc.setFontSize(8);
  doc.text("Marcar los cuadros si el neumaticos tiene cocada con un minimo de 3mm/ Solo las camionetas escoltas contaran con 2 Circulinas", 100, 10, {align:"center"});
  //agregacion de la imagen
  doc.addImage(vehiculosligerosCars, "PNG", 5, 13,195,30);
  resetTable()
  cell(doc, 77, 43, 2, 2, "", 1)
  resetTable()
  cell(doc, 97, 43, 2, 2, "", 1)
  resetTable()
  cell(doc, 105, 43, 2, 2, "", 1)
  resetTable()
  cell(doc, 126, 43, 2, 2, "", 1)
  for(let coc of values.cocadas){
    if(coc === "neumaticofi"){
      doc.text("x", 77, 45)
    }
    if(coc === "neumaticofd"){
      doc.text("x", 97, 45)
    }
    if(coc === "neumaticoti"){
      doc.text("x", 105, 45)
    }
    if(coc === "neumaticotd"){
      doc.text("x", 126, 45)
    }
  }
  resetTable()
  cell(doc,5, 50, 110,12, "OBSERVACIONES: " + values.observaciones, 1);
  resetTable()
  cell(doc, 115, 50, 90,heightCell, "TANQUE DE COMBUSTIBLE", 1, {color: "#1e81b0", align :"center"});
  cell(doc,115, 50, 18,heightCell, "E", 2, {align :"center"});
  cell(doc,115, 50, 18,heightCell, "1/4", 2, {align :"center"});
  cell(doc,115, 50, 18,heightCell, "1/2", 2, {align :"center"});
  cell(doc,115, 50, 18,heightCell, "3/4", 2, {align :"center"});
  cell(doc,115, 50, 18,heightCell, "F", 2, {align :"center"});
  setSelectValueOptionWidth(18)
  selectValue(doc, 115, 0, 90, heightCell, "", 3, {items: ["SI", "uncuarto", "medio", "trescuartos", "full"], value: values.combustible})
  resetTable()
  cell(doc, 5, 62, 20, 8, "PASAJEROS", 1, {align: "center", color: "#1e81b0"})
  resetTable()
  cell(doc, 25, 62, 90, heightCell, "1 " + values.pasajero_uno, 1)
  cell(doc, 25, 62, 90, heightCell, "2 " + values.pasajero_dos, 1)
  cell(doc, 25, 62, 90, heightCell, "3 " + values.pasajero_tres, 2)
  cell(doc, 25, 62, 90, heightCell, "4 " + values.pasajero_cuatro, 2)
  resetTable()
  cell(doc, 5, 70, 200, heightCell, "DEJO CONSTANCIA DEL ESTADO DE MI UNIDAD Y ASUMO LA RESPONSABILIDAD CON SEGURIDAD", 1, {align: "center", color: "#1e81b0"})
  resetTable()
  cell(doc, 5, 74, 45, heightCell, "NOMBRE DEL CONDUCTOR:", 1, {color: "#1e81b0"})
  cell(doc, 5, 74, 55, heightCell, values.conductor, 1)
  resetTable()
  cell(doc, 5, 78, 100, 20, "", 1)
  doc.text("FIRMA", 7, 80, {baseline: "top"})
  doc.addImage(values.firma, "PNG", 50, 80, 23, 17)

  let currentY = 74
  for(let sup in values.supervisores){
    const user = await getWorkerData(values.supervisores[sup].nombre)
    if(values[values.supervisores[sup].nombre] !== undefined){
      resetTable()
      cell(doc, 105, currentY, 45, heightCell, "NOMBRE DEL INSPECTOR:", 1, {color: "#1e81b0"})
      cell(doc, 105, currentY, 55, heightCell, user.name, 1)
      resetTable()
      cell(doc, 105, currentY + 4, 100, 20, "", 1)
      doc.text("FIRMA", 107, currentY + 6, {baseline: "top"})
      doc.addImage(user.firma, "PNG", 152, currentY + 6, 23, 17)
    } else {
      resetTable()
      cell(doc, 105, currentY, 45, heightCell, "NOMBRE DEL INSPECTOR:", 1, {color: "#1e81b0"})
      cell(doc, 105, currentY, 55, heightCell, user.name, 1)
      resetTable()
      cell(doc, 105, currentY + 4, 100, 20, "", 1)
      doc.text("FIRMA", 107, currentY + 6, {baseline: "top"})
    }
    currentY += 24
  }

  window.open(doc.output("bloburl"), "_blank");  
}