import React, { useState } from "react";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import "./ubicacion.css";
import { Modal } from "react-bootstrap";
import { Button, ButtonWrapper } from "../FrontComponents/Buttons";
import { Title } from "../FrontComponents/TextType";
import mapboxgl from "mapbox-gl";
/* import Map, { Marker } from "react-map-gl";
import maplibregl from "maplibre-gl"; */
const Mubication = ({ coordinates, show, onHide }) => {
  console.log(coordinates.longitud);
  const coords = [];
  coordinates.longitud &&
    coords.push(coordinates.longitud, coordinates.latitud);
  console.log(coords);
  const mapDiv = useRef(null);
  useLayoutEffect(() => {
    if (coordinates.longitud) {
      const geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coords,
            },
            properties: {
              title: "Mapbox",
              description: "Washington, D.C.",
            },
          },
        ],
      };
      const map = new mapboxgl.Map({
        container: mapDiv.current, // container ID
        style: "mapbox://styles/77fer1997/cl1v0ygt9002115qnvp592jib", // style URL
        center: coords, // starting position [lng, lat]

        zoom: 16, // starting zoom
      });
      for (const feature of geojson.features) {
        // create a HTML element for each feature
        const el = document.createElement("div");
        el.className = "marker";

        // make a marker for each feature and add to the map
        new mapboxgl.Marker(el)
          .setLngLat(feature.geometry.coordinates)
          .addTo(map);
      }
    }
  }, []);
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      {console.log(coordinates)}
      <Modal.Header>
        <Title modal>Ubicación:</Title>
      </Modal.Header>
      <Modal.Body>
        {coordinates.longitud ? (
          <div ref={mapDiv} style={{ height: "500px", width: "100% " }}></div>
        ) : (
          "Esta respuesta no tiene ubicación"
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Mubication;
