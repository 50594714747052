import React from "react";
import { Modal } from "react-bootstrap";
import { Button, ButtonWrapper } from "../FrontComponents/Buttons";
import {
  FormMKStyle,
  FormikStyled,
  Input,
} from "../FrontComponents/StyledFormik";
import { Title } from "../FrontComponents/TextType";
import * as Yup from "yup";
const validate = Yup.object({
  name: Yup.string().required(),
});
const Marea = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} size="lg" centered>
      <Modal.Header>
        <Title modal>{props.edit ? "Editar Área" : "Agregar Área"}</Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "1rem 2rem" }}>
        <FormikStyled
          initialValues={
            props.edit
              ? {}
              : {
                  name: "",
                }
          }
          validationSchema={validate}
          onSubmit={props.onSubmit}
        >
          {(formik) => (
            <FormMKStyle>
              <Input name="name" type="text">
                Nombre
              </Input>
              <ButtonWrapper center>
                <Button type="submit">Aceptar</Button>
              </ButtonWrapper>
            </FormMKStyle>
          )}
        </FormikStyled>
      </Modal.Body>
    </Modal>
  );
};

export default Marea;
