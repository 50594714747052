import React, { useState, useEffect } from "react";
import {
  Formik,
  FieldArray,
  Field,
  Form as FormMK,
  ErrorMessage,
  FastField,
} from "formik";
import * as Yup from "yup";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import Tarjeta from "../components/Tarjeta";
import ErrorM from "../components/ErrorMessage";
import { Input } from "../components/FrontComponents/StyledFormik";
import { Subtitle } from "../components/FrontComponents/TextType";
import { DeleteButton } from "../components/FrontComponents/ActionButtons";
import Camioneta from "../assets/Desktop.png";
import Firm from "../components/firm/firm";
import { Redirect } from "react-router-dom";
import { getWorkersData } from "../services/databaseData";
import { useCurrentPosition } from "../hooks/useCurrentPosition";
import {
  uploadVehiculosLigeros,
  sendNotification,
} from "../services/uploadToDatabase";
import Toast from "../components/toast";
import { getUserData } from "../services/userData";
import { ActionButton } from "../components/FrontComponents/ActionButtons/actionbutton";

const initialData = {
  placa: "",
  kilometraje: "",
  parabri_delantero: "",
  parabri_delantero_est: "",
  parabri_delantero_req: 1,
  parabri_delantero_exi: "",
  ventana_lateral: "",
  ventana_lateral_est: "",
  ventana_lateral_req: 4,
  ventana_lateral_exi: "",
  parabri_trasero: "",
  parabri_trasero_est: "",
  parabri_trasero_req: 1,
  parabri_trasero_exi: "",
  cabina: "",
  cabina_est: "",
  cabina_req: 0,
  cabina_exi: "",
  asiento_apoyacabeza: "",
  asiento_apoyacabeza_est: "",
  asiento_apoyacabeza_req: 5,
  asiento_apoyacabeza_exi: "",
  funda: "",
  funda_est: "",
  funda_req: 0,
  funda_exi: "",
  cinturon_seguridad: "",
  cinturon_seguridad_est: "",
  cinturon_seguridad_req: 5,
  cinturon_seguridad_exi: "",
  air_bag: "",
  air_bag_est: "",
  air_bag_req: 2,
  air_bag_exi: "",
  encendido_motor: "",
  encendido_motor_est: "",
  encendido_motor_req: 0,
  encendido_motor_exi: "",
  frenos_servicios: "",
  frenos_servicios_est: "",
  frenos_servicios_req: 0,
  frenos_servicios_exi: "",
  direccion: "",
  direccion_est: "",
  direccion_req: 0,
  direccion_exi: "",
  funcionamiento_indicador: "",
  funcionamiento_indicador_est: "",
  funcionamiento_indicador_req: 0,
  funcionamiento_indicador_exi: "",
  claxon: "",
  claxon_est: "",
  claxon_req: 0,
  claxon_exi: "",
  limpiaparabrisas: "",
  limpiaparabrisas_est: "",
  limpiaparabrisas_req: 0,
  limpiaparabrisas_exi: "",
  aire_acondicionado: "",
  aire_acondicionado_est: "",
  aire_acondicionado_req: 0,
  aire_acondicionado_exi: "",
  autoradio: "",
  autoradio_est: "",
  autoradio_req: 0,
  autoradio_exi: "",
  encendedor: "",
  encendedor_est: "",
  encendedor_req: 0,
  encendedor_exi: "",
  tapasol: "",
  tapasol_est: "",
  tapasol_req: 0,
  tapasol_exi: "",
  pisos: "",
  pisos_est: "",
  pisos_req: 0,
  pisos_exi: "",
  luz_baja: "",
  luz_baja_est: "",
  luz_baja_req: 0,
  luz_baja_exi: "",
  faro: "",
  faro_est: "",
  faro_req: 0,
  faro_exi: "",
  luz_direccional: "",
  luz_direccional_est: "",
  luz_direccional_req: 0,
  luz_direccional_exi: "",
  luz_freno: "",
  luz_freno_est: "",
  luz_freno_req: 0,
  luz_freno_exi: "",
  luz_retroceso: "",
  luz_retroceso_est: "",
  luz_retroceso_req: 0,
  luz_retroceso_exi: "",
  luz_placa: "",
  luz_placa_est: "",
  luz_placa_req: 0,
  luz_placa_exi: "",
  faros_neblineros: "",
  faros_neblineros_est: "",
  faros_neblineros_req: 0,
  faros_neblineros_exi: "",
  circulina_ambar: "",
  circulina_ambar_est: "",
  circulina_ambar_req: 2,
  circulina_ambar_exi: "",
  alarma_retroceso: "",
  alarma_retroceso_est: "",
  alarma_retroceso_req: 0,
  alarma_retroceso_exi: "",
  espejo_lateral: "",
  espejo_lateral_est: "",
  espejo_lateral_req: 0,
  espejo_lateral_exi: "",
  retrovisor_cabina: "",
  retrovisor_cabina_est: "",
  retrovisor_cabina_req: 0,
  retrovisor_cabina_exi: "",
  fuga_derivado: "",
  fuga_derivado_est: "",
  fuga_derivado_req: 0,
  fuga_derivado_exi: "",
  liquido_refri: "",
  aceite_motor: "",
  aceite_direccion: "",
  liquido_embria: "",
  liquido_freno: "",
  agua_limpiapara: "",
  estado_bateria: "",
  estado_bateria_est: "",
  estado_bateria_req: 0,
  estado_bateria_exi: "",
  tapa_combus: "",
  tapa_combus_est: "",
  tapa_combus_req: 0,
  tapa_combus_exi: "",
  chasis_carroce: "",
  chasis_carroce_est: "",
  chasis_carroce_req: 0,
  chasis_carroce_exi: "",
  neumaticos: "",
  neumaticos_est: "",
  neumaticos_req: 0,
  neumaticos_exi: "",
  presion_aire: "",
  presion_aire_est: "",
  presion_aire_req: 0,
  presion_aire_exi: "",
  aros: "",
  aros_est: "",
  aros_req: 0,
  aros_exi: "",
  tuercas: "",
  tuercas_est: "",
  tuercas_req: 0,
  tuercas_exi: "",
  trabatuercas: "",
  trabatuercas_est: "",
  trabatuercas_req: 4,
  trabatuercas_exi: "",
  llanta_repuesto: "",
  llanta_repuesto_est: "",
  llanta_repuesto_req: 1,
  llanta_repuesto_exi: "",
  escarpines: "",
  escarpines_est: "",
  escarpines_req: 0,
  escarpines_exi: "",
  //Creación del siguiente formulario
  casco_seguridad: "",
  casco_seguridad_est: "",
  casco_seguridad_req: 1,
  casco_seguridad_exi: "",
  lentes_seguridad: "",
  lentes_seguridad_est: "",
  lentes_seguridad_req: 2,
  lentes_seguridad_exi: "",
  tapon_oido: "",
  tapon_oido_est: "",
  tapon_oido_req: 1,
  tapon_oido_exi: "",
  guantes: "",
  guantes_est: "",
  guantes_req: 1,
  guantes_exi: "",
  chaleco_seguridad: "",
  chaleco_seguridad_est: "",
  chaleco_seguridad_req: 1,
  chaleco_seguridad_exi: "",
  mascarilla: "",
  mascarilla_est: "",
  mascarilla_req: 1,
  mascarilla_exi: "",
  respirador: "",
  respirador_est: "",
  respirador_req: 1,
  respirador_exi: "",
  zapato_seguridad: "",
  zapato_seguridad_est: "",
  zapato_seguridad_req: 1,
  zapato_seguridad_exi: "",
  dni: "",
  dni_est: "",
  dni_req: 1,
  dni_exi: "",
  licencia: "",
  licencia_est: "",
  licencia_req: 1,
  licencia_exi: "",
  fotocheck: "",
  fotocheck_est: "",
  fotocheck_req: 1,
  fotocheck_exi: "",
  certificacion: "",
  certificacion_est: "",
  certificacion_req: 1,
  certificacion_exi: "",
  pase_medico: "",
  pase_medico_est: "",
  pase_medico_req: 1,
  pase_medico_exi: "",
  scrt: "",
  scrt_est: "",
  scrt_req: 1,
  scrt_exi: "",
  certifi_identi: "",
  certifi_identi_est: "",
  certifi_identi_req: 1,
  certifi_identi_exi: "",
  certifi_inspec: "",
  certifi_inspec_est: "",
  certifi_inspec_req: 1,
  certifi_inspec_exi: "",
  soat: "",
  soat_est: "",
  soat_req: 1,
  soat_exi: "",
  constancia: "",
  constancia_est: "",
  constancia_req: 1,
  constancia_exi: "",
  preparacion: "",
  preparacion_est: "",
  preparacion_req: 1,
  preparacion_exi: "",
  cartilla_telef: "",
  cartilla_telef_est: "",
  cartilla_telef_req: 1,
  cartilla_telef_exi: "",
  hoja_resumen: "",
  hoja_resumen_est: "",
  hoja_resumen_req: 1,
  hoja_resumen_exi: "",
  hoja_ruta: "",
  hoja_ruta_est: "",
  hoja_ruta_req: 1,
  hoja_ruta_exi: "",
  poliza_seguro: "",
  poliza_seguro_est: "",
  poliza_seguro_req: 1,
  poliza_seguro_exi: "",
  pet_transporte: "",
  pet_transporte_est: "",
  pet_transporte_req: 1,
  pet_transporte_exi: "",
  pala: "",
  pala_est: "",
  pala_req: 1,
  pala_exi: "",
  pico: "",
  pico_est: "",
  pico_req: 1,
  pico_exi: "",
  cable_pasacorri: "",
  cable_pasacorri_est: "",
  cable_pasacorri_req: 1,
  cable_pasacorri_exi: "",
  kit_herrami: "",
  kit_herrami_est: "",
  kit_herrami_req: 1,
  kit_herrami_exi: "",
  multiplicador: "",
  multiplicador_est: "",
  multiplicador_req: 1,
  multiplicador_exi: "",
  bara_lumi: "",
  bara_lumi_est: "",
  bara_lumi_req: 2,
  bara_lumi_exi: "",
  medidor_presion: "",
  medidor_presion_est: "",
  medidor_presion_req: 1,
  medidor_presion_exi: "",
  gata: "",
  gata_est: "",
  gata_req: 1,
  gata_exi: "",
  palancas: "",
  palancas_est: "",
  palancas_req: 3,
  palancas_exi: "",
  triangulo_segu: "",
  triangulo_segu_est: "",
  triangulo_segu_req: 1,
  triangulo_segu_exi: "",
  linterna: "",
  linterna_est: "",
  linterna_req: 1,
  linterna_exi: "",
  paletas: "",
  paletas_est: "",
  paletas_req: 2,
  paletas_exi: "",
  boquitin: "",
  boquitin_est: "",
  boquitin_req: 1,
  boquitin_exi: "",
  extintor: "",
  extintor_est: "",
  extintor_req: 1,
  extintor_exi: "",
  cono_seguridad: "",
  cono_seguridad_est: "",
  cono_seguridad_req: 2,
  cono_seguridad_exi: "",
  taco_cuña: "",
  taco_cuña_est: "",
  taco_cuña_req: 2,
  taco_cuña_exi: "",
  kit_derrame: "",
  kit_derrame_est: "",
  kit_derrame_req: 1,
  kit_derrame_exi: "",
  radio_base: "",
  radio_base_est: "",
  radio_base_req: 1,
  radio_base_exi: "",
  antena_autoradio: "",
  antena_autoradio_est: "",
  antena_autoradio_req: 2,
  antena_autoradio_exi: "",
  barra_interno: "",
  barra_interno_est: "",
  barra_interno_req: 1,
  barra_interno_exi: "",
  barra_externo: "",
  barra_externo_est: "",
  barra_externo_req: 1,
  barra_externo_exi: "",
  letrero_numera: "",
  letrero_numera_est: "",
  letrero_numera_req: 1,
  letrero_numera_exi: "",
  cocadas: "",
  observaciones: "",
  combustible: "",
  pasajero_uno: "",
  pasajero_dos: "",
  pasajero_tres: "",
  pasajero_cuatro: "",
  conductor: "",
  firma: "",
  supervisores: [{ nombre: "" }],
};

const validate = Yup.object({
  placa: Yup.string().required("El campo es necesario llenarlo"),
  kilometraje: Yup.string().required("El campo es necesario llenarlo"),
  parabri_delantero: Yup.string().required("El campo es necesario llenarlo"),
  parabri_delantero_est: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  parabri_delantero_req: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  parabri_delantero_exi: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  ventana_lateral: Yup.string().required("El campo es necesario llenarlo"),
  ventana_lateral_est: Yup.string().required("El campo es necesario"),
  ventana_lateral_req: Yup.string().required("El campo es necesario"),
  ventana_lateral_exi: Yup.string().required("El campo es necesario"),
  parabri_trasero: Yup.string().required("El campo es necesario llenarlo"),
  parabri_trasero_est: Yup.string().required("El campo es necesario"),
  parabri_trasero_req: Yup.string().required("El campo es necesario"),
  parabri_trasero_exi: Yup.string().required("El campo es necesario"),
  cabina: Yup.string().required("El campo es necesario llenarlo"),
  cabina_est: Yup.string().required("El campo es necesario"),
  cabina_req: Yup.string().required("El campo es necesario"),
  asiento_apoyacabeza: Yup.string().required("El campo es necesario llenarlo"),
  asiento_apoyacabeza_est: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  asiento_apoyacabeza_req: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  asiento_apoyacabeza_exi: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  funda: Yup.string().required("El campo es necesario llenarlo"),
  funda_est: Yup.string().required("El campo es necesario"),
  funda_req: Yup.string().required("El campo es necesario"),
  cinturon_seguridad: Yup.string().required(
    "El campo cinturon de seguridad es necesario llenarlo"
  ),
  cinturon_seguridad_est: Yup.number().required(
    "El campo cinturon de seguridad es necesario llenarlo"
  ),
  cinturon_seguridad_req: Yup.number().required(
    "El campo cinturon de seguridad es necesario llenarlo"
  ),
  cinturon_seguridad_exi: Yup.number().required(
    "El campo cinturon de seguridad es necesario llenarlo"
  ),
  air_bag: Yup.string().required("El campo es necesario llenarlo"),
  air_bag_est: Yup.string().required("El campo es necesario"),
  air_bag_req: Yup.string().required("El campo es necesario"),
  air_bag_exi: Yup.string().required("El campo es necesario"),
  encendido_motor: Yup.string().required("El campo es necesario llenarlo"),
  encendido_motor_est: Yup.string().required("El campo es necesario"),
  encendido_motor_req: Yup.string().required("El campo es necesario"),
  frenos_servicios: Yup.string().required("El campo es necesario llenarlo"),
  frenos_servicios_est: Yup.string().required("El campo es necesario"),
  frenos_servicios_req: Yup.string().required("El campo es necesario"),
  direccion: Yup.string().required("El campo es necesario llenarlo"),
  direccion_est: Yup.string().required("El campo es necesario"),
  direccion_req: Yup.string().required("El campo es necesario"),
  funcionamiento_indicador: Yup.string().required(
    "El campo es necesario llenarlo"
  ),
  funcionamiento_indicador_est: Yup.string().required(
    "El campo es necesario llenarlo"
  ),
  funcionamiento_indicador_req: Yup.string().required(
    "El campo es necesario llenarlo"
  ),
  claxon: Yup.string().required("El campo es necesario llenarlo"),
  claxon_est: Yup.string().required("El campo es necesario"),
  claxon_req: Yup.string().required("El campo es necesario"),
  limpiaparabrisas: Yup.string().required("El campo es necesario llenarlo"),
  limpiaparabrisas_est: Yup.string().required("El campo es necesario"),
  limpiaparabrisas_req: Yup.string().required("El campo es necesario"),
  aire_acondicionado: Yup.string().required("El campo es necesario llenarlo"),
  aire_acondicionado_est: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  aire_acondicionado_req: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  autoradio: Yup.string().required("El campo es necesario llenarlo"),
  autoradio_est: Yup.string().required("El campo es necesario"),
  autoradio_req: Yup.string().required("El campo es necesario"),
  encendedor: Yup.string().required("El campo es necesario llenarlo"),
  encendedor_est: Yup.string().required("El campo es necesario"),
  encendedor_req: Yup.string().required("El campo es necesario"),
  tapasol: Yup.string().required("El campo es necesario llenarlo"),
  tapasol_est: Yup.string().required("El campo es necesario"),
  tapasol_req: Yup.string().required("El campo es necesario"),
  pisos: Yup.string().required("El campo es necesario llenarlo"),
  pisos_est: Yup.string().required("El campo es necesario"),
  pisos_req: Yup.string().required("El campo es necesario"),
  luz_baja: Yup.string().required("El campo es necesario llenarlo"),
  luz_baja_est: Yup.string().required("El campo es necesario"),
  luz_baja_req: Yup.string().required("El campo es necesario"),
  faro: Yup.string().required("El campo es necesario llenarlo"),
  faro_est: Yup.string().required("El campo es necesario"),
  faro_req: Yup.string().required("El campo es necesario"),
  luz_direccional: Yup.string().required("El campo es necesario llenarlo"),
  luz_direccional_est: Yup.string().required("El campo es necesario"),
  luz_direccional_req: Yup.string().required("El campo es necesario"),
  luz_freno: Yup.string().required("El campo es necesario llenarlo"),
  luz_freno_est: Yup.string().required("El campo es necesario"),
  luz_freno_req: Yup.string().required("El campo es necesario"),
  luz_retroceso: Yup.string().required("El campo es necesario llenarlo"),
  luz_retroceso_est: Yup.string().required("El campo es necesario"),
  luz_retroceso_req: Yup.string().required("El campo es necesario"),
  luz_placa: Yup.string().required("El campo es necesario llenarlo"),
  luz_placa_est: Yup.string().required("El campo es necesario"),
  luz_placa_req: Yup.string().required("El campo es necesario"),
  faros_neblineros: Yup.string().required("El campo es necesario llenarlo"),
  faros_neblineros_est: Yup.string().required("El campo es necesario"),
  faros_neblineros_req: Yup.string().required("El campo es necesario"),
  circulina_ambar: Yup.string().required("El campo es necesario llenarlo"),
  circulina_ambar_est: Yup.string().required("El campo es necesario"),
  circulina_ambar_req: Yup.string().required("El campo es necesario"),
  circulina_ambar_exi: Yup.string().required("El campo es necesario"),
  alarma_retroceso: Yup.string().required("El campo es necesario llenarlo"),
  alarma_retroceso_est: Yup.string().required("El campo es necesario"),
  alarma_retroceso_req: Yup.string().required("El campo es necesario"),
  espejo_lateral: Yup.string().required("El campo es necesario llenarlo"),
  espejo_lateral_est: Yup.string().required("El campo es necesario"),
  espejo_lateral_req: Yup.string().required("El campo es necesario"),
  retrovisor_cabina: Yup.string().required("El campo es necesario llenarlo"),
  retrovisor_cabina_est: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  retrovisor_cabina_req: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  fuga_derivado: Yup.string().required("El campo es necesario llenarlo"),
  fuga_derivado_est: Yup.string().required("El campo es necesario"),
  fuga_derivado_req: Yup.string().required("El campo es necesario"),
  liquido_refri: Yup.string().required("El campo es necesario llenarlo"),

  aceite_motor: Yup.string().required("El campo es necesario llenarlo"),

  aceite_direccion: Yup.string().required("El campo es necesario llenarlo"),

  liquido_embria: Yup.string().required("El campo es necesario llenarlo"),

  liquido_freno: Yup.string().required("El campo es necesario llenarlo"),

  agua_limpiapara: Yup.string().required("El campo es necesario llenarlo"),

  estado_bateria: Yup.string().required("El campo es necesario llenarlo"),
  estado_bateria_est: Yup.string().required("El campo es necesario"),
  estado_bateria_req: Yup.string().required("El campo es necesario"),
  tapa_combus: Yup.string().required("El campo es necesario llenarlo"),
  tapa_combus_est: Yup.string().required("El campo es necesario"),
  tapa_combus_req: Yup.string().required("El campo es necesario"),
  chasis_carroce: Yup.string().required("El campo es necesario llenarlo"),
  chasis_carroce_est: Yup.string().required("El campo es necesario"),
  chasis_carroce_req: Yup.string().required("El campo es necesario"),
  neumaticos: Yup.string().required("El campo es necesario llenarlo"),
  neumaticos_est: Yup.string().required("El campo es necesario"),
  neumaticos_req: Yup.string().required("El campo es necesario"),
  presion_aire: Yup.string().required("El campo es necesario llenarlo"),
  presion_aire_est: Yup.string().required("El campo es necesario"),
  presion_aire_req: Yup.string().required("El campo es necesario"),
  aros: Yup.string().required("El campo es necesario llenarlo"),
  aros_est: Yup.string().required("El campo es necesario"),
  aros_req: Yup.string().required("El campo es necesario"),
  tuercas: Yup.string().required("El campo es necesario llenarlo"),
  tuercas_est: Yup.string().required("El campo es necesario"),
  tuercas_req: Yup.string().required("El campo es necesario"),
  trabatuercas: Yup.string().required("El campo es necesario llenarlo"),
  trabatuercas_est: Yup.string().required("El campo es necesario"),
  trabatuercas_req: Yup.string().required("El campo es necesario"),
  trabatuercas_exi: Yup.string().required("El campo es necesario"),
  llanta_repuesto: Yup.string().required("El campo es necesario llenarlo"),
  llanta_repuesto_est: Yup.string().required("El campo es necesario"),
  llanta_repuesto_req: Yup.string().required("El campo es necesario"),
  llanta_repuesto_exi: Yup.string().required("El campo es necesario"),
  escarpines: Yup.string().required("El campo es necesario llenarlo"),
  escarpines_est: Yup.string().required("El campo es necesario"),
  escarpines_req: Yup.string().required("El campo es necesario"),
  casco_seguridad: Yup.string().required("El campo es necesario llenarlo"),
  casco_seguridad_est: Yup.string().required("El campo es necesario"),
  casco_seguridad_req: Yup.string().required("El campo es necesario"),
  casco_seguridad_exi: Yup.string().required("El campo es necesario"),
  lentes_seguridad: Yup.string().required("El campo es necesario llenarlo"),
  lentes_seguridad_est: Yup.string().required("El campo es necesario"),
  lentes_seguridad_req: Yup.string().required("El campo es necesario"),
  lentes_seguridad_exi: Yup.string().required("El campo es necesario"),
  tapon_oido: Yup.string().required("El campo es necesario llenarlo"),
  tapon_oido_est: Yup.string().required("El campo es necesario"),
  tapon_oido_req: Yup.string().required("El campo es necesario"),
  tapon_oido_exi: Yup.string().required("El campo es necesario"),
  guantes: Yup.string().required("El campo es necesario llenarlo"),
  guantes_est: Yup.string().required("El campo es necesario"),
  guantes_req: Yup.string().required("El campo es necesario"),
  guantes_exi: Yup.string().required("El campo es necesario"),
  chaleco_seguridad: Yup.string().required("El campo es necesario llenarlo"),
  chaleco_seguridad_est: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  chaleco_seguridad_req: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  chaleco_seguridad_exi: Yup.number().required(
    "El campo es necesario llenarlo"
  ),
  mascarilla: Yup.string().required("El campo es necesario llenarlo"),
  mascarilla_est: Yup.string().required("El campo es necesario"),
  mascarilla_req: Yup.string().required("El campo es necesario"),
  mascarilla_exi: Yup.string().required("El campo es necesario"),
  respirador: Yup.string().required("El campo es necesario llenarlo"),
  respirador_est: Yup.string().required("El campo es necesario"),
  respirador_req: Yup.string().required("El campo es necesario"),
  respirador_exi: Yup.string().required("El campo es necesario"),
  zapato_seguridad: Yup.string().required("El campo es necesario llenarlo"),
  zapato_seguridad_est: Yup.string().required("El campo es necesario"),
  zapato_seguridad_req: Yup.string().required("El campo es necesario"),
  zapato_seguridad_exi: Yup.string().required("El campo es necesario"),
  dni: Yup.string().required("El campo es necesario llenarlo"),
  dni_est: Yup.string().required("El campo es necesario"),
  dni_req: Yup.string().required("El campo es necesario"),
  dni_exi: Yup.string().required("El campo es necesario"),
  licencia: Yup.string().required("El campo es necesario llenarlo"),
  licencia_est: Yup.string().required("El campo es necesario"),
  licencia_req: Yup.string().required("El campo es necesario"),
  licencia_exi: Yup.string().required("El campo es necesario"),
  fotocheck: Yup.string().required("El campo es necesario llenarlo"),
  fotocheck_est: Yup.string().required("El campo es necesario"),
  fotocheck_req: Yup.string().required("El campo es necesario"),
  fotocheck_exi: Yup.string().required("El campo es necesario"),
  certificacion: Yup.string().required("El campo es necesario llenarlo"),
  certificacion_est: Yup.string().required("El campo es necesario"),
  certificacion_req: Yup.string().required("El campo es necesario"),
  certificacion_exi: Yup.string().required("El campo es necesario"),
  pase_medico: Yup.string().required("El campo es necesario llenarlo"),
  pase_medico_est: Yup.string().required("El campo es necesario"),
  pase_medico_req: Yup.string().required("El campo es necesario"),
  pase_medico_exi: Yup.string().required("El campo es necesario"),
  scrt: Yup.string().required("El campo es necesario llenarlo"),
  scrt_est: Yup.string().required("El campo es necesario"),
  scrt_req: Yup.string().required("El campo es necesario"),
  scrt_exi: Yup.string().required("El campo es necesario"),
  certifi_identi: Yup.string().required("El campo es necesario llenarlo"),
  certifi_identi_est: Yup.string().required("El campo es necesario"),
  certifi_identi_req: Yup.string().required("El campo es necesario"),
  certifi_identi_exi: Yup.string().required("El campo es necesario"),
  certifi_inspec: Yup.string().required("El campo es necesario llenarlo"),
  certifi_inspec_est: Yup.string().required("El campo es necesario"),
  certifi_inspec_req: Yup.string().required("El campo es necesario"),
  certifi_inspec_exi: Yup.string().required("El campo es necesario"),
  soat: Yup.string().required("El campo es necesario llenarlo"),
  soat_est: Yup.string().required("El campo es necesario"),
  soat_req: Yup.string().required("El campo es necesario"),
  soat_exi: Yup.string().required("El campo es necesario"),
  constancia: Yup.string().required("El campo es necesario llenarlo"),
  constancia_est: Yup.string().required("El campo es necesario"),
  constancia_req: Yup.string().required("El campo es necesario"),
  constancia_exi: Yup.string().required("El campo es necesario"),
  preparacion: Yup.string().required("El campo es necesario llenarlo"),
  preparacion_est: Yup.string().required("El campo es necesario"),
  preparacion_req: Yup.string().required("El campo es necesario"),
  preparacion_exi: Yup.string().required("El campo es necesario"),
  cartilla_telef: Yup.string().required("El campo es necesario llenarlo"),
  cartilla_telef_est: Yup.string().required("El campo es necesario"),
  cartilla_telef_req: Yup.string().required("El campo es necesario"),
  cartilla_telef_exi: Yup.string().required("El campo es necesario"),
  hoja_resumen: Yup.string().required("El campo es necesario llenarlo"),
  hoja_resumen_est: Yup.string().required("El campo es necesario"),
  hoja_resumen_req: Yup.string().required("El campo es necesario"),
  hoja_resumen_exi: Yup.string().required("El campo es necesario"),
  hoja_ruta: Yup.string().required("El campo es necesario llenarlo"),
  hoja_ruta_est: Yup.string().required("El campo es necesario"),
  hoja_ruta_req: Yup.string().required("El campo es necesario"),
  hoja_ruta_exi: Yup.string().required("El campo es necesario"),
  poliza_seguro: Yup.string().required("El campo es necesario llenarlo"),
  poliza_seguro_est: Yup.string().required("El campo es necesario"),
  poliza_seguro_req: Yup.string().required("El campo es necesario"),
  poliza_seguro_exi: Yup.string().required("El campo es necesario"),
  pet_transporte: Yup.string().required("El campo es necesario llenarlo"),
  pet_transporte_est: Yup.string().required("El campo es necesario"),
  pet_transporte_req: Yup.string().required("El campo es necesario"),
  pet_transporte_exi: Yup.string().required("El campo es necesario"),
  pala: Yup.string().required("El campo es necesario llenarlo"),
  pala_est: Yup.string().required("El campo es necesario"),
  pala_req: Yup.string().required("El campo es necesario"),
  pala_exi: Yup.string().required("El campo es necesario"),
  pico: Yup.string().required("El campo es necesario llenarlo"),
  pico_est: Yup.string().required("El campo es necesario"),
  pico_req: Yup.string().required("El campo es necesario"),
  pico_exi: Yup.string().required("El campo es necesario"),
  cable_pasacorri: Yup.string().required("El campo es necesario llenarlo"),
  cable_pasacorri_est: Yup.string().required("El campo es necesario"),
  cable_pasacorri_req: Yup.string().required("El campo es necesario"),
  cable_pasacorri_exi: Yup.string().required("El campo es necesario"),
  kit_herrami: Yup.string().required("El campo es necesario llenarlo"),
  kit_herrami_est: Yup.string().required("El campo es necesario"),
  kit_herrami_req: Yup.string().required("El campo es necesario"),
  kit_herrami_exi: Yup.string().required("El campo es necesario"),
  multiplicador: Yup.string().required("El campo es necesario llenarlo"),
  multiplicador_est: Yup.string().required("El campo es necesario"),
  multiplicador_req: Yup.string().required("El campo es necesario"),
  multiplicador_exi: Yup.string().required("El campo es necesario"),
  bara_lumi: Yup.string().required("El campo es necesario llenarlo"),
  bara_lumi_est: Yup.string().required("El campo es necesario"),
  bara_lumi_req: Yup.string().required("El campo es necesario"),
  bara_lumi_exi: Yup.string().required("El campo es necesario"),
  medidor_presion: Yup.string().required("El campo es necesario llenarlo"),
  medidor_presion_est: Yup.string().required("El campo es necesario"),
  medidor_presion_req: Yup.string().required("El campo es necesario"),
  medidor_presion_exi: Yup.string().required("El campo es necesario"),
  gata: Yup.string().required("El campo es necesario llenarlo"),
  gata_est: Yup.string().required("El campo es necesario"),
  gata_req: Yup.string().required("El campo es necesario"),
  gata_exi: Yup.string().required("El campo es necesario"),
  palancas: Yup.string().required("El campo es necesario llenarlo"),
  palancas_est: Yup.string().required("El campo es necesario"),
  palancas_req: Yup.string().required("El campo es necesario"),
  palancas_exi: Yup.string().required("El campo es necesario"),
  triangulo_segu: Yup.string().required("El campo es necesario llenarlo"),
  triangulo_segu_est: Yup.string().required("El campo es necesario"),
  triangulo_segu_req: Yup.string().required("El campo es necesario"),
  triangulo_segu_exi: Yup.string().required("El campo es necesario"),
  linterna: Yup.string().required("El campo es necesario llenarlo"),
  linterna_est: Yup.string().required("El campo es necesario"),
  linterna_req: Yup.string().required("El campo es necesario"),
  linterna_exi: Yup.string().required("El campo es necesario"),
  paletas: Yup.string().required("El campo es necesario llenarlo"),
  paletas_est: Yup.string().required("El campo es necesario"),
  paletas_req: Yup.string().required("El campo es necesario"),
  paletas_exi: Yup.string().required("El campo es necesario"),
  boquitin: Yup.string().required("El campo es necesario llenarlo"),
  boquitin_est: Yup.string().required("El campo es necesario"),
  boquitin_req: Yup.string().required("El campo es necesario"),
  boquitin_exi: Yup.string().required("El campo es necesario"),
  extintor: Yup.string().required("El campo es necesario llenarlo"),
  extintor_est: Yup.string().required("El campo es necesario"),
  extintor_req: Yup.string().required("El campo es necesario"),
  extintor_exi: Yup.string().required("El campo es necesario"),
  cono_seguridad: Yup.string().required("El campo es necesario llenarlo"),
  cono_seguridad_est: Yup.string().required("El campo es necesario"),
  cono_seguridad_req: Yup.string().required("El campo es necesario"),
  cono_seguridad_exi: Yup.string().required("El campo es necesario"),
  taco_cuña: Yup.string().required("El campo es necesario llenarlo"),
  taco_cuña_est: Yup.string().required("El campo es necesario"),
  taco_cuña_req: Yup.string().required("El campo es necesario"),
  taco_cuña_exi: Yup.string().required("El campo es necesario"),
  kit_derrame: Yup.string().required("El campo es necesario llenarlo"),
  kit_derrame_est: Yup.string().required("El campo es necesario"),
  kit_derrame_req: Yup.string().required("El campo es necesario"),
  kit_derrame_exi: Yup.string().required("El campo es necesario"),
  radio_base: Yup.string().required("El campo es necesario llenarlo"),
  radio_base_est: Yup.string().required("El campo es necesario"),
  radio_base_req: Yup.string().required("El campo es necesario"),
  radio_base_exi: Yup.string().required("El campo es necesario"),
  antena_autoradio: Yup.string().required("El campo es necesario llenarlo"),
  antena_autoradio_est: Yup.string().required("El campo es necesario"),
  antena_autoradio_req: Yup.string().required("El campo es necesario"),
  antena_autoradio_exi: Yup.string().required("El campo es necesario"),
  barra_interno: Yup.string().required("El campo es necesario llenarlo"),
  barra_interno_est: Yup.string().required("El campo es necesario"),
  barra_interno_req: Yup.string().required("El campo es necesario"),
  barra_interno_exi: Yup.string().required("El campo es necesario"),
  barra_externo: Yup.string().required("El campo es necesario llenarlo"),
  barra_externo_est: Yup.string().required("El campo es necesario"),
  barra_externo_req: Yup.string().required("El campo es necesario"),
  barra_externo_exi: Yup.string().required("El campo es necesario"),
  letrero_numera: Yup.string().required("El campo es necesario llenarlo"),
  letrero_numera_est: Yup.string().required("El campo es necesario"),
  letrero_numera_req: Yup.string().required("El campo es necesario"),
  letrero_numera_exi: Yup.string().required("El campo es necesario"),
  cocadas: Yup.array().min(1, "Debe marcar al menos un recuadro"),
  observaciones: Yup.string().required("El campo es necesario llenarlo"),
  combustible: Yup.string().required("El campo es necesario llenarlo"),
  pasajero_uno: Yup.string().required("El campo es necesario llenarlo"),
  pasajero_dos: Yup.string().required("El campo es necesario llenarlo"),
  pasajero_tres: Yup.string().required("El campo es necesario llenarlo"),
  pasajero_cuatro: Yup.string().required("El campo es necesario llenarlo"),
  conductor: Yup.string().required("El campo es necesario llenarlo"),
  firma: Yup.string().required("El campo es necesario llenarlo"),
  supervisores: Yup.array().of(
    Yup.object().shape({
      nombre: Yup.string().required("El campo es necesario llenarlo"),
    })
  ),
});

const VehiculosLigeros = () => {
  const [supervisores, setSupervisores] = useState({});
  const [upload, setUpload] = useState(false);
  const [latitud, longitud] = useCurrentPosition(0);
  const handleSubmit = async (values) => {
    const userData = getUserData();
    values.latitud = latitud;
    values.longitud = longitud;
    values.formKey = "VehiculosLigeros";
    values.createDate = Date.now();
    values.userId = userData.uid;
    const data = await uploadVehiculosLigeros(values);
    if (data.error === "") {
      sendNotification(values.supervisores, data.key);
      Toast("Información subida Correctamente", "success");
      setUpload(true);
    } else {
      console.log(data.error);
    }
  };

  useEffect(() => {
    const getDataUser = async () => {
      const workersData = await getWorkersData();
      setSupervisores(workersData);
    };
    getDataUser();
  }, []);

  return (
    <Container style={{ backgroundColor: "#a3dfff" }} className="p-3">
      {upload ? <Redirect to="/subido" /> : null}
      <Formik
        initialValues={initialData}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={validate}
        validateOnChange= {false}
      >
        {({ setFieldValue, values }) => (
          <FormMK>
            <Tarjeta>
              <h2
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                CHECK-LIST VEHÍCULOS LIVIANOS CAMIONETA
              </h2>
            </Tarjeta>
            <Tarjeta>
              {/* <ErrorMessage name="parabri_delantero_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage> */}
              <Input name="placa" type="text">
                Placa:
              </Input>
            </Tarjeta>
            <Tarjeta>
              {/* <ErrorMessage name="parabri_delantero_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage> */}
              <Input name="kilometraje" type="text">
                Kilometraje:
              </Input>
            </Tarjeta>
            <Tarjeta>
              <h2
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                Unidad
              </h2>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Palabrisas Delantero: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Palabrisas Delantero? </Subtitle>
              <Row>
                <Col>
                  <FastField
                    inline
                    as={Form.Check}
                    name="parabri_delantero"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <FastField
                    inline
                    as={Form.Check}
                    name="parabri_delantero"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <FastField
                    inline
                    as={Form.Check}
                    name="parabri_delantero"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="parabri_delantero" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <FastField
                    inline
                    as={Form.Check}
                    name="parabri_delantero_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <FastField
                    inline
                    as={Form.Check}
                    name="parabri_delantero_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="parabri_delantero_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="parabri_delantero_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="parabri_delantero_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Ventana Lateral: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Ventana Lateral? </Subtitle>
              <Row>
                <Col>
                  <FastField
                    inline
                    as={Form.Check}
                    name="ventana_lateral"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <FastField
                    inline
                    as={Form.Check}
                    name="ventana_lateral"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <FastField
                    inline
                    as={Form.Check}
                    name="ventana_lateral"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="ventana_lateral" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <FastField
                    inline
                    as={Form.Check}
                    name="ventana_lateral_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <FastField
                    inline
                    as={Form.Check}
                    name="ventana_lateral_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="ventana_lateral_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="ventana_lateral_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="ventana_lateral_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>Palabrisas Trasero: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Palabrisas Trasero?: </Subtitle>
              <Row>
                <Col>
                  <FastField
                    inline
                    as={Form.Check}
                    name="parabri_trasero"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <FastField
                    inline
                    as={Form.Check}
                    name="parabri_trasero"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <FastField
                    inline
                    as={Form.Check}
                    name="parabri_trasero"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="parabri_trasero" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <FastField
                    inline
                    as={Form.Check}
                    name="parabri_trasero_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <FastField
                    inline
                    as={Form.Check}
                    name="parabri_trasero_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="parabri_trasero_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="parabri_trasero_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="parabri_trasero_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>Cabina ordenada y limpia: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Cabina ordenada y limpia? </Subtitle>
              <Row>
                <Col>
                  <FastField
                    inline
                    as={Form.Check}
                    name="cabina"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <FastField
                    inline
                    as={Form.Check}
                    name="cabina"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <FastField
                    inline
                    as={Form.Check}
                    name="cabina"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="cabina" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="cabina_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="cabina_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="cabina_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="cabina_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="cabina_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>Asientos con Apoya Cabezas: </Subtitle>
              <br></br>
              <Subtitle>¿Tiene Asientos con Apoya Cabezas? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="asiento_apoyacabeza"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="asiento_apoyacabeza"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="asiento_apoyacabeza"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="asiento_apoyacabeza" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="asiento_apoyacabeza_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="asiento_apoyacabeza_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="asiento_apoyacabeza_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="asiento_apoyacabeza_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="asiento_apoyacabeza_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>Fundas de Asientos: </Subtitle>
              <br></br>
              <Subtitle>¿Tiene Fundas de Asientos? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="funda"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="funda"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="funda"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="funda" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="funda_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="funda_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="funda_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="funda_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="funda_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Cinturones de Seguridad: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Cinturones de Seguridad? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="cinturon_seguridad"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="cinturon_seguridad"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="cinturon_seguridad"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="cinturon_seguridad" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="cinturon_seguridad_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="cinturon_seguridad_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="cinturon_seguridad_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="cinturon_seguridad_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="cinturon_seguridad_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Air Bag (bolsa de aire) para cada asiento delantero:
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Air Bag (bolsa de aire) para cada asiento delantero?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="air_bag"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="air_bag"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="air_bag"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="air_bag" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="air_bag_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="air_bag_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="air_bag_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="air_bag_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="air_bag_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Encendido y Apagado del motor: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Encendido y Apagado del motor? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="encendido_motor"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="encendido_motor"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="encendido_motor"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="encendido_motor" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="encendido_motor_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="encendido_motor_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="encendido_motor_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="encendido_motor_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="encendido_motor_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>Frenos de servicio/parqueo (motor encendido):</Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Frenos de servicio/parqueo (motor encendido)?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="frenos_servicios"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="frenos_servicios"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="frenos_servicios"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="frenos_servicios" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="frenos_servicios_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="frenos_servicios_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="frenos_servicios_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="frenos_servicios_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="frenos_servicios_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Dirección (motor encendido): </Subtitle>
              <br></br>
              <Subtitle>¿Tiene Dirección (motor encendido)?</Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="direccion"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="direccion"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="direccion"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="direccion" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="direccion_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="direccion_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="direccion_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="direccion_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="direccion_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Funcionamiento de Indicadores de alerta en el tablero:
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Funcionamiento de Indicadores de alerta en el tablero?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="funcionamiento_indicador"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="funcionamiento_indicador"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="funcionamiento_indicador"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="funcionamiento_indicador" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="funcionamiento_indicador_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="funcionamiento_indicador_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage
                    name="funcionamiento_indicador_est"
                    as={Subtitle}
                  >
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input
                    disabled
                    name="funcionamiento_indicador_req"
                    type="text"
                  >
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="funcionamiento_indicador_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Cláxon Eléctrico: </Subtitle>
              <br></br>
              <Subtitle>¿Tiene Cláxon Eléctrico?</Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="claxon"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="claxon"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="claxon"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="claxon" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="claxon_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="claxon_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="claxon_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="claxon_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="claxon_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Sistema limpiaparabrisas (bomba de agua, brazo y plumilla):
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Sistema limpiaparabrisas (bomba de agua, brazo y
                plumilla)?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="limpiaparabrisas"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="limpiaparabrisas"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="limpiaparabrisas"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="limpiaparabrisas" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="limpiaparabrisas_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="limpiaparabrisas_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="limpiaparabrisas_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="limpiaparabrisas_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="limpiaparabrisas_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>Sistema de aire acondicionado y calefacción:</Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Sistema de aire acondicionado y calefacción?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="aire_acondicionado"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="aire_acondicionado"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="aire_acondicionado"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="aire_acondicionado" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="aire_acondicionado_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="aire_acondicionado_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="aire_acondicionado_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="aire_acondicionado_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="aire_acondicionado_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Autoradio: </Subtitle>
              <br></br>
              <Subtitle>¿Tiene Autoradio? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="autoradio"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="autoradio"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="autoradio"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="autoradio" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="autoradio_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="autoradio_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="autoradio_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="autoradio_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="autoradio_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Encendedor/Ceniceros: </Subtitle>
              <br></br>
              <Subtitle>¿Tiene Encendedor/Ceniceros? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="encendedor"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="encendedor"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="encendedor"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="encendedor" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="encendedor_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="encendedor_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="encendedor_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="encendedor_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="encendedor_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Tapasol(I-D): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Tapasol? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tapasol"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="tapasol"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tapasol"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="tapasol" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tapasol_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tapasol_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="tapasol_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="tapasol_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="tapasol_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Pisos: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Pisos? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="pisos"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="pisos"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pisos"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="pisos" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="pisos_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pisos_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="pisos_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="pisos_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="pisos_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Luz baja/alta de Faro delantero y Micas (I-D):
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Luz baja/alta de Faro delantero y Micas?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_baja"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="luz_baja"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_baja"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="luz_baja" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_baja_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_baja_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="luz_baja_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="luz_baja_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="luz_baja_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Faro(s) Pirata(s): </Subtitle>
              <br></br>
              <Subtitle>¿Tiene Faro(s) Pirata(s)?</Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="faro"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="faro"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="faro"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="faro" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="faro_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="faro_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="faro_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="faro_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="faro_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Luz direccional y micas (I-D): </Subtitle>
              <br></br>
              <Subtitle>¿Tiene Luz direccional y micas? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_direccional"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_direccional"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_direccional"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="luz_direccional" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_direccional_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_direccional_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="luz_direccional_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="luz_direccional_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="luz_direccional_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Luz de freno y micas (I-D): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Luz de freno y micas ? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_freno"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_freno"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_freno"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="luz_freno" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_freno_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_freno_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="luz_freno_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="luz_freno_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="luz_freno_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Luz de retroceso y Micas (I-D): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Luz de retroceso y Micas? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_retroceso"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="luz_retroceso"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_retroceso"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="luz_retroceso" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_retroceso_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_retroceso_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="luz_retroceso_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="luz_retroceso_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="luz_retroceso_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Luz de Placa: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Luz de Placa?</Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_placa"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_placa"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_placa"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="luz_placa" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_placa_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="luz_placa_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="luz_placa_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="luz_placa_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="luz_placa_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Faros neblineros y Micas (I-D): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Faros neblineros y Micas? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="faros_neblineros"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="faros_neblineros"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="faros_neblineros"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="faros_neblineros" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="faros_neblineros_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="faros_neblineros_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="faros_neblineros_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="faros_neblineros_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="faros_neblineros_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Circulina ambar asegurada al techo (switch encendido):
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Circulina ambar asegurada al techo (switch encendido)?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="circulina_ambar"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="circulina_ambar"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="circulina_ambar"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="circulina_ambar" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="circulina_ambar_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="circulina_ambar_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="circulina_ambar_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="circulina_ambar_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="circulina_ambar_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Alarma retroceso (switch encendido): </Subtitle>
              <br></br>
              <Subtitle>¿Tiene Alarma retroceso (switch encendido)? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="alarma_retroceso"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="alarma_retroceso"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="alarma_retroceso"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="alarma_retroceso" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="alarma_retroceso_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="alarma_retroceso_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="alarma_retroceso_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="alarma_retroceso_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="alarma_retroceso_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Espejos Laterales (I-D): </Subtitle>
              <br></br>
              <Subtitle>¿Tiene Espejos Laterales? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="espejo_lateral"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="espejo_lateral"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="espejo_lateral"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="espejo_lateral" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="espejo_lateral_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="espejo_lateral_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="espejo_lateral_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="espejo_lateral_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="espejo_lateral_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Retrovisor de Cabina: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Retrovisor de Cabina? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="retrovisor_cabina"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="retrovisor_cabina"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="retrovisor_cabina"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="retrovisor_cabina" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="retrovisor_cabina_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="retrovisor_cabina_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="retrovisor_cabina_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="retrovisor_cabina_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="retrovisor_cabina_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Fuga/Derrame de derivados de Hidrocarburos: </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Fuga/Derrame de derivados de Hidrocarburos?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="fuga_derivado"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="fuga_derivado"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="fuga_derivado"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="fuga_derivado" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="fuga_derivado_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="fuga_derivado_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="fuga_derivado_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="fuga_derivado_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="fuga_derivado_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Nivel del liquido refrigerante: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Nivel del liquido refrigerante? </Subtitle>
              <Field
                name="liquido_refri"
                type="text"
                as="select"
                className="form-select"
              >
                <option value="">Seleccionar</option>
                <option value="0%">0%</option>
                <option value="25%">25%</option>
                <option value="50%">50%</option>
                <option value="75%">75%</option>
                <option value="100%">100%</option>
              </Field>
              <ErrorMessage name="liquido_refri" as={Subtitle}>
                {(message) => <ErrorM>{message}</ErrorM>}
              </ErrorMessage>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Nivel de aceite de Motor: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Nivel de aceite de Motor? </Subtitle>
              <Field
                name="aceite_motor"
                type="text"
                as="select"
                className="form-select"
              >
                <option value="">Seleccionar</option>
                <option value="0%">0%</option>
                <option value="25%">25%</option>
                <option value="50%">50%</option>
                <option value="75%">75%</option>
                <option value="100%">100%</option>
              </Field>
              <ErrorMessage name="aceite_motor" as={Subtitle}>
                {(message) => <ErrorM>{message}</ErrorM>}
              </ErrorMessage>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Nivel de aceite de dirección (ATF): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Nivel de aceite de dirección (ATF)? </Subtitle>
              <Field
                name="aceite_direccion"
                type="text"
                as="select"
                className="form-select"
              >
                <option value="">Seleccionar</option>
                <option value="0%">0%</option>
                <option value="25%">25%</option>
                <option value="50%">50%</option>
                <option value="75%">75%</option>
                <option value="100%">100%</option>
              </Field>
              <ErrorMessage name="aceite_direccion" as={Subtitle}>
                {(message) => <ErrorM>{message}</ErrorM>}
              </ErrorMessage>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Niveles del liquido de Embriague: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Niveles del liquido de Embriague? </Subtitle>
              <Field
                name="liquido_embria"
                type="text"
                as="select"
                className="form-select"
              >
                <option value="">Seleccionar</option>
                <option value="0%">0%</option>
                <option value="25%">25%</option>
                <option value="50%">50%</option>
                <option value="75%">75%</option>
                <option value="100%">100%</option>
              </Field>
              <ErrorMessage name="liquido_embria" as={Subtitle}>
                {(message) => <ErrorM>{message}</ErrorM>}
              </ErrorMessage>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Niveles del liquido Freno: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Niveles del liquido Freno? </Subtitle>
              <Field
                name="liquido_freno"
                type="text"
                as="select"
                className="form-select"
              >
                <option value="">Seleccionar</option>
                <option value="0%">0%</option>
                <option value="25%">25%</option>
                <option value="50%">50%</option>
                <option value="75%">75%</option>
                <option value="100%">100%</option>
              </Field>
              <ErrorMessage name="liquido_freno" as={Subtitle}>
                {(message) => <ErrorM>{message}</ErrorM>}
              </ErrorMessage>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Niveles agua Limpiaparabrisa: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Nivel agua Limpiaparabrisa? </Subtitle>
              <Field
                name="agua_limpiapara"
                type="text"
                as="select"
                className="form-select"
              >
                <option value="">Seleccionar</option>
                <option value="0%">0%</option>
                <option value="25%">25%</option>
                <option value="50%">50%</option>
                <option value="75%">75%</option>
                <option value="100%">100%</option>
              </Field>
              <ErrorMessage name="agua_limpiapara" as={Subtitle}>
                {(message) => <ErrorM>{message}</ErrorM>}
              </ErrorMessage>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Estado de la bateria y bornes: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Estado de la bateria y bornes? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_bateria"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="estado_bateria"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_bateria"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="estado_bateria" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_bateria_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="estado_bateria_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="estado_bateria_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="estado_bateria_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="estado_bateria_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Tapa de Combustible: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Tapa de Combustible? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tapa_combus"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="tapa_combus"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tapa_combus"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="tapa_combus" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tapa_combus_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tapa_combus_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="tapa_combus_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="tapa_combus_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="tapa_combus_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Chasis y Carrocería: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Chasis y Carrocería? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="chasis_carroce"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="chasis_carroce"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="chasis_carroce"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="chasis_carroce" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="chasis_carroce_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="chasis_carroce_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="chasis_carroce_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="chasis_carroce_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="chasis_carroce_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Neumáticos (sin cortes, grietas, diagonales y aberturas):
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Neumáticos (sin cortes, grietas, diagonales y aberturas)?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="neumaticos"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="neumaticos"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="neumaticos"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="neumaticos" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="neumaticos_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="neumaticos_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="neumaticos_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="neumaticos_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="neumaticos_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Presión de aire de llantas: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Presión de aire de llantas? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="presion_aire"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="presion_aire"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="presion_aire"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="presion_aire" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="presion_aire_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="presion_aire_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="presion_aire_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="presion_aire_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="presion_aire_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Aros (no presentan deformación): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Aros (no presentan deformación)? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="aros"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="aros"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="aros"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="aros" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="aros_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="aros_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="aros_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="aros_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="aros_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Tuercas/Esparragos alineados y marcados: </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Tuercas/Esparragos alineados y marcados?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tuercas"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="tuercas"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tuercas"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="tuercas" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tuercas_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tuercas_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="tuercas_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="tuercas_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="tuercas_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Trabatuercas: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Trabatuercas? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="trabatuercas"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="trabatuercas"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="trabatuercas"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="trabatuercas" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="trabatuercas_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="trabatuercas_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="trabatuercas_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="trabatuercas_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="trabatuercas_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Llanta de repuesto (de preferencia en la tolva):
              </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Llanta de repuesto ? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="llanta_repuesto"
                    type="radio"
                    value="SI"
                    label="SI"
                  />

                  <Field
                    inline
                    as={Form.Check}
                    name="llanta_repuesto"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="llanta_repuesto"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="llanta_repuesto" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="llanta_repuesto_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="llanta_repuesto_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="llanta_repuesto_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="llanta_repuesto_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="llanta_repuesto_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Escarpines: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Escarpines? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="escarpines"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="escarpines"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="escarpines"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="escarpines" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="escarpines_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="escarpines_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="escarpines_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="escarpines_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input disabled name="escarpines_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <h2
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                Elementos de Proteccion Personal
              </h2>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Casco de seguridad con barbiquejo: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Casco de seguridad con barbiquejo? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="casco_seguridad"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="casco_seguridad"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="casco_seguridad"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="casco_seguridad" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="casco_seguridad_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="casco_seguridad_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="casco_seguridad_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="casco_seguridad_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="casco_seguridad_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Lentes/sobrelentes de seguridad claros y oscuros:
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Lentes/sobrelentes de seguridad claros y oscuros?
              </Subtitle>
              <Row>
                <Col>
                  <FastField
                    inline
                    as={Form.Check}
                    name="lentes_seguridad"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="lentes_seguridad"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="lentes_seguridad"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="lentes_seguridad" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="lentes_seguridad_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="lentes_seguridad_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="lentes_seguridad_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="lentes_seguridad_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="lentes_seguridad_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Tapón de oido: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Tapón de oido? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tapon_oido"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tapon_oido"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tapon_oido"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="tapon_oido" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="tapon_oido_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="tapon_oido_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="tapon_oido_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="tapon_oido_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="tapon_oido_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Guantes:Badana (): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Guantes:Badana? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="guantes"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="guantes"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="guantes"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="guantes" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="guantes_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="guantes_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="guantes_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="guantes_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="guantes_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Chaleco de seguridad naranja con cintas reflectivas:
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Chaleco de seguridad naranja con cintas reflectivas?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="chaleco_seguridad"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="chaleco_seguridad"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="chaleco_seguridad"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="chaleco_seguridad" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="chaleco_seguridad_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="chaleco_seguridad_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="chaleco_seguridad_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="chaleco_seguridad_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="chaleco_seguridad_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Mascarilla descartable KN95: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Mascarilla descartable KN95? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="mascarilla"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="mascarilla"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="mascarilla"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="mascarilla" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="mascarilla_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="mascarilla_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="mascarilla_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="mascarilla_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="mascarilla_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>Respirador de media cara con cartuchos P100:</Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Respirador de media cara con cartuchos P100?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="respirador"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="respirador"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="respirador"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="respirador" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="respirador_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="respirador_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="respirador_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="respirador_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="respirador_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Zapatos de seguridad con punta reforzada: </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Zapatos de seguridad con punta reforzada?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="zapato_seguridad"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="zapato_seguridad"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="zapato_seguridad"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="zapato_seguridad" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="zapato_seguridad_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="zapato_seguridad_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="zapato_seguridad_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="zapato_seguridad_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="zapato_seguridad_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <h2
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                Documentos del Conductor (Vigentes)
              </h2>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> DNI: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene DNI? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="dni"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="dni"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="dni"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="dni" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="dni_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="dni_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="dni_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="dni_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="dni_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Licencia MTC: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Licencia MTC? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="licencia"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="licencia"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="licencia"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="licencia" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="licencia_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="licencia_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="licencia_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="licencia_req" type="text">
                    Licencia MTC Cantidad Requerida:
                  </Input>
                  <Input name="licencia_exi" type="text">
                    Licencia MTC Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Fotocheck:Empresa : </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Fotocheck:Empresa? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="fotocheck"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="fotocheck"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="fotocheck"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="fotocheck" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="fotocheck_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="fotocheck_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="fotocheck_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="fotocheck_req" type="text">
                    Fotocheck:Empresa Cantidad Requerida:
                  </Input>
                  <Input name="fotocheck_exi" type="text">
                    Fotocheck:Empresa Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Certificación Matpel Nivel: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Certificación Matpel Nivel? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="certificacion"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="certificacion"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="certificacion"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="certificacion" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="certificacion_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="certificacion_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="certificacion_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="certificacion_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="certificacion_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Pase medico: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Pase medico? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="pase_medico"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pase_medico"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pase_medico"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="pase_medico" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="pase_medico_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pase_medico_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="pase_medico_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="pase_medico_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="pase_medico_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> SCTR (Salud y Pensión): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene SCTR (Salud y Pensión)? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="scrt"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="scrt"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="scrt"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="scrt" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="scrt_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="scrt_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="scrt_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="scrt_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="scrt_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <h2
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                Documentos Unidad (Vigentes)
              </h2>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Certificación de identificación vehicular (Tarjeta propiedad):
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Certificación de identificación vehicular (Tarjeta
                propiedad)?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="certifi_identi"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="certifi_identi"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="certifi_identi"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="certifi_identi" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="certifi_identi_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="certifi_identi_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="certifi_identi_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="certifi_identi_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="certifi_identi_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Certificado inspección técnica vehicular: </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Certificado inspección técnica vehicular?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="certifi_inspec"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="certifi_inspec"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="certifi_inspec"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="certifi_inspec" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="certifi_inspec_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="certifi_inspec_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="certifi_inspec_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="certifi_inspec_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="certifi_inspec_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> SOAT: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene SOAT? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="soat"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="soat"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="soat"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="soat" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="soat_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="soat_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="soat_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="soat_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="soat_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Constancia GPS: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Constancia GPS? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="constancia"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="constancia"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="constancia"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="constancia" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="constancia_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="constancia_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="constancia_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="constancia_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="constancia_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Plan de Preparación y Respuestas: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Plan de Preparación y Respuestas? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="preparacion"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="preparacion"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="preparacion"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="preparacion" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="preparacion_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="preparacion_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="preparacion_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="preparacion_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="preparacion_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Cartilla teléfonos de emergencia: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Cartilla teléfonos de emergencia? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="cartilla_telef"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="cartilla_telef"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="cartilla_telef"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="cartilla_telef" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="cartilla_telef_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="cartilla_telef_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="cartilla_telef_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="cartilla_telef_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="cartilla_telef_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Hoja de resumen de seguridad (SDS): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Hoja de resumen de seguridad? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="hoja_resumen"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="hoja_resumen"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="hoja_resumen"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="hoja_resumen" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="hoja_resumen_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="hoja_resumen_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="hoja_resumen_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="hoja_resumen_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="hoja_resumen_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Hoja de ruta y plan de viaje (punto de control):
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Hoja de ruta y plan de viaje (punto de control)?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="hoja_ruta"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="hoja_ruta"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="hoja_ruta"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="hoja_ruta" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="hoja_ruta_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="hoja_ruta_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="hoja_ruta_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="hoja_ruta_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="hoja_ruta_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Poliza seguro vehicular (RC): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Poliza seguro vehicular? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="poliza_seguro"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="poliza_seguro"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="poliza_seguro"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="poliza_seguro" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="poliza_seguro_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="poliza_seguro_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="poliza_seguro_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="poliza_seguro_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="poliza_seguro_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                PET de transporte de concentrado de molibdeno:
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene PET de transporte de concentrado de molibdeno?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="pet_transporte"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pet_transporte"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pet_transporte"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="pet_transporte" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="pet_transporte_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pet_transporte_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="pet_transporte_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="pet_transporte_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="pet_transporte_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <h2
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                Herramientas e Implementos
              </h2>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Pala con mango de Plástico: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Pala con mango de Plástico? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="pala"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pala"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pala"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="pala" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="pala_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pala_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="pala_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="pala_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="pala_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Pico con buje plástico: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Pico con buje plástico? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="pico"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pico"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pico"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="pico" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="pico_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="pico_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="pico_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="pico_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="pico_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Cable Pasacorriente: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Cable Pasacorriente? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="cable_pasacorri"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="cable_pasacorri"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="cable_pasacorri"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="cable_pasacorri" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="cable_pasacorri_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="cable_pasacorri_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="cable_pasacorri_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="cable_pasacorri_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="cable_pasacorri_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Kit herramintas basicos: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Kit herramintas basicos? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_herrami"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_herrami"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_herrami"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="kit_herrami" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_herrami_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_herrami_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="kit_herrami_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="kit_herrami_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="kit_herrami_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Multiplicador de torque para rueda: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Multiplicador de torque para rueda? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="multiplicador"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="multiplicador"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="multiplicador"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="multiplicador" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="multiplicador_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="multiplicador_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="multiplicador_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="multiplicador_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="multiplicador_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> bara luminosa (roja y verde): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene bara luminosa (roja y verde)? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="bara_lumi"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="bara_lumi"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="bara_lumi"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="bara_lumi" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="bara_lumi_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="bara_lumi_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="bara_lumi_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="bara_lumi_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="bara_lumi_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Medidor de presión del neumático (Aire): </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Medidor de presión del neumático (Aire)?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="medidor_presion"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="medidor_presion"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="medidor_presion"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="medidor_presion" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="medidor_presion_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="medidor_presion_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="medidor_presion_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="medidor_presion_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="medidor_presion_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Gata: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Gata? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="gata"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="gata"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="gata"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="gata" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="gata_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="gata_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="gata_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="gata_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="gata_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Palancas y llave de rueda: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Palancas y llave de rueda? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="palancas"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="palancas"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="palancas"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="palancas" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="palancas_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="palancas_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="palancas_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="palancas_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="palancas_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Triangulo de Seguridad: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Triangulo de Seguridad? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="triangulo_segu"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="triangulo_segu"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="triangulo_segu"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="triangulo_segu" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="triangulo_segu_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="triangulo_segu_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="triangulo_segu_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="triangulo_segu_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="triangulo_segu_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Linterna: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Linterna? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="linterna"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="linterna"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="linterna"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="linterna" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="linterna_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="linterna_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="linterna_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="linterna_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="linterna_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Paletas (PARE Y SIGA): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Paletas (PARE Y SIGA)? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="paletas"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="paletas"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="paletas"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="paletas" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="paletas_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="paletas_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="paletas_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="paletas_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="paletas_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <h2
                style={{ fontFamily: "Google Sans" }}
                className="pt-1 fw-bold"
              >
                Implementos de Seguridad
              </h2>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Botiquín y Check list: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Botiquín y Check list? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="boquitin"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="boquitin"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="boquitin"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="boquitin" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="boquitin_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="boquitin_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="boquitin_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="boquitin_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="boquitin_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Extintor Exterior 6 Kg - PQS: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Extintor Exterior 6 Kg - PQS? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="extintor"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="extintor"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="extintor"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="extintor" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="extintor_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="extintor_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="extintor_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="extintor_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="extintor_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Cono de Seguridad de 70 cm con 2 cintas reflectivas:
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Cono de Seguridad de 70 cm con 2 cintas reflectivas?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="cono_seguridad"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="cono_seguridad"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="cono_seguridad"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="cono_seguridad" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="cono_seguridad_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="cono_seguridad_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="cono_seguridad_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="cono_seguridad_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="cono_seguridad_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Taco/cuña de fibra con agarradera: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Taco/cuña de fibra con agarradera? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="taco_cuña"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="taco_cuña"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="taco_cuña"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="taco_cuña" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="taco_cuña_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="taco_cuña_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="taco_cuña_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="taco_cuña_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="taco_cuña_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Kit Contra Derrames y Check list: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Kit Contra Derrames y Check list? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_derrame"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_derrame"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_derrame"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="kit_derrame" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_derrame_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="kit_derrame_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="kit_derrame_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="kit_derrame_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="kit_derrame_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Radio base comunicación: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Radio base comunicación? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="radio_base"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="radio_base"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="radio_base"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="radio_base" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="radio_base_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="radio_base_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="radio_base_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="radio_base_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="radio_base_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Antena de Autoradio y Radio de comunicaciones:
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Antena de Autoradio y Radio de comunicaciones?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="antena_autoradio"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="antena_autoradio"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="antena_autoradio"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="antena_autoradio" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="antena_autoradio_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="antena_autoradio_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="antena_autoradio_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="antena_autoradio_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="antena_autoradio_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Barra antivuelco interna (ROPS): </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Barra antivuelco interna (ROPS)? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="barra_interno"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="barra_interno"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="barra_interno"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="barra_interno" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="barra_interno_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="barra_interno_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="barra_interno_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="barra_interno_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="barra_interno_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle> Barra antivuelco externa: </Subtitle>
              <br></br>
              <Subtitle> ¿Tiene Barra antivuelco externa? </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="barra_externo"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="barra_externo"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="barra_externo"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="barra_externo" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="barra_externo_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="barra_externo_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="barra_externo_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="barra_externo_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="barra_externo_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                Letrero de convoy con juego de Numeración del 1 al 10:
              </Subtitle>
              <br></br>
              <Subtitle>
                ¿Tiene Letrero de convoy con juego de Numeración del 1 al 10?
              </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="letrero_numera"
                    type="radio"
                    value="SI"
                    label="SI"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="letrero_numera"
                    type="radio"
                    value="NO"
                    label="NO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="letrero_numera"
                    type="radio"
                    value="NA"
                    label="NA"
                  />
                  <ErrorMessage name="letrero_numera" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Subtitle> Estado: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="letrero_numera_est"
                    type="radio"
                    value="1"
                    label="BUENO"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="letrero_numera_est"
                    type="radio"
                    value="0"
                    label="MALO"
                  />
                  <ErrorMessage name="letrero_numera_est" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                  <Input disabled name="letrero_numera_req" type="text">
                    Cantidad Requerida:
                  </Input>
                  <Input name="letrero_numera_exi" type="text">
                    Cantidad Existente:
                  </Input>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>
                {" "}
                Marque los neumáticos que tengan Cocada con un Mínimo de 3mm
                (Solo las camionetas escoltas contaran con 2 circulinas){" "}
              </Subtitle>
              <img
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  marginTop: "2rem",
                }}
                src={Camioneta}
                alt=""
              />
              <div>
                <Field
                  inline
                  as={Form.Check}
                  name="cocadas"
                  type="checkbox"
                  value="neumaticofi"
                  label="Neumatico frontal izquierdo"
                />
                <Field
                  inline
                  as={Form.Check}
                  name="cocadas"
                  type="checkbox"
                  value="neumaticofd"
                  label="Neumatico frontal derecho"
                />
                <Field
                  inline
                  as={Form.Check}
                  name="cocadas"
                  type="checkbox"
                  value="neumaticotd"
                  label="Neumatico trasero derecho"
                />
                <Field
                  inline
                  as={Form.Check}
                  name="cocadas"
                  type="checkbox"
                  value="neumaticoti"
                  label="Neumatico trasero izquierdo"
                />
                <ErrorMessage name="cocadas" as={Subtitle}>
                  {(message) => <ErrorM>{message}</ErrorM>}
                </ErrorMessage>
              </div>
            </Tarjeta>
            <Tarjeta>
              <Input name="observaciones" type="text">
                Observaciones:
              </Input>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>Combustible: </Subtitle>
              <Row>
                <Col>
                  <Field
                    inline
                    as={Form.Check}
                    name="combustible"
                    type="radio"
                    value="SI"
                    label="E"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="combustible"
                    type="radio"
                    value="uncuarto"
                    label="1/4"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="combustible"
                    type="radio"
                    value="medio"
                    label="1/2"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="combustible"
                    type="radio"
                    value="trescuartos"
                    label="3/4"
                  />
                  <Field
                    inline
                    as={Form.Check}
                    name="combustible"
                    type="radio"
                    value="full"
                    label="F"
                  />
                  <ErrorMessage name="combustible" as={Subtitle}>
                    {(message) => <ErrorM>{message}</ErrorM>}
                  </ErrorMessage>
                </Col>
              </Row>
            </Tarjeta>
            <Tarjeta>
              <Subtitle>Lista de pasajeros:</Subtitle>
              <Input name="pasajero_uno" type="text">
                Pasajero 1
              </Input>
              <Input name="pasajero_dos" type="text">
                Pasajero 2
              </Input>
              <Input name="pasajero_tres" type="text">
                Pasajero 3
              </Input>
              <Input name="pasajero_cuatro" type="text">
                Pasajero 4
              </Input>
            </Tarjeta>
            <Tarjeta>
              <Input name="conductor" type="text">
                Nombre del conductor:
              </Input>

              <Field name="firma">
                {({ field }) => (
                  <Firm
                    value={field.value}
                    firmChange={(value) => setFieldValue(field.name, value)}
                  >
                    Firma
                  </Firm>
                )}
              </Field>
              <ErrorMessage name="firma" as={Form.Label}>
                {(message) => <ErrorM>{message}</ErrorM>}
              </ErrorMessage>
            </Tarjeta>
            <FieldArray name="supervisores">
              {({ push, remove }) => (
                <>
                  {values.supervisores.map((supervisor, index) => {
                    return (
                      <Tarjeta key={index}>
                        <Form.Label>Supervisor</Form.Label>
                        <Field
                          name={`supervisores[${index}].nombre`}
                          type="text"
                          as="select"
                          className="form-select"
                        >
                          <option value="">Select</option>
                          {Object.entries(supervisores)
                            .filter(([id, supervisor]) => {
                              return supervisor.area === "Supervisor";
                            })
                            .map(([id, supervisor]) => {
                              return (
                                <option key={id} value={id}>
                                  {supervisor.name}
                                </option>
                              );
                            })}
                        </Field>
                        <br/>
                        <ErrorMessage
                          name={`supervisores[${index}].nombre`}
                          as={Form.Label}
                        >
                          {(message) => <ErrorM>{message}</ErrorM>}
                        </ErrorMessage>
                        {index!==0 && <DeleteButton onClick={() => remove(index)} />}
                        {index === values.supervisores.length - 1 && (
                          <ActionButton
                            type="add"
                            float="right"
                            onClick={() =>
                              push({
                                nombre: "",
                              })
                            }
                          />
                        )}
                      </Tarjeta>
                    );
                  })}
                </>
              )}
            </FieldArray>
            <Button type="submit"> subir </Button>
          </FormMK>
        )}
      </Formik>
    </Container>
  );
};
export default VehiculosLigeros;
