import styled from "styled-components";
export const Content = styled.div`
  margin-top: 1.5rem;
  position: relative;
  padding: 0 1rem;
  top: 70px;
  @media screen and (min-width: 576px) {
    padding: 0 2rem;
  }
  @media screen and (min-width: 1024px) {
    left: 280px;
    width: calc(100% - 280px);
    margin-top: 3rem;
    top: 0;
  }
  @media screen and (min-width: 1400px) {
    padding: 0 12rem;
  }
`;
