import { firebase } from "../firebase-configs";

const firebaseDB = firebase.database();

const getFormsData = async () => {
  let formRef;
  let snapshot;
  const out = {};
  formRef = firebaseDB.ref("FormulariosManuales");
  snapshot = await formRef.once("value");
  Object.keys(snapshot.val()).map((index) => {
    Object.assign(out, {
      [index]: snapshot.val()[index],
    });
  });
  formRef = firebaseDB.ref("Formularios");
  snapshot = await formRef.once("value");
  Object.keys(snapshot.val()).map((index) => {
    Object.assign(out, {
      [index]: snapshot.val()[index],
    });
  });
  return out;
};

export const getAnswersData = async () => {
  const answersRef = firebaseDB.ref("Respuestas");
  const snapshot = await answersRef.once("value");
  const formData = await getFormsData();
  const out = {};
  Object.keys(snapshot.val()).map((index) => {
    const date = new Date(snapshot.val()[index].createDate);
    let typeDoc = snapshot.val()[index]["Documento de identidad"];
    if (typeDoc === undefined) {
      typeDoc = snapshot.val()[index]["Documento de identidad Menor de edad"];
      if (typeDoc === undefined) {
        typeDoc = snapshot.val()[index].documento;
        if (typeDoc === undefined) {
          typeDoc = "No se encontraron datos";
        }
      }
    } else {
      typeDoc = typeDoc.docType;
    }
    Object.assign(out, {
      [index]: {
        formName: formData[snapshot.val()[index].formKey].nombre,
        formDate: date.toLocaleDateString(),
        formTime: date.toLocaleTimeString(),
        formTypeDoc: typeDoc,
      },
    });
  });
  console.log(out);
  return out;
};
