import { jsPDF } from "jspdf";
import { resetTable, cell, selectValue, setSelectValueOptionWidth} from "../pdfFunctions.js";
import { getWorkerData } from "../databaseData.js";
import ipercIcon from "../../icons/ipercIcon.js"
import secondIcon from "../../icons/ipercSecondIcon.js"

const initUserData = {
  name: "",
  email: "",
  area: "",
  firma: ipercIcon,
  logo: ipercIcon,
  empresa: "",
}

export const downloadIpercPdf = async(values) => {
  resetTable()
  let userData = await getWorkerData(values.userId)
  const doc = new jsPDF();
  const heightCell = 5;
  const colorCells = "#cccccc"
  doc.setFontSize(7);

  cell(doc, 0, 0, 30, 20, "", 1)
  if(userData.logo !== undefined){
    doc.addImage(userData.logo, "PNG", 0, 5, 30, 8.17)
  } else {
    doc.addImage(ipercIcon, "PNG", 0, 5, 30, 8.17)
  }
  resetTable()
  cell(doc, 30, 0, 150, heightCell, "REGISTRO", 1, {align: "center"})
  cell(doc, 30, 0, 150, heightCell, "IPERC CONTINUO", 2, {align: "center"})
  cell(doc, 30, 0, 75, heightCell, "Código: R.BMB.PRY.SST.EST.01.01", 3)
  cell(doc, 30, 0, 40, heightCell, "Versión:  0", 3)
  cell(doc, 30, 0, 35, heightCell, "Páginas: 1 de 2", 3)
  cell(doc, 30, 0, 75, heightCell, "Área: Gerencia de Seguridad y Salud Ocupacional", 4)
  cell(doc, 30, 0, 40, heightCell, "Fecha: 25-08-2021", 4)
  cell(doc, 30, 0, 35, heightCell, "Registro N°:", 4)
  resetTable()
  cell(doc, 180, 0, 30, 20, "", 1)
  if(userData.logo !== undefined){
    doc.addImage(userData.logo, "PNG", 180, 5, 30, 8.17)
  } else {
    doc.addImage(ipercIcon, "PNG", 180, 5, 30, 8.17)
    userData = initUserData
  }
  resetTable()
  const date = new Date(values.createDate);
  cell(doc, 0, 20, 30, heightCell, "Empresa:", 1, {align: "right"})
  cell(doc, 0, 20, 75, heightCell, userData.empresa, 1)
  cell(doc, 0, 20, 30, heightCell, "Nombre de la Tarea:", 1, {align: "right"})
  cell(doc, 0, 20, 75, heightCell, values.tarea, 1)
  cell(doc, 0, 20, 30, heightCell, "Área:", 2, {align: "right"})
  cell(doc, 0, 20, 75, heightCell, userData.area, 2)
  cell(doc, 0, 20, 30, heightCell, "Fecha:", 2, {align: "right"})
  cell(doc, 0, 20, 75, heightCell, date.toLocaleDateString(), 2)
  cell(doc, 0, 20, 30, heightCell, "Ubicación exacta (Lugar):", 3, {align: "right"})
  cell(doc, 0, 20, 75, heightCell, values.ubicacion, 3)
  cell(doc, 0, 20, 30, heightCell, "Hora:", 3, {align: "right"})
  cell(doc, 0, 20, 75, heightCell, date.toLocaleTimeString(), 3)

  resetTable()
  cell(doc, 0, 35, 5, 10, "N°", 1, {align: "center"})
  cell(doc, 0, 35, 45, 10, "SECUENCIA DE PASOS DE LA TAREA", 1, {align: "center"})
  resetTable()
  doc.setFontSize(5)
  cell(doc, 50, 35, 30, 7, "¿PASO DE LA TAREA INVOLUCRA EXPONER LA MANO(S) A LA LINEA DE FUEGO?", 1, {align: "center"})
  resetTable()
  cell(doc, 50, 42, 15, 3, "SI", 1, {align: "center"})
  cell(doc, 0, 0, 15, 3, "NO", 1, {align: "center"})
  resetTable()
  doc.setFontSize(7)
  cell(doc, 80, 35, 20, 10, "DESCRIPCION DEL PELIGRO", 1, {align: "center"})
  cell(doc, 0, 0, 20, 10, "RIESGO", 1, {align: "center"})
  resetTable()
  doc.setFontSize(5)
  cell(doc, 120, 35, 15, 7, "EVALUACIÓN IPER", 1, {align: "center"})
  doc.setFontSize(7)
  resetTable()
  cell(doc, 120, 42, 5, 3, "A", 1, {align: "center", color: "#e85858"})
  cell(doc, 0, 0, 5, 3, "M", 1, {align: "center", color: "#e3e858"})
  cell(doc, 0, 0, 5, 3, "B", 1, {align: "center", color: "#58e875"})
  resetTable()
  cell(doc, 135, 35, 60, 10, "CONTROLES CRÍTICOS IDENTIFICADOS E IMPLEMENTADOS", 1, {align: "center"})
  resetTable()
  doc.setFontSize(5)
  cell(doc, 195, 35, 15, 7, "EVALUACIÓN RIESGO RESIDUAL", 1, {align: "center"})
  resetTable()
  doc.setFontSize(7)
  cell(doc, 195, 42, 5, 3, "A", 1, {align: "center", color: "#e85858"})
  cell(doc, 0, 0, 5, 3, "M", 1, {align: "center", color: "#e3e858"})
  cell(doc, 0, 0, 5, 3, "B", 1, {align: "center", color: "#58e875"})
  
  let currentY = 45
  let nSecuence = 1
  let currRow = 1
  const maxPageY = 200
  resetTable()
  
  for(let sec of values.secuencias){
    if(currentY > maxPageY){
      doc.addPage()
      currentY = 5
      currRow = 1
      resetTable()
    }
    setSelectValueOptionWidth(15)
    cell(doc, 0, currentY, 5, heightCell, nSecuence, currRow)
    selectValue(doc, 0, currentY, 75, heightCell, sec.nombre, currRow, {items: ["SI", "NO"], value: sec.fuego})
    cell(doc, 0, currentY, 20, heightCell, sec.descripcion, currRow)
    setSelectValueOptionWidth(5)
    selectValue(doc, 0, currentY, 35, heightCell, sec.riesgo, currRow, {items: ["A", "M", "B"], value: sec.eva_iper})
    selectValue(doc, 0, currentY, 75, heightCell, sec.controles, currRow, {items: ["A", "M", "B"], value: sec.eva_riesgo})
    nSecuence += 1
    currRow += 1
    currentY += heightCell
  }

  currRow = 1
  nSecuence = 1
  resetTable()
  if(currentY + heightCell > maxPageY){
    doc.addPage()
    currentY = 5
  }

  cell(doc, 0, currentY, 210, heightCell, "SECUENCIA  PARA CONTROLAR EL PELIGRO Y REDUCIR EL RIESGO", 1)
  resetTable()

  currentY += heightCell

  for (let sec of values.controlsecuencias){
    if(currentY + heightCell > maxPageY){
      doc.addPage()
      currentY = 5
      currRow = 1
      resetTable()
    }
    cell(doc, 0, currentY, 210, heightCell, nSecuence + ".- " + sec.nombre, currRow)
    nSecuence += 1
    currRow += 1
    currentY += heightCell
  }

  currRow = 1
  nSecuence = 1
  resetTable()
  if(currentY + (heightCell * 3) > maxPageY){
    doc.addPage()
    currentY = 5
  }

  cell(doc, 0, currentY, 210, heightCell, "DATOS DE TRABAJADORES:", 1, {color: colorCells})
  doc.setFontSize(6)
  cell(doc, 0, currentY, 210, heightCell, "Antes de iniciar la tarea, el supervisor responsable de la tarea debe asegurar que los trabajadores conocen los pasos de la tarea, peligros, riesgos y controles establecidos. Todos los trabajadores deben firmar en el formato, en señal de conformidad y compromiso en cumplir y hacer cumplir los controles de seguridad establecidos.", 2)
  doc.setFontSize(7)
  cell(doc, 0, currentY, 5, heightCell, "N°", 3, {align: "center",color: colorCells})
  cell(doc, 0, currentY, 20, heightCell, "Fecha", 3, {align: "center",color: colorCells})
  cell(doc, 0, currentY, 20, heightCell, "Hora", 3, {align: "center",color: colorCells})
  cell(doc, 0, currentY, 30, heightCell, "Nivel/Area", 3, {align: "center",color: colorCells})
  cell(doc, 0, currentY, 80, heightCell, "Nombres y Apellidos", 3, {align: "center",color: colorCells})
  cell(doc, 0, currentY, 55, heightCell, "Firma", 3, {align: "center",color: colorCells})

  currentY += heightCell * 3
  resetTable()

  for (let wrk of values.trabajadores){
    if(currentY + 20 > maxPageY){
      doc.addPage()
      currentY = 5
      currRow = 1
      resetTable()
    }
    const user = await getWorkerData(wrk.id)
    cell(doc, 0, currentY, 5, 20, nSecuence, currRow, {align: "center"})
    if(values[wrk.id] !== undefined){
      const date = new Date(values[wrk.id].acceptDate)
      cell(doc, 0, currentY, 20, 20, date.toLocaleDateString(), currRow, {align: "center"})
      cell(doc, 0, currentY, 20, 20, date.toLocaleTimeString(), currRow, {align: "center"})
      cell(doc, 0, currentY, 30, 20, user.area, currRow, {align: "center"})
      cell(doc, 0, currentY, 80, 20, user.name, currRow, {align: "center"})
      cell(doc, 0, currentY, 55, 20, "", currRow, {align: "center"})
      doc.addImage(user.firma, "PNG", 168, currentY, 30, 20)
      
    } else {
      cell(doc, 0, currentY, 20, 20, "", currRow, {align: "center"})
      cell(doc, 0, currentY, 20, 20, "", currRow, {align: "center"})
      cell(doc, 0, currentY, 30, 20, user.area, currRow, {align: "center"})
      cell(doc, 0, currentY, 80, 20, user.name, currRow, {align: "center"})
      cell(doc, 0, currentY, 55, 20, "", currRow, {align: "center"})
    }
    nSecuence += 1
    currRow += 1
    currentY += 20
  }

  currRow = 1
  nSecuence = 1
  resetTable()
  if(currentY + 20 > maxPageY){
    doc.addPage()
    currentY = 5
  }

  cell(doc, 0, currentY, 155, heightCell, "DATOS DE LOS SUPERVISORES:", 1, {color: colorCells, align: "center"})
  cell(doc, 0, currentY, 5, heightCell, "N°", 2, {color: colorCells, align: "center"})
  cell(doc, 0, currentY, 20, heightCell, "HORA", 2, {color: colorCells, align: "center"})
  cell(doc, 0, currentY, 45, heightCell, "Nombres y Apellidos del Supervisor", 2, {color: colorCells, align: "center"})
  cell(doc, 0, currentY, 30, heightCell, "Medida Correctiva", 2, {color: colorCells, align: "center"})
  cell(doc, 0, currentY, 55, heightCell, "Firma", 2, {color: colorCells, align: "center"})
  doc.addImage(secondIcon, "PNG", 160, currentY, 55, 23.5)

  currentY += heightCell * 2
  resetTable()

  for (let wrk of values.supervisores){
    if(currentY + 20 > maxPageY){
      doc.addPage()
      currentY = 5
      currRow = 1
      resetTable()
    }
    const user = await getWorkerData(wrk.id)
    cell(doc, 0, currentY, 5, 20, nSecuence, currRow, {align: "center"})
    if(values[wrk.id] !== undefined){
      const date = new Date(values[wrk.id].acceptDate)
      cell(doc, 0, currentY, 20, 20, date.toLocaleTimeString(), currRow, {align: "center"})
      cell(doc, 0, currentY, 45, 20, user.name, currRow, {align: "center"})
      cell(doc, 0, currentY, 30, 20, values[wrk.id].medida_correctiva, currRow, {align: "center"})
      cell(doc, 0, currentY, 55, 20, "", currRow, {align: "center"})
      console.log(user)
      if(user.firma !== undefined){
        doc.addImage(user.firma, "PNG", 112, currentY, 30, 20)

      }
    } else {
      cell(doc, 0, currentY, 20, 20, "", currRow, {align: "center"})
      cell(doc, 0, currentY, 45, 20, user.name, currRow, {align: "center"})
      cell(doc, 0, currentY, 30, 20, "", currRow, {align: "center"})
      cell(doc, 0, currentY, 55, 20, "", currRow, {align: "center"})
    }
    nSecuence += 1
    currRow += 1
    currentY += 20
  }
  
  window.open(doc.output("bloburl"), "_blank");
}