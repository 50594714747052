import React from "react";
import styled from "styled-components";

const Estilo = styled.div`
  color: red;
  font-size: 0.8rem;
`;
const ErrorMessage = (props) => {
  return (
    <>
      <Estilo>{props.children}</Estilo>
    </>
  );
};
export default ErrorMessage;
