import React, { useEffect, useState } from "react";
import { Content } from "../components/FrontComponents/Structure";
import { Subtitle, Title } from "../components/FrontComponents/TextType";
import { DatePicker } from "../components/FrontComponents/Inputs/datepicker";
import Navbar from "../components/Navbar/NavbarVF";
import NavbarSupervisor from "../components/Navbar/NavBarSupervisor";
import NavbarTrabajador from "../components/Navbar/NavBarTrabajador";
import { getUserData } from "../services/userData";
import { InputWrapper } from "../components/FrontComponents/inputwrapper";
import { Wrapper } from "../components/FrontComponents/wrapper";
import { Button, ButtonWrapper } from "../components/FrontComponents/Buttons";
import { Chart } from "react-google-charts";
import { getAnswersData } from "../services/answersData";
import { Spinner } from "react-bootstrap";
const options = {
  title: "Cantidad de formatos por tipo",
  chartArea: { width: "45%" },
  hAxis: {
    title: "Total Formatos",
    minValue: 0,
  },
  vAxis: {
    title: "Tipo",
  },
};
const Dashboard = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [answersObj, setAnswersObj] = useState({});
  const [dataChart, setDataChart] = useState([]);
  const datatest = [];
  const handleClick = () => {
    setDataChart(chartData);
  };
  const dataunfiltered = [];
  const chartDataunfiltered = [["Tipo", "Cantidad"]];
  const chartData = [["Tipo", "Cantidad"]];
  const toMs = (date) => {
    return new Date(date).getTime();
  };
  const answers = Object.entries(answersObj).map(([id, answer]) => {
    return { ...answer, id };
  });
  const leakedDates = answers.filter((answer) => {
    const partsDate = answer.formDate.split("/");
    const date = partsDate[2] + "-" + partsDate[1] + "-" + partsDate[0];
    const dateMs = toMs(date);
    const endDateMs = toMs(endDate);
    const startDateMs = toMs(startDate);
    return dateMs > startDateMs && dateMs < endDateMs;
  });
  console.log(answers);
  console.log(leakedDates);
  //Data filtrada
  leakedDates.forEach((date, index) => {
    datatest.push([date.formName]);
  });

  const newdata = datatest.reduce(
    (prev, cur) => ((prev[cur] = prev[cur] + 1 || 1), prev),
    {}
  );
  Object.entries(newdata).forEach(([nombre, cantidad]) => {
    chartData.push([nombre, cantidad]);
  });
  //data no filtrada
  answers.forEach((date, index) => {
    dataunfiltered.push([date.formName]);
  });
  const newdataunfiltered = dataunfiltered.reduce(
    (prev, cur) => ((prev[cur] = prev[cur] + 1 || 1), prev),
    {}
  );
  Object.entries(newdataunfiltered).forEach(([nombre, cantidad]) => {
    chartDataunfiltered.push([nombre, cantidad]);
  });
  //console.log("soy respuestas", answersObj);
  useEffect(() => {
    getAnswersData().then((data) => {
      setAnswersObj(data);
    });
  }, []);

  return (
    <>
       {getUserData().area === "Administrador" ? (
        <Navbar />
      ) : getUserData().area === "Supervisor" ? (
        <NavbarSupervisor />
      ) : (
        <NavbarTrabajador />
      )}
      <Content>
        <Title>Dashboard</Title>
        <Wrapper style={{marginBottom: "1rem"}} justify="start">
          <InputWrapper>
            <Subtitle>Fecha Inicio</Subtitle>
            <DatePicker
              name="date_start"
              onChange={(e) => setStartDate(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Subtitle>Fecha Fin</Subtitle>
            <DatePicker
              name="date_end"
              onChange={(e) => setEndDate(e.target.value)}
            />
          </InputWrapper>

          <Button align="end" onClick={() => handleClick()}>
            Search
          </Button>
        </Wrapper>
        {console.log(dataChart)}
        {chartDataunfiltered.length>1 ?<Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={dataChart.length > 1 ? dataChart : chartDataunfiltered}
          options={options}
        /> : <div style={{display:"flex", justifyContent: "center"}}><Spinner animation="border" size="lg"/></div>  }
        
      </Content>
    </>
  );
};

export default Dashboard;
