import { HashRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "./pages/landingPage";
import FormPage from "./pages/FormPage/formPage";
import Subido from "./components/subido";
import LoginForm from "./components/loginForm";
import Form from "./components/CustomForm/customForm";
import RespuestasPage from "./pages/RespuestasPage";
import Typedecl from "./pages/typedecl";
import PlusPetrol from "./pages/PlusPetrol";
import AuthRoute from "./components/authRoute";
import React, { useEffect, useState } from "react";
import Trabajadores from "./pages/Trabajadores";
import Solicitudes from "./pages/Solicitudes";
import VehiculosLigeros from "./pages/vehiculosligeros";
import "./App.css";
import ipercFormat from "./pages/ipercFormat";
import vehiculosPesadosFormat from "./pages/vehiculosPesadosFormat";
import Dashboard from "./pages/dashboard";
import Areas from "./pages/Mantenimientos/areas";
import Navbar from "./components/Navbar/NavbarVF";
import { getUserData, isUserAuth } from "./services/userData";
function App() {
  const [user, setUser] = useState("")
  let users;
  
  useEffect(() => {
    isUserAuth().then((value)=>{
      users = getUserData()
      setUser(value)
    }).catch()
    
  }, []);
  return (
    <Router>
   
      <Switch>
        <Route path="/" exact component={LoginForm} />
        <div>
          <AuthRoute
            area={["Administrador", "Supervisor"]}
            path="/formPage"
            component={FormPage}
          />
          <AuthRoute area={["Supervisor"]} path="/Respuestas" component={RespuestasPage} />
          <AuthRoute guest path="/pluspetrol" component={PlusPetrol} />
          <AuthRoute guest path="/subido" component={Subido} />
          <AuthRoute guest path="/typedecl" component={Typedecl} />
          <AuthRoute guest path="/form/:formKey" component={Form} />
          <AuthRoute area={["Administrador"]} path="/trabajadores" component={Trabajadores} />
          <AuthRoute path="/solicitud" component={Solicitudes} />
          <AuthRoute area={["Administrador"]} path="/dashboard" component={Dashboard} />
          <AuthRoute area={["Administrador"]} path="/areas" component={Areas} />
          <AuthRoute
            path="/VehiculosLigeros"
            component={VehiculosLigeros}
          />
          <AuthRoute area={["Administrador", "Supervisor"]} path="/Iperc" component={ipercFormat} />
          <AuthRoute
            path="/VehiculosPesados"
            component={vehiculosPesadosFormat}
          />
        </div>
        <Route component={LandingPage} />
      </Switch>
    </Router>
  );
}

export default App;
