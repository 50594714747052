import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import AddQuestion from "../../components/addQuestion";
import "react-toastify/dist/ReactToastify.css";
import Question from "../../components/question";
import { firebase } from "../../firebase-configs";
import * as ImIcons from "react-icons/im";
import { useParams } from "react-router-dom";
import Toast from "../toast";
import api from "../../services/autocompleteData";
import pdf from "../../services/exportPdf";

import Subido from "../subido";
import { Redirect } from "react-router-dom";

const firebaseDB = firebase.database();
let offButton = false;
let actualIndex = 0;
let questionsData = [];
let initialQuestionData = {
  title: "",
  type: "Respuesta",
};
let solveData = {};
let firmData = {};
let isEditable = "input";

const CustomForm = (props) => {
  const [show, setShow] = useState(false);
  const [toFetch, setToFetch] = useState([]);
  const [addQuestionType, setAddQuestionType] = useState("Añadir");
  const [data, setData] = useState({});
  const [toForms, setToForms] = useState(false);
  let formKey = useParams().formKey;

  const handleClose = () => {
    setShow(false);
  };
  const handleClick = (initialData, index) => {
    if (initialData !== false) {
      initialQuestionData = initialData;
      setAddQuestionType("Modificar");
      actualIndex = index;
    } else {
      initialQuestionData = {
        title: "",
        type: "Respuesta",
      };
      setAddQuestionType("Añadir");
    }
    setShow(true);
  };
  const handleSubmit = (event, data) => {
    if (event.target.id === "Añadir") {
      questionsData.push(data);
      fetchData();
      Toast("Pregunta agregada", "success");
    } else {
      questionsData[actualIndex] = data;
      setShow(false);
      fetchData();
    }
  };
  const handleDelete = (index) => {
    questionsData.splice(index, 1);
    fetchData();
  };
  const handleSave = (event) => {
    event.preventDefault();
    let count = 0;
    questionsData.map((item) => {
      if (item.type !== "Texto") {
        count = count + 1;
      }
      return false;
    });
    if (count === Object.keys(solveData).length || offButton) {
      if (props.edit !== undefined) {
        formKey = props.edit;
      }
      if (event.target.id === "Guardar") {
        const formsRef = firebaseDB.ref("/Formularios/" + formKey);
        formsRef.set(data).catch((error) => {
          console.log(error);
        });
        const refQuestions = firebaseDB.ref("Preguntas/" + formKey);
        refQuestions.set(questionsData).catch((error) => {
          console.log(error);
        });
        Toast("Formulario creado Correctamente", "success");
        props.onSubmit();
      } else {
        solveData.formKey = formKey;
        solveData.createDate = Date.now();
        let answersRef = firebaseDB.ref("Respuestas/");
        answersRef.push(solveData).then(() => {
          Toast("Información subida Correctamente", "success");
          pdf.dowloadPdf(solveData);
          setToForms(true);
        });
      }
    } else {
      Toast("Falta Ingresar la Firma", "error");
    }
  };

  const handleSendData = (data, header) => {
    if (Object.keys(data).length <= 1) {
      solveData[Object.keys(data)[0]] = data[Object.keys(data)[0]];
    } else {
      solveData = { ...solveData, [header]: data };
    }
  };

  const handleSearch = async (data, key) => {
    if (solveData[key] === undefined) {
      solveData[key] = { docType: "DNI" };
    }
    if (data.target.value !== undefined) {
      solveData[key] = { ...solveData[key], number: data.target.value };
    }
    if (solveData[key].docType === "DNI") {
      const ref = key.replace("Documento de identidad", "");
      const dniData = await api.getData(data.target.value);
      if (dniData !== false) {
        const names =
          dniData.nombres +
          " " +
          dniData.apellidoPaterno +
          " " +
          dniData.apellidoMaterno;
        solveData["Nombres y Apellidos" + ref] = names;
        isEditable = "label";
        console.log("success");
        fetchData();
      } else {
        console.log("No se encontraron datos");
      }
    } else {
      isEditable = "input";
      fetchData();
    }
  };

  const fetchData = () => {
    const fetch = questionsData.map((item, index) => {
      let value = "";
      let editable = "input";
      if (solveData[item.title] !== undefined) {
        value = solveData[item.title];
        editable = isEditable;
      }
      return (
        <div key={index}>
          <Question
            search={handleSearch}
            sendData={handleSendData}
            typeControl={editable}
            req={offButton ? false : true}
            estado={offButton}
            type={item.type}
            initValue={value}
            delete={() => handleDelete(index)}
            modify={() => handleClick(item, index)}
          >
            {item.title}
          </Question>
        </div>
      );
    });
    setToFetch(fetch);
  };

  const handleHeaderData = (sendData) => {
    data.nombre = sendData[Object.keys(sendData)[0]];
  };

  useEffect(() => {
    solveData = {};

    if (props.edit !== undefined) {
      formKey = props.edit;
      offButton = true;
    } else {
      offButton = false;
    }
    setData([]);
    actualIndex = 0;
    questionsData = [];
    initialQuestionData = {
      title: "",
      type: "Respuesta",
    };
    const questionsRef = firebaseDB.ref("Preguntas/" + formKey);
    questionsRef.once("value").then((snapshot) => {
      if (snapshot.val() !== null) {
        if (snapshot.val() !== undefined) {
          questionsData = snapshot.val();
          fetchData();
          questionsRef.off();
        }
      }
    });
    const dataRef = firebaseDB.ref("Formularios/" + formKey);
    dataRef.once("value").then((snapshot) => {
      if (snapshot.val() !== null) {
        if (snapshot.val() !== undefined) {
          const d = snapshot.val();
          setData(d);
          dataRef.off();
        }
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {toForms ? <Redirect to="/subido" /> : null}
      <form onSubmit={handleSave} id={offButton ? "Guardar" : "Grabar"}>
        <Container style={{ backgroundColor: "#a3dfff" }} className="p-3">
          <Row>
            <Col>
              <Col>
                <Row>
                  <Col md="12" className="text-end">
                    {offButton ? (
                      <Button
                        size="lg"
                        className="mb-2"
                        variant="success"
                        onClick={() => handleClick(false, 0)}
                      >
                        <ImIcons.ImPlus
                          style={{
                            paddingRight: "0.3rem",
                            paddingBottom: "0.2rem",
                          }}
                        />
                        Añadir item
                      </Button>
                    ) : null}
                  </Col>
                  <Col>
                    <Question
                      sendData={handleHeaderData}
                      req={true}
                      className="border-bottom"
                      typeControl="input"
                      initValue={offButton ? data.nombre : null}
                      type={offButton ? "Respuesta" : "Texto"}
                    >
                      {offButton ? "Nombre del documento" : data.nombre}
                    </Question>
                  </Col>
                </Row>
                <AddQuestion
                  className="mt-2"
                  handleShow={show}
                  hide={handleClose}
                  submit={handleSubmit}
                  initialData={initialQuestionData}
                  type={addQuestionType}
                ></AddQuestion>
              </Col>
              <Row>{toFetch}</Row>

              <Row>
                <Col className="mt-3">
                  <Button
                    style={{ width: "100%" }}
                    size="lg"
                    variant="success"
                    type="submit"
                  >
                    {offButton ? "Guardar" : "Grabar"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </form>
    </>
  );
};

export default CustomForm;
