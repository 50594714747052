import React from "react"
import {Container,Col,Row,Image} from "react-bootstrap"
import Check from "../icons/check.png"
function Subido(){
    return (
        <>
        <Container className="text-center"style={{height:"100vh", display: "flex", alignItems:"center", justifyContent:"center"}} >
            <Row>
                <Col>
                    <h1>Formulario</h1>
                    <p >¡Su respuesta se ha registrado!</p>
                    <Image src={Check} style={{height:"5rem"}}/>
                </Col>
            </Row>
        </Container>
        </>  
    )
}
export default Subido