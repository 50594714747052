import React from "react";
import { Button } from "./Buttons";
import * as IoIcons from "react-icons/io";
import * as MdIcons from "react-icons/md";
import styled from "styled-components";
const ButtonStyled = styled(Button)`
  border-radius: 5px;
  font-size: 14px;
  padding: 0rem 0.3rem 0.1rem 0.3rem;

  @media screen and (min-width: 900px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1400px) {
    font-size: 20px;
  }
`;

export const DeleteButton = (props) => {
  return (
    <ButtonStyled type="button" color="red" onClick={props.onClick}>
      <MdIcons.MdDelete />
    </ButtonStyled>
  );
};
export const EditButton = (props) => {
  return (
    <ButtonStyled onClick={props.onClick} type="button" color="yellow">
      <MdIcons.MdEdit />
    </ButtonStyled>
  );
};
export const ViewButton = (props) => {
  return (
    <ButtonStyled onClick={props.onClick} type="button" color="green">
      <IoIcons.IoMdEye />
    </ButtonStyled>
  );
};


